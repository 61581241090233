// -------------------------------------------------------------------------------------------------
//  apiTypes.js
//  - - - - - - - - - -
//  Загальний перелік типів команд, нотифікацій та сигналів (API).
//
//  Notes:
//  - - - - -
//  - команди - це вказівки серверу щось виконати;
//  - нотифікації - це повідомлення від сервера до фронтенду;
//  - сигнали - це повідомлення від одніжї частини фронтенду до іншої;
//  - назви CMD = це дієслово/команда, що показує що потрібно виконати (зробити);
//  - назви NTF = це відповідь на питання "яке повідомлення прийшло?";
//  - назви SIG = це відповідь на питання "що трапилось?";
// -------------------------------------------------------------------------------------------------

export const REF                                        = 'r';
export const CMD                                        = 'c';
export const NTF                                        = 'n';
export const STATUS                                     = 's';
export const PAYLOAD                                    = 'p';
export const ERRORS                                     = 'e';

export const PING                                       = '>';
export const PONG                                       = '10.346.2782'; // attn: версія повинна бути така ж само як на бекенді !!!

// (CMD) Commands - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export const AUTHENTICATE_CMD                           = 'authenticate';
export const AUTH_LOGOUT_CMD                            = '/auth/logout';
export const AUTH_REGISTRATION_UPDATE_CMD               = '/auth/registration/update';
// export const AUTH_APPEND_SOCIAL_LOGIN_CMD            = '/auth/{provider}/login';
// export const AUTH_REMOVE_SOCIAL_LOGIN_CMD            = '/auth/{provider}/remove';

// account/my:
export const GET_MY_ACCOUNT_CMD                         = '/my/account/get';
export const UPDATE_MY_ACCOUNT_CMD                      = 'my.a~';

// feeds:
export const FETCH_DISCOVER_ADVERTS_AS_GUEST_CMD        = '/fd/dis/ax';
export const FETCH_DISCOVER_ADVERTS_CMD                 = 'fd.dis.ax';
export const FETCH_SUBJECT_ADVERTS_CMD                  = 'fd.j.ax';
export const FETCH_USER_ADVERTS_CMD                     = 'fd.u.ax';

export const FETCH_DISCOVER_STAPLES_AS_GUEST_CMD        = '/fd/dis/sx';
export const FETCH_FEATURED_STAPLES_AS_GUEST_CMD        = '/fd/frd/sx';
export const FETCH_COLLECTION_STAPLES_AS_GUEST_CMD      = '/fd/c/sx';
export const FETCH_USER_STAPLES_AS_GUEST_CMD            = '/fd/u/sx';

export const FETCH_DISCOVER_STAPLES_CMD                 = 'fd.dis.sx';
export const FETCH_FEATURED_STAPLES_CMD                 = 'fd.frd.sx';
export const FETCH_FOLLOWING_STAPLES_CMD                = 'fd.fwg.sx';
export const FETCH_SEARCHED_STAPLES_CMD                 = 'fd.src.sx';
export const FETCH_CSUBSCRIPTION_STAPLES_CMD            = 'fd.ss.sx';
export const FETCH_COLLECTION_STAPLES_CMD               = 'fd.c.sx';
export const FETCH_USER_STAPLES_CMD                     = 'fd.u.sx';

export const FETCH_DISCOVER_COLLECTIONS_AS_GUEST_CMD    = '/fd/dis/cx';
export const FETCH_FEATURED_COLLECTIONS_AS_GUEST_CMD    = '/fd/frd/cx';
export const FETCH_SEARCHED_COLLECTIONS_AS_GUEST_CMD    = '/fd/src/cx';
export const FETCH_USER_COLLECTIONS_AS_GUEST_CMD        = '/fd/u/cx';

export const FETCH_DISCOVER_COLLECTIONS_CMD             = 'fd.dis.cx';
export const FETCH_FEATURED_COLLECTIONS_CMD             = 'fd.frd.cx';
export const FETCH_SEARCHED_COLLECTIONS_CMD             = 'fd.src.cx';
export const FETCH_SUBJECT_COLLECTIONS_CMD              = 'fd.j.cx';
export const FETCH_USER_COLLECTIONS_CMD                 = 'fd.u.cx';
export const FETCH_MY_CSUBSCRIPTIONS_CMD                = 'fd.my.ssx';

export const FETCH_MY_CONTEXTS_CMD                      = 'fd.my.ctxx';
export const FETCH_USER_CONTEXTS_CMD                    = 'fd.u.ctxx';

// export const FETCH_MY_SCTX_CHATS_CMD                 = 'fd.my.sctx.hx';
// export const FETCH_MY_CCTX_CHATS_CMD                 = 'fd.my.cctx.hx';
// export const FETCH_MY_UCTX_CHATS_CMD                 = 'fd.my.uctx.hx';
// export const FETCH_USER_SCTX_CHATS_CMD               = 'fd.u.sctx.hx';
// export const FETCH_USER_CCTX_CHATS_CMD               = 'fd.u.cctx.hx';
export const FETCH_STAPLE_SCTX_CHATS_CMD                = 'fd.s.sctx.hx';
export const FETCH_COLLECTION_CCTX_CHATS_CMD            = 'fd.c.cctx.hx';
export const FETCH_USER_UCTX_CHATS_CMD                  = 'fd.u.uctx.hx';

export const FETCH_CHAT_MESSAGES_CMD                    = 'fd.h.mx';

// users:
export const FETCH_USERS_AS_GUEST_CMD                   = '/u/get_by_ids';
export const FETCH_USERS_BY_SLUGS_AS_GUEST_CMD          = '/u/get_by_slugs';
export const FETCH_USER_PROFILES_AS_GUEST_CMD           = '/u/get_profiles_by_ids';
export const FETCH_USER_PROFILES_BY_SLUGS_AS_GUEST_CMD  = '/u/get_profiles_by_slugs';

export const FETCH_USERS_CMD                            = 'u.<ix';
export const FETCH_USERS_BY_SLUGS_CMD                   = 'u.<lx';
export const FETCH_USER_PROFILES_CMD                    = 'u.<pflix';
export const FETCH_USER_PROFILES_BY_SLUGS_CMD           = 'u.<pfllx';

export const FETCH_MY_FRIENDS_CMD                       = 'my.fx<';
export const SEND_FRIENDSHIP_REQUEST_CMD                = 'my.fsr?';
export const SEND_FRIENDSHIP_REQUEST_EMAIL_CMD          = 'my.fsr?@';
export const SEND_FRIENDSHIP_REQUEST_AGAIN_CMD          = 'my.fsr??';
export const SEND_FRIENDSHIP_REQUEST_TO_CONTACTS_CMD    = 'my.fsr?kx';
export const ACCEPT_FRIENDSHIP_REQUEST_CMD              = 'my.fsr+';
export const REJECT_FRIENDSHIP_REQUEST_CMD              = 'my.fsr!';
export const TERMINATE_FRIENDSHIP_REQUEST_CMD           = 'my.fsr-';
export const CANCEL_FRIENDSHIP_CMD                      = 'my.fs-';

// contacts:
export const FETCH_MY_CONTACTS_CMD                      = 'k.x<';
export const CREATE_CONTACT_CMD                         = 'k.+';
export const UPDATE_CONTACT_CMD                         = 'k.~';
export const DELETE_CONTACT_CMD                         = 'k.-';

// groups:
export const FETCH_MY_GROUPS_CMD                        = 'g.x<';
export const FETCH_GROUP_CMD                            = 'g.<';
export const CREATE_GROUP_CMD                           = 'g.+';
export const UPDATE_GROUP_CMD                           = 'g.~';
export const DELETE_GROUP_CMD                           = 'g.-';
export const ADD_CONTACTS_TO_GROUP_CMD                  = 'g.kx+';
export const REMOVE_CONTACTS_FROM_GROUP_CMD             = 'g.kx-';

// parsers:
export const PARSE_ELEMENT_CMD                          = 'p.el?';

// advert:
export const FETCH_ADVERT_AS_GUEST_CMD                  = '/a/get';
export const FETCH_ADVERT_CMD                           = 'a.<';
export const CREATE_ADVERT_CMD                          = 'a.+';
export const UPDATE_ADVERT_CMD                          = 'a.~';
export const DELETE_ADVERT_CMD                          = 'a.-';
export const SEND_ADVERT_FEEDBACK_EMAIL_CMD             = '/a/feedback/email';

// staples:
export const FETCH_STARRED_STAPLE_IDS_CMD               = 's.strix<';
export const FETCH_STAPLE_AS_GUEST_CMD                  = '/s/get';
export const FETCH_STAPLE_CMD                           = 's.<';
export const CREATE_STAPLE_CMD                          = 's.+';
export const CLONE_STAPLE_CMD                           = 's.=';
export const UPDATE_STAPLE_CMD                          = 's.~';
export const BAN_STAPLE_CMD                             = 's.!';
export const DELETE_STAPLES_CMD                         = 's.x-';
export const CREATE_STAPLE_GRADE_CMD                    = 's.gr+';
export const UPDATE_STAPLE_GRADE_CMD                    = 's.gr~';
export const DELETE_STAPLE_GRADE_CMD                    = 's.gr-';
export const CREATE_STAPLE_REACTION_CMD                 = 's.re+';
export const UPDATE_STAPLE_REACTION_CMD                 = 's.re~';
export const DELETE_STAPLE_REACTION_CMD                 = 's.re-';

// collections:
export const FETCH_MY_COLLECTIONS_CMD                   = 'c.x<';
export const FETCH_COLLECTION_AS_GUEST_CMD              = '/c/get';
export const FETCH_COLLECTION_CMD                       = 'c.<';
export const CREATE_COLLECTION_CMD                      = 'c.+';
export const UPDATE_COLLECTION_CMD                      = 'c.~';
export const BAN_COLLECTION_CMD                         = 'c.!';
export const DELETE_COLLECTION_CMD                      = 'c.-';
export const ADD_STAPLES_TO_COLLECTION_CMD              = 'c.sx+';
export const REMOVE_STAPLES_FROM_COLLECTION_CMD         = 'c.sx-';
export const UPDATE_COLLECTION_STAPLEDATAS_CMD          = 'c.sdx~';
export const FOLLOW_COLLECTION_CMD                      = 'c.fl+';
export const UNFOLLOW_COLLECTION_CMD                    = 'c.fl-';
export const FETCH_FOLLOWED_COLLECTION_IDS_CMD          = 'c.flcix<';
export const FETCH_CSUBSCRIBED_COLLECTION_IDS_CMD       = 'c.sscix<';
export const FETCH_CSUBSCRIPTION_CMD                    = 'c.ss<';
export const CREATE_CSUBSCRIPTION_CMD                   = 'c.ss+';
export const CANCEL_CSUBSCRIPTION_CMD                   = 'c.ss-';

// miscellaneous:
export const UPDATE_PENDING_FIELDS_CMD                  = 'x.pfx~';

// chats:
export const FETCH_CHATS_CMD                            = 'h.<ix';
export const CREATE_CHAT_CMD                            = 'h.+';
export const CREATE_SCTX_CHATS_CMD                      = 'h.sctx.x+';
export const UPDATE_CHAT_CMD                            = 'h.~';
export const CLOSE_CHAT_CMD                             = 'h.~c';
export const DELETE_CHAT_CMD                            = 'h.-';

export const FETCH_CHAT_MEMBERS_CMD                     = 'h.mmb<';
export const ADD_CHAT_MEMBERS_CMD                       = 'h.mmb+';
export const UPDATE_CHAT_MEMBERS_CMD                    = 'h.mmb~';
export const REMOVE_CHAT_MEMBERS_CMD                    = 'h.mmb-';

export const CREATE_CHAT_MESSAGE_CMD                    = 'h.m+';
export const UPDATE_CHAT_MESSAGE_CMD                    = 'h.m~';
export const DELETE_CHAT_MESSAGE_CMD                    = 'h.m-';

export const FETCH_UNREAD_MESSAGES_CMD                  = 'h.umx<';
export const SET_UNREAD_MESSAGES_AS_READ_CMD            = 'h.umx-';

// calls:
export const FETCH_MY_CALLS_CMD                         = 'rtc.call.list';
export const REQUEST_CALL_CMD                           = 'rtc.call.request';
export const JOIN_CALL_CMD                              = 'rtc.call.join';
export const LEAVE_CALL_CMD                             = 'rtc.call.leave';
export const ADD_CALL_MEMBER_CMD                        = 'rtc.call.members.add';
export const SEND_SIGNAL_CMD                            = 'rtc.call.signal';

// cdn:
export const UPLOAD_AVATAR_CMD                          = '/cdn/avatar/upload';
export const UPLOAD_PICTURE_CMD                         = '/cdn/picture/upload';
export const UPLOAD_DOCUMENT_CMD                        = '/cdn/document/upload';

// system:
export const FETCH_SYSTEM_LANGUAGES_CMD                 = '/sys/languages';
export const FETCH_SYSTEM_COUNTRIES_CMD                 = 'sys.yx';
export const FETCH_SYSTEM_CATEGORIES_AS_GUEST_CMD       = '/sys/categories';
export const FETCH_SYSTEM_CATEGORIES_CMD                = 'sys.cgx';
export const FETCH_SYSTEM_SUBJECTS_AS_GUEST_CMD         = '/sys/subjects';
export const FETCH_SYSTEM_SUBJECTS_CMD                  = 'sys.jx';
export const FETCH_SYSTEM_STATICPAGES_CMD               = '/sys/staticpages';

// (NTF) Notifications - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export const ACCOUNT_TYPE_CHANGED_NTF                   = 'acc.t~';

export const FRIENDSHIP_NTF                             = 'fs';
export const CONTACT_UPDATED_NTF                        = 'k.~';
export const STAPLE_UPDATED_NTF                         = 's.~';
export const COLLECTION_STAPLEDATAS_UPDATED_NTF         = 'c.sdx~';
export const CSUBSCRIPTION_CREATED_NTF                  = 'c.ss+';
export const CSUBSCRIPTION_CANCELED_NTF                 = 'c.ss-';

export const CHAT_CREATED_NTF                           = 'h.+';
export const CHAT_UPDATED_NTF                           = 'h.~';
export const CHAT_CLOSED_NTF                            = 'h.~c';
export const CHAT_DELETED_NTF                           = 'h.-';
export const CHAT_MESSAGE_CREATED_NTF                   = 'h.m+';
export const CHAT_MESSAGE_UPDATED_NTF                   = 'h.m~';
export const CHAT_MESSAGE_READ_NTF                      = 'h.m~r';
export const CHAT_MESSAGE_DELETED_NTF                   = 'h.m-';
export const CHAT_CONTEXT_UPDATED_NTF                   = 'h.ctx~';

export const CALL_REQUEST_NTF                           = 'call.request';
export const CALL_REQUEST_CANCEL_NTF                    = 'call.request.cancel';
export const CALL_JOIN_NTF                              = 'call.join';
export const CALL_LEAVE_NTF                             = 'call.leave';
export const CALL_MEMBER_ADD_NTF                        = 'call.members.add';
export const CALL_TERMINATE_NTF                         = 'call.terminate';
export const CALL_SIGNAL_NTF                            = 'call.signal';

export const PRESENCE_NTF                               = 'p';

// (SIG) Signals - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export const MY_CALLS_WERE_FETCHED_SIG                  = 'MYCLLST';    // список дзвінків поточного користувача

export const CALL_REQUESTED_SIG                         = 'CLRQSTD';    // прийшов запит на вхідний дзвінок
export const CALL_REQUEST_CANCELED_SIG                  = 'CLRQCAN';    // callee не прийняв дзвінок (15 сек) або прийняв з іншого пристрою
export const CALL_JOINED_SIG                            = 'CLJOIND';    // інший учасник вийшов (відʼєднався)
export const CALL_LEAVED_SIG                            = 'CLLEAVD';    // інший учасник зайшов (приєднався)
export const CALL_MEMBER_ADDED_SIG                      = 'CLMMBAD';    // інформація про нового учасника дзвінка
export const CALL_TERMINATED_SIG                        = 'CLTERMD';    // дзвінок закінчено

export const RTC_OFFER_SIG                              = 'RTCOFFR';    // сигнал для учасників дзвінка (RTC Offer)
export const RTC_ANSWER_SIG                             = 'RTCANSW';    // сигнал для учасників дзвінка (RTC Answer)
export const RTC_ICE_CANDIDATE_SIG                      = 'RTCICEC';    // сигнал для учасників дзвінка (RTC ICE-Candidate)

export const SERVER_ERROR_SIG                           = 'ERRSERV';    // помилка на сервері
export const SOCKET_ERROR_SIG                           = 'ERRSOCK';    // помилка сокета (транспорт)
