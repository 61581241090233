// -------------------------------------------------------------------------------------------------
//  MarketplaceStore.js
//  - - - - - - - - - -
//  Adverts & other classes for marketplace.
//
//  Attn:
//  - - - - -
//  - поля id, value, label потрібні для Select2 (геттери: value, label);
//  - при розміщенні обʼєктів в стор перетворюємо назви полів: snake_case --> camelCase;
//  - subjects зберігаємо в сторі в текстовому вигляді в форматі {{ SJ-LIST-STR }};
//  - для кожного предмету формуємо список ідентифікаторів оголошень, що входять до цього предмету:
//    ['subjectAdvertsOptions', subjectId, 'advertIds'];
//  - функції get{...}Adverts віддають обʼєкти тільки ДО курсора, щоб не було "пропусків" в фіді;
//
//  Attn:
//  - - - - -
//  a)  суфікс '...Feed' в назві функції показує, що функція повертає відразу повний набір
//      параметрів фіду (напр: {adverts, advertCursor, areAdvertsLoaded});
// -------------------------------------------------------------------------------------------------
import {ReduceStore} from 'flux/utils';
import {Map, Set, OrderedSet, Record} from 'immutable';
import camelcaseKeys from 'camelcase-keys';
import Dispatcher from 'dispatcher/Dispatcher';
import {
  DISCOVER_ADVERTS_WERE_FETCHED_ACTION,
  SUBJECT_ADVERTS_WERE_FETCHED_ACTION,
  USER_ADVERTS_WERE_FETCHED_ACTION,
  ADVERT_WAS_FETCHED_AS_GUEST_ACTION,
  ADVERT_WAS_FETCHED_ACTION,
  ADVERT_WAS_CREATED_ACTION,
  ADVERT_WAS_UPDATED_ACTION,
  ADVERT_WAS_DELETED_ACTION,
  MY_ACCOUNT_WAS_FETCHED_ACTION,
  LOGGED_OUT_ACTION} from 'core/actionTypes';
import {
  ID_FLD,
  OWNER_ID_FLD,
  LANGUAGE_ID_FLD,
  TYPE_FLD,
  NAME_FLD,
  DESCRIPTION_FLD,
  SUBJECTS_FLD,
  BUSINESS_HOURS_FLD,
  PRICE_RANGE_FLD,
  CREATED_AT_FLD,
  VISITS_COUNT_FLD} from 'core/apiFields';
import {DEFAULT_TSN12_CURSOR, LS_SETTINGS} from 'core/commonTypes';
import {
  DISCOVER_ADVERTS_FEED,
  SUBJECT_ADVERTS_FEED,
  USER_ADVERTS_FEED} from 'core/uiTypes';
import {fetchAdvert} from 'actions/AdvertActions';
import {toReact} from 'components/RichEditor/rich-conmark-processors';

const DISCOVER_ADVERTS_PREFIX             = DISCOVER_ADVERTS_FEED; // префікс ключа для discover-фіду оголошень
const SUBJECT_ADVERTS_PREFIX              = SUBJECT_ADVERTS_FEED; // префікс ключа для фіду оголошень для конкретного предмету (напр: j19vDIwiC8g00)
const USER_ADVERTS_PREFIX                 = USER_ADVERTS_FEED; // префікс ключа для фіду оголошень юзера (напр: u19c4CJjfP900)

const isVerbose = DEBUG && true;
const prefix = '- - - MarketplaceStore';

function trace(msg, ...other) { if (isVerbose) { console.log(`${prefix}.${msg}`, ...other); }}
function traceWarn(msg, ...other) { if (isVerbose) { console.warn(`${prefix}.${msg}`, ...other); }}
function traceError(msg, ...other) { console.error(`${prefix}.${msg}`, ...other); }

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
const AdvertRecord = Record({
  id: '',
  ownerId: '',                            // власник (автор)
  type: '',                               // тип відображення
  name: '',                               // назва/заголовок
  description: '',                        // опис
  businessHours: '',                      // час роботи
  priceRange: '',                         // ціни
  strSubjects: '[]',                      // список предметів в форматі {{ SJ-LIST-STR }}
  createdAt: '',                          // дата створення
  visitsCount: '',                        // к-сть переглядів
});

class Advert extends AdvertRecord {
  get descriptionHtml() {
    return toReact(this.description);
  }
  get lstSubjects() {
    return JSON.parse(this.strSubjects);  // '[]' --> []
  }
  get defaultSortKey() {
    return this.id;                       // кастомний порядок сортування оголошень (в фідах)
  }
}

// Attn: {{ ADVERT }} --> inner format ('i' --> 'id', ...)
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
function mergeAdvert(state, patchObject) {
  const advertId = patchObject[ID_FLD] || patchObject['id'];
  let nextState = state.updateIn(['adverts', advertId], prevAdvert => {
    const {
      [ID_FLD]:id,
      [OWNER_ID_FLD]:ownerId,
      [TYPE_FLD]:type,
      [NAME_FLD]:name,
      [DESCRIPTION_FLD]:description,
      [BUSINESS_HOURS_FLD]:businessHours,
      [PRICE_RANGE_FLD]:priceRange,
      [SUBJECTS_FLD]:strSubjects,
      [CREATED_AT_FLD]:createdAt,
      [VISITS_COUNT_FLD]:visitsCount,
      ...innerFormatAdvertFields} = patchObject; // без camelcaseKeys (бо є поля з '_') !!!
    const formattedObject = camelcaseKeys(innerFormatAdvertFields, {deep: true});
    // - - - присвоєння робиться тільки коли є значення !!!
    if (id !== undefined)                 {formattedObject.id = advertId;}
    if (ownerId !== undefined)            {formattedObject.ownerId = ownerId;}
    if (type !== undefined)               {formattedObject.type = type;}
    if (name !== undefined)               {formattedObject.name = name;}
    if (description !== undefined)        {formattedObject.description = description;}
    if (businessHours !== undefined)      {formattedObject.businessHours = businessHours;}
    if (priceRange !== undefined)         {formattedObject.priceRange = priceRange;}
    if (strSubjects !== undefined)        {formattedObject.strSubjects = strSubjects;}
    if (createdAt !== undefined)          {formattedObject.createdAt = createdAt;}
    if (visitsCount !== undefined)        {formattedObject.visitsCount = visitsCount;}
    // - - -
    return (prevAdvert || new Advert()).merge(formattedObject);
  });
  // ...формуємо список ідентифікаторів оголошень для кожного предмету (Subject):
  const advert = nextState.getIn(['adverts', advertId]);
  const {lstSubjects:subjects} = advert;
  const nextState1 = subjects.reduce((accState, subj) => {
    const {[ID_FLD]:subjectId} = subj;
    if (subjectId) {
      const prevFeed = accState.getIn(['advertFeeds', SUBJECT_ADVERTS_PREFIX + subjectId]) || new AdvertFeed();
      const nextFeed = prevFeed.setIn(['advertIds'], prevFeed.getIn(['advertIds']).add(advertId));
      return accState.setIn(['advertFeeds', SUBJECT_ADVERTS_PREFIX + subjectId], nextFeed);
    }
    return accState;
  }, nextState);
  // ...додаємо id до списку ідентифікаторів загального фіду:
  const prevFeed1 = nextState1.getIn(['advertFeeds', DISCOVER_ADVERTS_PREFIX]) || new AdvertFeed();
  const nextFeed1 = prevFeed1.setIn(['advertIds'], prevFeed1.getIn(['advertIds']).add(advertId));
  return nextState1.setIn(['advertFeeds', DISCOVER_ADVERTS_PREFIX], nextFeed1);
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
const AdvertFeed = Record({
  advertIds: Set(),                       // ідентифікатори оголошень, що входять в фід (тільки для subject)
  advertCursor: DEFAULT_TSN12_CURSOR,     // наступний курсор для цього фіду (для запиту до БД)
  areAdvertsLoaded: false,                // чи завантажені усі оголошення цього фіду в стор?
});

function mergeAdvertFeed(state, key, patchObject) {
  return state.updateIn(['advertFeeds', key], prevFeed => {
    return (prevFeed || new AdvertFeed()).merge(patchObject);
  });
}

// 1) значення myId потрібно відразу (тому з LS, бо з серверу приходить з затримкою) !!!
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
class MarketplaceStore extends ReduceStore {
  getInitialState() {
    trace(`getInitialState`);
    const {myAccount} = JSON.parse(localStorage.getItem(LS_SETTINGS)) || {}; // sic!: 1)
    const {id:myId = ''} = myAccount || {};
    return Map({
      myId: myId,                         // ідентифікатор користувача
      adverts: Map(),                     // завантажені в стор оголошення
      advertFeeds: Map(),                 // дані для фідів оголошень (DiscoverAdverts, SubjectAdverts, UserAdverts, ...)
    });
  }

  // - - - predicates:

  areUserAdvertsLoaded(userId) {
    const result = !!this.getState().getIn(['advertFeeds', USER_ADVERTS_PREFIX + userId, 'areAdvertsLoaded']);
    trace(`areUserAdvertsLoaded: loaded=${result}`);
    return result;
  }

  areSubjectAdvertsLoaded(subjectId) {
    const result = !!this.getState().getIn(['advertFeeds', SUBJECT_ADVERTS_PREFIX + subjectId, 'areAdvertsLoaded']);
    trace(`areSubjectAdvertsLoaded: loaded=${result}`);
    return result;
  }

  areDiscoverAdvertsLoaded() {
    const result = !!this.getState().getIn(['advertFeeds', DISCOVER_ADVERTS_PREFIX, 'areAdvertsLoaded']);
    trace(`areDiscoverAdvertsLoaded: loaded=${result}`);
    return result;
  }

  isAdvertLoaded(id) {
    const result = !!this.getState().getIn(['adverts', id]);
    trace(`isAdvertLoaded: chatId=${id}, loaded=${result}`);
    return result;
  }

  // - - - getters:

  getMyId() {
    trace(`getMyId`);
    return this.getState().get('myId');
  }

  getAdvert(id) {
    return this.getState().getIn(['adverts', id]);
  }

  getUserAdverts(userId) { // ToDo: повертати {adverts, advertCursor, areAdvertsLoaded} !!!
    trace(`getUserAdverts`);
    const cursor = this.getState().getIn(['advertFeeds', USER_ADVERTS_PREFIX + userId, 'advertCursor']) || DEFAULT_TSN12_CURSOR;
    return cursor || cursor === DEFAULT_TSN12_CURSOR ? // sic!: для показу новостворених оголошень в пустому фіді (курсор ще невизначений)
      this.getState().get('adverts')
        .filter(advert => advert.ownerId === userId && advert.id >= cursor)
        .toList()
        .sort((a, b) => { return a.defaultSortKey > b.defaultSortKey ? -1 : 1; }) :
      OrderedSet();
  }

  getDiscoverAdverts() { // ToDo: повертати {adverts, advertCursor, areAdvertsLoaded} !!!
    trace(`getDiscoverAdverts`);
    const advertIds = this.getState().getIn(['advertFeeds', DISCOVER_ADVERTS_PREFIX, 'advertIds']);
    const cursor = this.getState().getIn(['advertFeeds', DISCOVER_ADVERTS_PREFIX, 'advertCursor']) || DEFAULT_TSN12_CURSOR;
    return this.getSomeAdverts(advertIds)
      .filter(advert => advert.id >= cursor)
      .sort((a, b) => { return a.defaultSortKey > b.defaultSortKey ? -1 : 1; });
  }

  getSubjectAdverts(subjectId) { // ToDo: повертати {adverts, advertCursor, areAdvertsLoaded} !!!
    trace(`getSubjectAdverts`);
    const advertIds = this.getState().getIn(['advertFeeds', SUBJECT_ADVERTS_PREFIX + subjectId, 'advertIds']);
    const cursor = this.getState().getIn(['advertFeeds', SUBJECT_ADVERTS_PREFIX + subjectId, 'advertCursor']) || DEFAULT_TSN12_CURSOR;
    return cursor || cursor === DEFAULT_TSN12_CURSOR ? // sic!: для показу новостворених оголошень в пустому фіді (курсор ще невизначений)
      this.getSomeAdverts(advertIds)
        .filter(advert => cursor === DEFAULT_TSN12_CURSOR || advert.id >= cursor) // sic!: якщо курсор не змінювався, то показ усіх зображень
        .sort((a, b) => { return a.defaultSortKey > b.defaultSortKey ? -1 : 1; }) :
      OrderedSet();
  }

  getSomeAdverts(ids = []) { // ToDo: HIGHLOAD: отримувати по списку ідентифікаторів одним запитом (API: FETCH_..._CMD) !!!
    trace(`getSomeAdverts`);
    return ids.reduce((acc, id) => {
      const advert = this.getAdvert(id);
      if (!advert) {
        fetchAdvert(id); // xtra-fetch: Advert
        return acc;
      }
      return acc.add(advert);
    }, OrderedSet()); // sic!: дефолтне сортування згідно порядку, як отримували з серверу
  }

  getDiscoverAdvertCursor() {
    trace(`getDiscoverAdvertCursor`);
    return this.getState().getIn(['advertFeeds', DISCOVER_ADVERTS_PREFIX, 'advertCursor']) || DEFAULT_TSN12_CURSOR;
  }

  getSubjectAdvertCursor(subjectId) {
    trace(`getSubjectAdvertCursor`);
    return this.getState().getIn(['advertFeeds', SUBJECT_ADVERTS_PREFIX + subjectId, 'advertCursor']) || DEFAULT_TSN12_CURSOR;
  }

  getUserAdvertCursor(userId) {
    trace(`getUserAdvertCursor`);
    return this.getState().getIn(['advertFeeds', USER_ADVERTS_PREFIX + userId, 'advertCursor']) || DEFAULT_TSN12_CURSOR;
  }

  // - - - reducers:

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [DISCOVER_ADVERTS_WERE_FETCHED_ACTION] = (state, {payload}) => {
    const {adverts, advertCursor, areAdvertsLoaded} = payload;
    if (!adverts) {
      return state;
    }
    const nextState = adverts.reduce(mergeAdvert, state);
    const {advertIds:prevAdvertIds} = nextState.getIn(['advertFeeds', DISCOVER_ADVERTS_PREFIX]) || new AdvertFeed();
    const advertIds = adverts.reduce((acc, advert) => {
      return acc.add(advert[ID_FLD]);
    }, prevAdvertIds);
    return mergeAdvertFeed(nextState, DISCOVER_ADVERTS_PREFIX, {advertCursor, areAdvertsLoaded, advertIds});
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [SUBJECT_ADVERTS_WERE_FETCHED_ACTION] = (state, {payload}) => {
    const {subjectId, adverts, advertCursor, areAdvertsLoaded} = payload;
    if (!adverts) {
      return state;
    }
    const nextState = adverts.reduce(mergeAdvert, state);
    return mergeAdvertFeed(nextState, SUBJECT_ADVERTS_PREFIX + subjectId, {advertCursor, areAdvertsLoaded});
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [USER_ADVERTS_WERE_FETCHED_ACTION] = (state, {payload}) => {
    const {userId, adverts, advertCursor, areAdvertsLoaded} = payload;
    if (!adverts) {
      return state;
    }
    const nextState = adverts.reduce(mergeAdvert, state);
    return mergeAdvertFeed(nextState, USER_ADVERTS_PREFIX + userId, {advertCursor, areAdvertsLoaded});
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [ADVERT_WAS_FETCHED_AS_GUEST_ACTION] = (state, {payload}) => {
    const {advert} = payload;
    return advert ?
      mergeAdvert(state, advert) :
      state;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [ADVERT_WAS_FETCHED_ACTION] = (state, {payload}) => {
    const {advert} = payload;
    return advert ?
      mergeAdvert(state, advert) :
      state;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [ADVERT_WAS_CREATED_ACTION] = (state, {payload}) => {
    const {advert} = payload;
    if (!advert) {
      return state;
    }
    const {[ID_FLD]:advertId, [OWNER_ID_FLD]:ownerId} = advert;
    const nextState = mergeAdvert(state, advert);
    //
    // Attn:
    // -  відразу після реєстрації, щоб показати новостворене оголошення, потрібно
    //    ініціалізувати курсор advertCursor для поточного користувача;
    //    інакше новостворене оголошення не попадає для показу в фіді, бо його id < DEFAULT_TSN12_CURSOR;
    // - - - - -
    // FixMe: не показується оголошення в фіді DiscoverAdverts при повністю ПОРОЖНЬОМУ фіді !!!
    return this.getUserAdvertCursor(ownerId) === DEFAULT_TSN12_CURSOR ?
      mergeAdvertFeed(nextState, USER_ADVERTS_PREFIX + ownerId, {advertCursor: advertId, areAdvertsLoaded: false}) :
      nextState;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [ADVERT_WAS_UPDATED_ACTION] = (state, {payload}) => {
    const {advert, appendedSubjectIds, removedSubjectIds} = payload;
    const {[ID_FLD]:advertId} = advert || {};
    if (!advertId) {
      return state;
    }
    // ...1) видаляємо id оголошення із списків ідентифікаторів фідів предметів для кожного видаленого предмету
    const nextState = removedSubjectIds.reduce((accState, subjectId) => {
      const prevFeed = accState.getIn(['advertFeeds', SUBJECT_ADVERTS_PREFIX + subjectId]) || new AdvertFeed();
      const nextFeed = prevFeed.setIn(['advertIds'], prevFeed.getIn(['advertIds']).delete(advertId));
      return accState.setIn(['advertFeeds', SUBJECT_ADVERTS_PREFIX + subjectId], nextFeed);
    }, state);
    // ...2) додаємо id оголошення до списків ідентифікаторів фідів для кожного з доданих предметів
    const nextState1 = appendedSubjectIds.reduce((accLocalState, subjectId) => {
      const prevFeed = accLocalState.getIn(['advertFeeds', SUBJECT_ADVERTS_PREFIX + subjectId]) || new AdvertFeed();
      const nextFeed = prevFeed.setIn(['advertIds'], prevFeed.getIn(['advertIds']).add(advertId));
      return accLocalState.setIn(['advertFeeds', SUBJECT_ADVERTS_PREFIX + subjectId], nextFeed);
    }, nextState);
    // ...3) оновлюємо оголошення
    return mergeAdvert(nextState1, advert);
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [ADVERT_WAS_DELETED_ACTION] = (state, {payload}) => {
    const {deletedId, affectedSubjectIds} = payload;
    if (!deletedId) {
      return state;
    }
    // ...1) видаляємо оголошення
    const nextState = state.deleteIn(['adverts', deletedId]);
    // ...2) видаляємо id із списку ідентифікаторів фіду оголошень
    const nextState1 = nextState.setIn(['advertFeeds', DISCOVER_ADVERTS_PREFIX, 'advertIds'],
      nextState.getIn(['advertFeeds', DISCOVER_ADVERTS_PREFIX, 'advertIds']).delete(deletedId));
    // ...3) видаляємо id оголошення із списків ідентифікаторів фідів предметів для кожного задіяного предмету
    return affectedSubjectIds.reduce((accState, subjectId) => {
      const prevFeed = accState.getIn(['advertFeeds', SUBJECT_ADVERTS_PREFIX + subjectId]) || new AdvertFeed();
      const nextFeed = prevFeed.setIn(['advertIds'], prevFeed.getIn(['advertIds']).delete(deletedId));
      return accState.setIn(['advertFeeds', SUBJECT_ADVERTS_PREFIX + subjectId], nextFeed);
    }, nextState1);
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [MY_ACCOUNT_WAS_FETCHED_ACTION] = (state, {payload}) => {
    const {account} = payload;
    return account ?
      state.mergeIn(['myId'], account[ID_FLD]) :
      state;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [LOGGED_OUT_ACTION] = (state, {payload}) => {
    return this.getInitialState();
  }

  reduce(state, action) {
    const reducer = this[action.type];
    if (reducer) {
      const nextState = reducer(state, action);
      trace(`reduce:`, action, nextState.toJS());
      return nextState;
    }
    return state;
  }
}

export default new MarketplaceStore(Dispatcher);
