// -------------------------------------------------------------------------------------------------
//  WebSocketAPI.js
//  - - - - - - - - - -
//  Все для роботи з WebSockets.
//
//  https://www.npmjs.com/package/reconnecting-websocket
//
//  Attn: a)
//  - - - - -
//  - при зміні формату версії в PONG-відгуці серверу потрібно змінити regex-вираз перевірки !!!
//
//  Attn:
//  - - - - -
//  - WSOCK_API_URL задається в webpack.DefinePlugin;
//  - реалізовано два списки pub-sub хендлерів: для повідомлень сокета (eventPubSub)
//    та для помилок сокета (errorPubSub);
//  - після встановлення зʼєднання треба відповісти в сокет, інакше сокет закриває конект;
//  - обовʼязкові реквізити для websocket request (інакше сервер віддає 'malformed_request'):
//    {
//      [REF]: UUID,             // UUID-ідентифікатор
//      [CMD]: ...,              // назва команди, наприклад: 'staples.detect'
//      [PAYLOAD]: {}            // додаткові параметри запиту, або {}
//    }
//
//  Notes:
//  - - - - -
//  - усі назви експортних функцій починаються на wsock*;
// -------------------------------------------------------------------------------------------------
import ReconnectingWebsocket from 'reconnecting-websocket'
import {v1 as uuid} from 'uuid';
import PubSub from 'utils/PubSub';
import {REF, CMD, NTF, STATUS, PAYLOAD, ERRORS, PING, PONG, AUTHENTICATE_CMD} from 'core/apiTypes';
import {t} from 'ttag';
import {logout} from 'actions/AuthActions';
import {confirmAction} from 'components/UI/ConfirmAction';
import {connectionStatus} from 'components/UI/ConnectionStatus';
import {websocket as settings} from 'settings/local.yaml';

const isVerbose = DEBUG && false;
const prefix = '- - - WebSocketAPI';

function trace(msg, ...other) { if (isVerbose) { console.log(`${prefix}.${msg}`, ...other); }}
function traceWarn(msg, ...other) { if (isVerbose) { console.warn(`${prefix}.${msg}`, ...other); }}
function traceError(msg, ...other) { console.error(`${prefix}.${msg}`, ...other); }

let wsock;                              // системний сокет
let eventPubSub = new PubSub();         // список функцій, що обробляють сокет-івенти
let errorPubSub = new PubSub();         // список функцій, що обробляють помилки сокетів
let showStatusTimer;                    // ідентифікатор таймеру, щоб показати статус підключення
let hideStatusTimer;                    // ідентифікатор таймеру, щоб приховати статус підключення
let pingTimer;                          // ідентифікатор пінг-таймера

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export function wsockSubscribeOnEvents(callback) {
  return eventPubSub.subscribe(callback);
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export function wsockUnsubscribeFromEvents(callback) {
  return eventPubSub.unsubscribe(callback);
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export function wsockSubscribeOnErrors(callback) {
  return errorPubSub.subscribe(callback);
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export function wsockUnsubscribeFromErrors(callback) {
  return errorPubSub.unsubscribe(callback);
}

// CONNECTING	= 0	  The connection is not yet open.
// OPEN	      = 1	  The connection is open and ready to communicate.
// CLOSING	  = 2	  The connection is in the process of closing.
// CLOSED	    = 3	  The connection is closed or couldn't be opened.
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export function wsockReady() {
  return wsock && wsock.readyState === wsock.OPEN;
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
function connect() {
  trace(`connect`);
  wsock = new Promise((resolve, reject) => {
    const rewsock = new ReconnectingWebsocket(WSOCK_API_URL, [], {
      maxReconnectionDelay: 10000,        // max delay in ms between reconnections
      minReconnectionDelay: 1000,         // min delay in ms between reconnections
      reconnectionDelayGrowFactor: 1.3,   // how fast the reconnection delay grows
      minUptime: 5000,                    // min time in ms to consider connection as stable
      connectionTimeout: 4000,            // retry connect if not connected after this time, in ms
      maxRetries: Infinity,               // maximum number of retries
      maxEnqueuedMessages: Infinity,      // maximum number of messages to buffer until reconnection
      startClosed: false,                 // start websocket in CLOSED state, call `.reconnect()` to connect
      debug: false,                       // enables debug output
    });

    rewsock.onopen = (event) => {
      trace(`onOpen`);
      const requestId = uuid();
      const authToken = localStorage.getItem('authToken');
      rewsock.send(JSON.stringify({
          [REF]: requestId,
          [CMD]: AUTHENTICATE_CMD,
          [PAYLOAD]: {
            'x-auth-token': authToken,
          },
        }
      ));
      if (showStatusTimer) {
        clearTimeout(showStatusTimer);
        showStatusTimer = null;
      }
      hideStatusTimer = setTimeout(connectionStatus(false), settings.statusTimeout);
      resolve(rewsock);
    };

    rewsock.onerror = (event) => {
      switch(event.code) {
        case 'ETIMEDOUT': traceError(`onError: [${event.code}]/Disconnected`); break;
        case 'EHOSTDOWN': traceError(`onError: [${event.code}]/Server unreachable`); break;
        default:          traceError(`onError: [${event.code}]/Error in connection establishment`);
      }
      errorPubSub.publish(event);
    };

    rewsock.onclose = (event) => {
      trace(`onClose: ${event.reason !== '' ? event.reason : 'Unknown reason'}`);
      if (hideStatusTimer) {
        clearTimeout(hideStatusTimer);
        hideStatusTimer = null;
      }
      if (event.reason !== 'wsockDrop') {
        showStatusTimer = setTimeout(connectionStatus(true), settings.statusTimeout);
      }
    };

    rewsock.onmessage = (event) => {
      const beVersion = ('' + event.data).match(/^([\d]{1,2}).([\d]{1,3}).([\d]{1,4})$/); // sic!: a) (BE = "10.346.2782") !!!
      if (!beVersion) {
        if (isVerbose) {
          const {[CMD]:cmd, [REF]:ref, [PAYLOAD]:payload} = JSON.parse(event.data);
          trace(`onRead: cmd=${cmd}, ref=${ref}, payload=${JSON.stringify(payload)}`);
        }
        eventPubSub.publish(event); // send event to all subscribers
      } else {
        const feVersion = ('' + PONG).match(/^([\d]{1,2}).([\d]{1,3}).([\d]{1,4})$/); // sic!: a) (FE = "10.346.2782") !!!
        if (feVersion && (feVersion[1] !== beVersion[1] || feVersion[2] !== beVersion[2] || feVersion[3] !== beVersion[3])) {
          confirmAction({
              title: t`New Version Available`,
              description: t`The current webpage version [v${PONG}] needs to be reloaded to update to the newest version [v${event.data}].`,
            }, [
              {title: t`No`, isAccented: false},
              {title: t`Yes`, isAccented: true, onClick: () => {
                if (feVersion[1] !== beVersion[1]) {
                  logout(); // logout if major version has been changed !!!
                  setTimeout(() => window.location.href = "/", 400); // go to the root url !!!
                }
                setTimeout(() => window.location.reload(), 1000); // reload webpage !!!
              }}
            ]
          );
        }
      }
    }
  });
  return wsock; // sic!: будь-яке значення (навіть нерозрезолвлений проміс) запобігає створенню зайвих конектів !!!
}

//  Зупиняє надсилання ping-сигналу
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
function stopPinging() {
  if (pingTimer) {
    trace(`stopPinging`);
    clearInterval(pingTimer);
    pingTimer = null;
  }
}

//  Починає надсилання ping-сигналу через заданий проміжок часу
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
function startPinging() {
  stopPinging();
  if (!pingTimer) {
    trace(`startPinging`);
    pingTimer = setInterval(() => {
      if (wsockReady()) {
        wsock.send(PING);
      }
    }, settings.pingTimeout);
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function wsockInit(reason = '') {
  trace(`wsockInit: reason=${JSON.stringify(reason)}`);
  if (!wsock || wsock.readyState !== wsock.OPEN) {
    trace(`wsockInit: prepare new connection`);
    wsock = await connect();
    if (wsock && wsock.readyState === wsock.OPEN) {
      trace(`wsockInit: NEW connection established; state=${wsock.readyState}`);
      startPinging();
    }
  }
  return wsock;
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function wsockDrop(reason = '') {
  trace(`wsockDrop: reason=${JSON.stringify(reason)}`);
  if (wsock) {
    stopPinging();
    await wsock.close(1000, 'wsockDrop', { keepClosed: true, fastClose: true, delay: 0 });
  }
  wsock = null;
}

//  Асинхронний спосіб роботи з websocket.
//  Щоб отримати результат потрібно підписатись на events відповідного типу.
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function wsockWrite(request) {
  request[REF] = uuid();
  trace(`wsockWrite: request=${JSON.stringify(request)}`);
  const tmpsock = await wsockInit('wsockWrite');
  tmpsock.send(JSON.stringify(request));
}

//  Синхронний спосіб роботи з websocket.
//  Чекаємо на відповідь сервера, результат обробляємо в API-функціях, що викликали wsockFetch.
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function wsockFetch(request) {
  const event = await capture(request);
  const response = JSON.parse(event.data);
  // only malformed requests are considered as errors
  if (response.status === 'malformed_request') {
    traceError(`wsockFetch: request=${JSON.stringify(request)}, response=${JSON.stringify(response)}`);
  }
  return response;
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
async function capture(request) {
  trace(`capture: request=${JSON.stringify(request)}`);
  const tmpsock = await wsockInit('capture');
  const requestId = uuid();
  request[REF] = requestId;
  return new Promise((resolve, reject) => {
    let retryTimer;
    let rejectTimer;
    let unsubscribeResponseListener;

    function send() {
      trace(`onSend: request=${JSON.stringify(request)}`);
      tmpsock.send(JSON.stringify(request));
    }

    function clear() {
      clearTimeout(retryTimer);
      clearTimeout(rejectTimer);
      unsubscribeResponseListener();
    }

    // query a server until resolving or rejection
    retryTimer = setTimeout(send, settings.retryTimeout);

    // stop querying a server
    rejectTimer = setTimeout(() => {
      trace(`onRejected`);
      clear();
      reject('Rejected by timeout');
    }, settings.rejectTimeout);

    // catch an appropriate server's response
    unsubscribeResponseListener = eventPubSub.subscribe((responseEvent) => {
      const {[CMD]:cmd, [REF]:responseId} = JSON.parse(responseEvent.data);
      if (requestId === responseId) {
        trace(`onResolved: cmd=${cmd}, ref=${responseId}`);
        clear();
        resolve(responseEvent);
      }
    });
    // query a server at least one time
    send();
  });
}
