// -------------------------------------------------------------------------------------------------
//  converters.js
//  - - - - - - - - - -
//  Функції для екранування, модицікації тексту, перетворень URL та інше.
// -------------------------------------------------------------------------------------------------

const BASE62 = `0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ`;
const STAR_COLLECTION_IDS_SUFFIX = 'xU';

import {
  parse as UriParse,
  serialize as UriSerialize,
  // resolve,
  // resolveComponents,
  // normalize,
  // equal,
  // removeDotSegments,
  // pctEncChar,
  // pctDecChars,
  // escapeComponent,
  // unescapeComponent,
} from 'uri-js';

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export function composeStarCollectionId(userId) {
  return userId ? ('' + userId).slice(0, 10) + STAR_COLLECTION_IDS_SUFFIX : '';
}

// 	Transforms user-entered filter string to regex-ready state
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export function prepareFilter(filterString) {
  return ('' + filterString).toLowerCase().replaceAll('*', '.*').replaceAll(' ', '');
}

// 	Converts user-entered filter string into regex
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export function createRegex(filterString) {
  return new RegExp(('' + filterString).toLowerCase().replaceAll('*', '.*').replaceAll(' ', ''));
}

// 	Shift datetime cursor according to an order.
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
//  ASC:  "2019-03-30T19:57:48.813Z" --> "2019-03-30T19:57:48.81Z"
//  DESC: "2019-03-30T19:57:48.813Z" --> "2019-03-30T19:57:48.8139Z"
//
//  Attn:
//  - PostgreSQL зберігає поля datetime з точністю до 1/1000000 секунди, а в Javascript 1/1000.
//    При передачі даних на фронтенд через округлення курсор зміщується в сторону збільшення часу
//    внаслідок чого із списку видачі пропадати записи, якщо виконуються наступні умови:
//    (датачас у декількох записів однакові і частина цих записів відкидається внаслідок limit);
//  - в SQL-запиті на відбір записів повинна бути умова ">=" або "<=";
//
export function cursorify(cursor, order) {
  return cursor ?
    order === 'DESC' ?
      (cursor + '').replace('Z', '9Z') : // додаємо +0.9 секунди (збільшуємо час)
      (cursor + '') : //.replace(/[0123456789]Z/, 'Z') : // відкидаємо останній знак (зменшуємо час)
    '';
}

// 	Convert an IRI to a URI (with missed protocol correction).
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
//  Examples:
//  input2uri('омв.укр/просто/', protocol='https') --> 'https://xn--b1atf.xn--j1amh/%D0%BF%D1%80%D0%BE%D1%81%D1%82%D0%BE/'
//  input2uri('омв.укр/просто/') --> 'http://xn--b1atf.xn--j1amh/%D0%BF%D1%80%D0%BE%D1%81%D1%82%D0%BE/'
//  input2uri('омв.укр') --> 'http://xn--b1atf.xn--j1amh'
//
export function input2uri(iri, protocol='https') {
  let url = (iri + '').trim();
  url = url.search(/http:\/\/|https:\/\//) < 0 ? protocol + '://' + url : url;
  return UriSerialize(UriParse(url));
}

// 	Convert an IRI to a URI.
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
//  Examples:
//  iri2uri('http://омв.укр/просто/') --> 'http://xn--b1atf.xn--j1amh/%D0%BF%D1%80%D0%BE%D1%81%D1%82%D0%BE/'
//  iri2uri('омв.укр') --> '%D0%BE%D0%BC%D0%B2.%D1%83%D0%BA%D1%80'
//  iri2uri("http://\N{COMET}.com/\N{COMET}") --> 'http://xn--o3h.com/%E2%98%84'
//  iri2uri("http://bitworking.org/?fred=\N{COMET}") --> 'http://bitworking.org/?fred%3D%E2%98%84'
//  iri2uri("http://bitworking.org/#\N{COMET}") --> 'http://bitworking.org/#%E2%98%84'
//  iri2uri(u"#\N{COMET}") --> '#%E2%98%84'
//  iri2uri("/fred?bar=\N{BLACK LEFT POINTING INDEX}#\N{COMET}") --> '/fred?bar%3D%E2%98%9A#%E2%98%84'
//
export function iri2uri(iri) {
  let url = (iri + '').trim();
  return url ? UriSerialize(UriParse(url)) : ''; // sic!: або значення, або ''
}

// 	Transform value to slug
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export function slugify(value) {
  let result = '';
  let val = value || '';
  // lowercase
  val = val.trim().toLowerCase();
  // replace 'space' --> '-' (in case of copy-paste)
  val = val.replace(/[ ]/g, '-');
  // remove escaped symbols
  val = val.replace(/[\·\`\~\!\@\#\$\%\^\&\*\_\=\+\?\/\\\<\>\|\}\{\[\]\(\)\"\'\.\,\:\;]/g, '');
  // remove duplicated '-'
  val = val.replace(/-{2,}/g,'-');
  // remove leading '-'
  val = val.replace(/^-{1,}/g,'');
  // remove tailing '-'
  val = val.replace(/-{1,}$/g,'');
  if (val.length > 0 ) {
    result = val;
  }
  return result;
}

// 	Transform user-entered group name
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export function cleanifyGroupName(value) {
  return cleanifyCollectionName(value)
}

// 	Transform user-entered contact name
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export function cleanifyContactName(value) {
  return cleanifyStapleName(value)
}

// 	Transform user-entered advert name
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export function cleanifyAdvertName(value) {
  return cleanifyStapleName(value)
}

// 	Transform user-entered collection name
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export function cleanifyCollectionName(value) {
  let result = '';
  let val = value || '';
  // replace '`' --> '՚'
  val = val.replace(/[`]/g, '՚');
  // replace ''' --> '՚'
  val = val.replace(/[']/g, '՚');
  // replace '"' --> '«' (start of the string)
  val = val.replace(/^"/g, '«');
  // replace ' "' --> ' «'
  val = val.replace(/[ \b\r\n\t]{1,}"/g, ' «');
  // replace '"' --> '»'
  val = val.replace(/["]/g, '»');
  // replace / --> {space}/{space}
  val = val.replace(/\//g, ' / ');
  // replace \ --> {space}|{space}
  val = val.replace(/\\/g, ' | ');
  // replace | --> {space}|{space}
  val = val.replace(/\|/g, ' | ');
  // replace {space}<--{space} --> {space}←{space}
  val = val.replace(/ <-- /g, ' \u2190 ');
  // replace {space}-->{space} --> {space}→{space}
  val = val.replace(/ --> /g, ' \u2192 ');
  // replace {space}<---{space} --> {space}←{space}
  val = val.replace(/ <--- /g, ' \u2190 ');
  // replace {space}--->{space} --> {space}→{space}
  val = val.replace(/ ---> /g, ' \u2192 ');
  // remove escaped symbols
  val = val.replace(/[\·\@\#\$\%\^\<\>\}\{\`\"\'\\]/g, '');
  // remove duplicated '-'
  val = val.replace(/\-{2,}/g,'-');
  // remove leading '-'
  val = val.replace(/^\-{1,}/g,'');
  // remove tailing '-'
  val = val.replace(/\-{1,}$/g,'');
  // remove leading '+'
  val = val.replace(/^\+{1,}/g,'');
  // remove duplicated ' '
  val = val.replace(/ {2,}/g,' ');
  // remove leading & tailing ' '
  val = val.trim();
  if (val.length > 0 ) {
    result = val;
  }
  return result;
}

// 	Transform user-entered staple Name
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export function cleanifyStapleName(value) {
  let result = '';
  let val = value || '';
  // replace {BOL}'"`{word} --> {BOL}«{word}
  val = val.replace(/^[\'\"\`]/g, '\u00AB');
  // replace '"`{word} --> «{word}
  val = val.replace(/\s[\'\"\`]| [\'\"\`]/g, ' \u00AB');
  // replace {comma}'"`{word} --> {comma}«{word}
  val = val.replace(/,[\'\"\`]/g, ',\u00AB');
  // replace {word}'"`{EOL} --> {word}»{EOL}
  val = val.replace(/[\'\"\`]$/g, '\u00BB');
  // replace {word}'"`{space} --> {word}»{space}
  val = val.replace(/[\'\"\`]\s|[\'\"\`] /g, '\u00BB ');
  // replace {word}'"`{comma} --> {word}»{comma}
  val = val.replace(/[\'\"\`],/g, '\u00BB,');
  // replace / --> {space}/{space}
  val = val.replace(/\//g, ' / ');
  // replace \ --> {space}\{space}
  val = val.replace(/\\/g, ' \\ ');
  // replace | --> {space}|{space}
  val = val.replace(/\|/g, ' | ');
  // replace {space}<--{space} --> {space}←{space}
  val = val.replace(/ <-- /g, ' \u2190 ');
  // replace {space}-->{space} --> {space}→{space}
  val = val.replace(/ --> /g, ' \u2192 ');
  // replace {space}<---{space} --> {space}←{space}
  val = val.replace(/ <--- /g, ' \u2190 ');
  // replace {space}--->{space} --> {space}→{space}
  val = val.replace(/ ---> /g, ' \u2192 ');
  // replace '"` --> '
  val = val.replace(/[\'\"\`]/g, '\u055A');
  // replace {space}*{space} --> {space}•{space}
  val = val.replace(/ \* /g, ' \u2022 ');
  // replace {space}-{space} --> {space}‒{space}
  val = val.replace(/ - /g, ' \u2012 ');
  // replace (c) --> ©
  val = val.replace(/\(c\)/g, '\u00A9');
  // replace (tm) --> ™
  val = val.replace(/\(tm\)/g, ' \u2122 ');
  // replace $ --> USD
  val = val.replace(/[\$]/g, 'USD');
  // remove escaped symbols
  val = val.replace(/[\=\[\]\’\`\;\@\#\$\%\^\&\{\}\'\"\<\>]/g, '');
  // remove duplicated '-'
  val = val.replace(/\-{2,}/g,'-');
  // remove duplicated ' '
  val = val.replace(/ {2,}/g,' ');
  // remove leading & tailing ' '
  val = val.trim();
  if (val.length > 0 ) {
    result = val;
  }
  return result;
}

// 	Transform user-entered detail text
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export function cleanifyDetail(value) {
  return cleanifyDescription(value)
}

// 	Transform user-entered Text Description
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export function cleanifyDescription(value) {
  let result = '';
  let val = value || '';
  // replace {BOL}'"`{word} --> {BOL}«{word}
  val = val.replace(/^[\'\"\`]/g, '\u00AB');
  // replace '"`{word} --> «{word}
  val = val.replace(/\s[\'\"\`]| [\'\"\`]/g, ' \u00AB');
  // replace {comma}'"`{word} --> {comma}«{word}
  val = val.replace(/,[\'\"\`]/g, ',\u00AB');
  // replace {word}'"`{EOL} --> {word}»{EOL}
  val = val.replace(/[\'\"\`]$/g, '\u00BB');
  // replace {word}'"`{space} --> {word}»{space}
  val = val.replace(/[\'\"\`]\s|[\'\"\`] /g, '\u00BB ');
  // replace {word}'"`{comma} --> {word}»{comma}
  val = val.replace(/[\'\"\`],/g, '\u00BB,');
  // replace '"` --> '
  val = val.replace(/[\'\"\`]/g, '\u055A');
  // replace {space}*{space} --> {space}•{space}
  val = val.replace(/ \* /g, ' \u2022 ');
  // replace {space}-{space} --> {space}‒{space}
  val = val.replace(/ - /g, ' \u2012 ');
  // replace (c) --> ©
  val = val.replace(/\(c\)/g, '\u00A9');
  // replace (tm) --> ™
  val = val.replace(/\(tm\)/g, ' \u2122 ');
  // replace $ --> USD
  val = val.replace(/[\$]/g, 'USD');
  // trim left
  val = val.replace(/^\s+/g, '');
  // remove escaped symbols
  val = val.replace(/[\[\]\’\`\$\%\^\&\{\}\\\/\'\"\<\>]/g, '');
  if (val.length > 0 ) {
    result = val;
  }
  return result;
}

// 	Transform user-entered CharField
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export function cleanifyCharField(value) {
  let result = '';
  let val = value || '';
  // replace {BOL}'"`{word} --> {BOL}«{word}
  val = val.replace(/^[\'\"\`]/g, '\u00AB');
  // replace '"`{word} --> «{word}
  val = val.replace(/\s[\'\"\`]| [\'\"\`]/g, ' \u00AB');
  // replace {comma}'"`{word} --> {comma}«{word}
  val = val.replace(/,[\'\"\`]/g, ',\u00AB');
  // replace {word}'"`{EOL} --> {word}»{EOL}
  val = val.replace(/[\'\"\`]$/g, '\u00BB');
  // replace {word}'"`{space} --> {word}»{space}
  val = val.replace(/[\'\"\`]\s|[\'\"\`] /g, '\u00BB ');
  // replace {word}'"`{comma} --> {word}»{comma}
  val = val.replace(/[\'\"\`],/g, '\u00BB,');
  // replace '"` --> '
  val = val.replace(/[\'\"\`]/g, '\u055A');
  // replace {space}*{space} --> {space}•{space}
  val = val.replace(/ \* /g, ' \u2022 ');
  // replace {space}-{space} --> {space}‒{space}
  val = val.replace(/ - /g, ' \u2012 ');
  // replace (c) --> ©
  val = val.replace(/\(c\)/g, '\u00A9');
  // replace (tm) --> ™
  val = val.replace(/\(tm\)/g, ' \u2122 ');
  // replace $ --> USD
  val = val.replace(/[\$]/g, 'USD');
  // replace / --> ' / '
  val = val.replace(/[\/]/g, ' / ');
  // replace \ --> ' \ '
  val = val.replace(/[\\]/g, ' \ ');
  // trim left
  val = val.replace(/^\s+/g, '');
  // remove escaped symbols
  val = val.replace(/[\[\]\’\`\$\%\^\&\{\}\<\>]/g, ''); // []’`$%^&{}<>
  // remove duplicated '-'
  val = val.replace(/\-{2,}/g,'-');
  // remove duplicated ' '
  val = val.replace(/ {2,}/g,' ');
  // remove leading & tailing ' '
  val = val.trim();
  if (val.length > 0 ) {
    result = val;
  }
  return result;
}

// 	Transform user-entered Markdown
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export function cleanifyMarkdownData(value) {
  let result = '';
  let val = value || '';
  // replace {BOL}'"`{word} --> {BOL}«{word}
  val = val.replace(/^[\'\"\`]/g, '\u00AB');
  // replace '"`{word} --> «{word}
  val = val.replace(/\s[\'\"\`]| [\'\"\`]/g, ' \u00AB');
  // replace {comma}'"`{word} --> {comma}«{word}
  val = val.replace(/,[\'\"\`]/g, ',\u00AB');
  // replace {word}'"`{EOL} --> {word}»{EOL}
  val = val.replace(/[\'\"\`]$/g, '\u00BB');
  // replace {word}'"`{space} --> {word}»{space}
  val = val.replace(/[\'\"\`]\s|[\'\"\`] /g, '\u00BB ');
  // replace {word}'"`{comma} --> {word}»{comma}
  val = val.replace(/[\'\"\`],/g, '\u00BB,');
  // replace '"` --> '
  val = val.replace(/[\'\"\`]/g, '\u055A');
  // replace {space}*{space} --> {space}•{space}
  val = val.replace(/ \* /g, ' \u2022 ');
  // replace {space}-{space} --> {space}‒{space}
  val = val.replace(/ - /g, ' \u2012 ');
  // replace (c) --> ©
  val = val.replace(/\(c\)/g, '\u00A9');
  // replace (tm) --> ™
  val = val.replace(/\(tm\)/g, ' \u2122 ');
  // replace $ --> USD
  val = val.replace(/[\$]/g, 'USD');
  // trim left
  val = val.replace(/^\s+/g, '');
  // remove escaped symbols
  val = val.replace(/[\’\`\$\^\&\{\}\|\\\'\"\<]/g, '');
  if (val.length > 0 ) {
    result = val;
  }
  return result;
}

// 	Transform user-entered Email
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export function cleanifyEmail(value) {
  let result = '';
  let val = value || '';
  // lowercase
  val = val.trim().toLowerCase();
  // replace 'space' --> '-' (in case of copy-paste)
  val = val.replace(/[ ]/g, '-');
  // remove escaped symbols
  val = val.replace(/[\`\~\!\#\$\%\^\&\*\_\=\+\?\/\\\<\>\|\}\{\[\]\(\)\"\'\,\:\;]/g, '');
  // remove duplicated '-'
  val = val.replace(/-{2,}/g,'-');
  // remove leading '-'
  val = val.replace(/^-{1,}/g,'');
  // remove tailing '-'
  val = val.replace(/-{1,}$/g,'');
  if (val.length > 0 ) {
    result = val;
  }
  return result;
}

// 	Transform user-entered Url
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// ToDo: IMPLEMENT !!!
export function cleanifyUrl(value) {
  let result = '';
  let val = value || '';
  // lowercase
  val = val.trim().toLowerCase();
  // replace 'space' --> '-' (in case of copy-paste)
  val = val.replace(/[ ]/g, '-');
  // remove escaped symbols
  // val = val.replace(/[\@\`\~\!\#\$\%\^\&\*\_\=\+\?\/\\\<\>\|\}\{\[\]\(\)\"\'\,\:\;]/g, '');
  // remove duplicated '-'
  val = val.replace(/-{2,}/g,'-');
  // remove leading '-'
  val = val.replace(/^-{1,}/g,'');
  // remove tailing '-'
  val = val.replace(/-{1,}$/g,'');
  if (val.length > 0 ) {
    result = val;
  }
  return result;
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export function cleanifyMeta(str) {
  return str ?
    ('' + str).slice(0, 120)
      .replace(/[\n\t\"\'\“\”\`\\]/g, ' ')  // видалення перенесення стрічок та табуляцій
      .replace(/ {2,}/g,' ')                // видалення декількох пробілів підряд
      .trim()                               // видалення зайвих пробілів
    : '';
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export function cleanifyIFrame(str) {
  return str ?
    ('' + str).slice(0, 600)
      .replace(/[\n\t]/g, ' ')              // видалення перенесення стрічок та табуляцій
      .replace(/ {2,}/g,' ')                // видалення декількох пробілів підряд
      .trim()                               // видалення зайвих пробілів
    : '';
}

// 	Validate GroupName
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export function isGroupNameValid(value) {
  return cleanifyGroupName(value).length > 0;
}

// 	Validate ContactName
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export function isContactNameValid(value) {
  return cleanifyContactName(value).length > 0;
}

// 	Validate AdvertName
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export function isAdvertNameValid(value) {
  return cleanifyAdvertName(value).length > 0;
}

// 	Validate CollectionName
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export function isCollectionNameValid(value) {
  return cleanifyCollectionName(value).length > 0;
}

// 	Validate StapleName
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export function isStapleNameValid(value) {
  return cleanifyStapleName(value).length > 0;
}

// 	Validate MarkdownData
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export function isMarkdownDataValid(value) {
  return cleanifyMarkdownData(value).length > 0;
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export function encodeBase62(code) {
  let hash = '';
  let rest = code;
  while (rest > 0) {
    hash = BASE62[parseInt(rest % 62)] + hash;
    rest = Math.floor(rest / 62);
  }
  return hash;
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export function decodeBase62(encoded) {
  let intStr = 0;
  for (let i = 0; i < encoded.length; i++) {
    let char, n, power;
    char = encoded[i];
    n = BASE62.indexOf(char);
    if (n === -1) {
      return -1; // bad hash
    }
    power = (encoded.length-1)-i;
    intStr += n * Math.pow(62, power);
  }
  return parseInt(intStr);
}
