// -------------------------------------------------------------------------------------------------
//  Tabbar.js
//  - - - - - - - - - -
//  A tab bar lets the user switch quickly between different sections of an app.
//  In general, use a tab bar to organize information at the app level.
// -------------------------------------------------------------------------------------------------
import React from 'react';
import Types from 'prop-types';
import {NavLink} from 'react-router-dom';
import classnames from 'classnames';
import {t} from 'ttag';
import {BASIC_ACCOUNT, PRO_ACCOUNT, PREMIUM_ACCOUNT, ENTERPRISE_ACCOUNT, ADMIN_ACCOUNT} from 'core/accountTypes';
import {scrollWrapperToTop} from 'application/LayerSwitch';
import {selectAction} from 'components/UI/SelectAction';
import Icon from 'components/UI/icons/Icon';
import styles from './Tabbar.scss';

const isVerbose = DEBUG && true;
const prefix = '- - - Tabbar';

function trace(msg, ...other) { if (isVerbose) { console.log(`${prefix}.${msg}`, ...other); }}
function traceWarn(msg, ...other) { if (isVerbose) { console.warn(`${prefix}.${msg}`, ...other); }}
function traceError(msg, ...other) { console.error(`${prefix}.${msg}`, ...other); }

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
const IconTab = ({to, exact, symbolName, badgeText, isActive}) => (
  <NavLink to={to} exact={exact} activeClassName={styles.isActive} isActive={isActive}>
    <Icon symbolName={symbolName} className={styles.icon} />
    {badgeText && <span className={styles.badgeText}>{badgeText}</span>}
  </NavLink>
)

// // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// const TextTab = ({to, exact, text, isActive}) => (
//   <NavLink to={to} exact={exact} activeClassName={styles.isActive} isActive={isActive}>
//     <span className={styles.tabText}>{text}</span>
//   </NavLink>
// )

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export default class Tabbar extends React.Component {
  static propTypes = {
    myAccount: Types.object,              // акаунт користувача
    fsrCount: Types.string,               // к-сть отриманих запитів дружби
    topicUnreadCount: Types.string,       // к-сть непрочитаних повідомлень для тематичних чатів
    communityUnreadCount: Types.string,   // к-сть непрочитаних повідомлень для прямих чатів
    csubscriptionUnreadCount: Types.string, // к-сть непрочитаних повідомлень для підписок
    isSidebarOpen: Types.bool,            // is sidebar open?
    onToggleSidebar: Types.func,          // sidebar toggle handler
  }

  static contextTypes = {
    router: Types.object.isRequired,
  }

  handleClick = (e) => {
    const {isSidebarOpen, onToggleSidebar} = this.props;
    if (isSidebarOpen) {
      onToggleSidebar && onToggleSidebar();
    }
    scrollWrapperToTop && scrollWrapperToTop();
  }

  isHomeButtonActive = () => {
    const {myAccount} = this.props;
    const {slug:mySlug} = myAccount || {};
    const {pathname} = this.context
    && this.context.router
    && this.context.router.route
    && this.context.router.route.location || '';
    if (pathname !== '/g/create' && ('' + pathname).indexOf('/g/') === 0) return true; // attn: усі групи === власні !!!
    return [
      '/' + mySlug + '/collections',
      '/' + mySlug + '/courses',
      '/' + mySlug + '/items',
      '/' + mySlug + '/ads',
      '/my/contacts',
      '/my/groups',
      '/my/workspace',
      '/my/friends',
      '/my/invitations'].some(s => pathname.includes(s));
  }

  handleCreateStaple = () => {
    trace(`handleCreateStaple`);
    this.context.router.history.push(`/i/create`);
  }

  handleCreateCollection = () => {
    trace(`handleCreateCollection`);
    this.context.router.history.push(`/co/create`);
  }

  handleCreateCourse = () => {
    trace(`handleCreateCourse`);
    this.context.router.history.push(`/crs/create`);
  }

  handleCreateContact = () => {
    trace(`handleCreateContact`);
    this.context.router.history.push(`/k/create`);
  }

  handleCreateGroup = () => {
    trace(`handleCreateGroup`);
    this.context.router.history.push(`/g/create`);
  }

  handleInviteFriend = () => {
    trace(`handleInviteFriend`);
    this.context.router.history.push(`/f/invite`);
  }

  handleSelectAction = () => {
    trace(`handleSelectAction`);
    selectAction([
      {title: t`Create Staple`, symbolName: "textbox-outline", onClick: this.handleCreateStaple},
      {title: t`Create Collection`, symbolName: "folder-open", onClick: this.handleCreateCollection},
      {title: t`Create Course`, symbolName: "school", onClick: this.handleCreateCourse},
      {title: t`Create Contact`, symbolName: "account-circle", onClick: this.handleCreateContact},
      {title: t`Create Group`, symbolName: "people3", onClick: this.handleCreateGroup},
      {title: t`Invite a Friend by Email`, symbolName: "person", onClick: this.handleInviteFriend}
    ]);
  }

  render() {
    const {
      myAccount,
      fsrCount,
      topicUnreadCount,
      communityUnreadCount,
      csubscriptionUnreadCount,
      isSidebarOpen,
      onToggleSidebar} = this.props;
    const {slug:mySlug, accountType:myAccountType} = myAccount || {};
    trace(`render`);
    return (
      <nav className={styles.Tabbar}>
        <ul>
          {myAccountType && myAccountType === BASIC_ACCOUNT &&
            <li onClick={this.handleClick}>
            {/*
              <TextTab
                text={t`Pricing Page`}
                to="/pricing"
              />
            */}
              <IconTab
                symbolName="gift"
                to="/pricing"
              />
            </li>
          }
          <li onClick={this.handleClick}>
            <IconTab
              symbolName="home"
              to={`/${mySlug}`}
              badgeText={fsrCount}
              isActive={this.isHomeButtonActive}
            />
          </li>
          <li onClick={this.handleClick}>
            <IconTab
              // symbolName={topicUnreadCount ? "inbox-arrow-down" : "inbox-full"}
              symbolName={topicUnreadCount ? "inbox-full" : "inbox-full"}
              to="/my/topics"
              badgeText={topicUnreadCount}
            />
          </li>
          <li onClick={this.handleClick}>
            <IconTab
              symbolName="chat-directtype"
              to="/my/community"
              badgeText={communityUnreadCount}
            />
          </li>
          <li onClick={this.handleClick}>
            <IconTab
              symbolName="bookmark-education"
              to="/my/subscriptions"
              badgeText={csubscriptionUnreadCount}
            />
          </li>
        {/* - - - ToDo: Hidden functionality of Neuralnets !!!
          <li onClick={this.handleClick}>
            <IconTab
              symbolName="openai"
              to="/gpt/topics"
            />
          </li>
        */}
          <li onClick={this.handleSelectAction}>
            <Icon symbolName="add" className={styles.icon} />
          </li>
          <li className={classnames(styles.toggleSidebarTab, {[styles.isSidebarOpen]: isSidebarOpen})} onClick={onToggleSidebar}>
            <Icon symbolName={isSidebarOpen ? 'sidebar-open' : 'sidebar-closed'} className={styles.icon} />
          </li>
        </ul>
      </nav>
    );
  }
}
