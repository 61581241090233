// -------------------------------------------------------------------------------------------------
//  AuthActions.js
//  - - - - - - - - - -
//  Authentication (login/logout/registration) actions.
// -------------------------------------------------------------------------------------------------
import ReactGA from 'react-ga4';
import Dispatcher from 'dispatcher/Dispatcher';
import {LOGGED_IN_ACTION, LOGGED_OUT_ACTION} from 'core/actionTypes';
import {LS_PREV_BASE_LOCATION, LS_PREV_SUBMODAL_LOCATION} from 'core/commonTypes';
import {gaCategories, gaActions} from 'core/gaTypes';
import {apiLogin, apiLogout} from 'api/AuthAPI';
import {wsockInit, wsockDrop} from 'api/WebSocketAPI';
import {fetchMyAccount} from 'actions/AccountActions';
import {fetchUnreadMessages} from 'actions/ChatActions';
import {fetchStarredStapleIds} from 'actions/StapleActions';
import {fetchMyCollections, fetchFollowedCollectionIds, fetchCSubscribedCollectionIds} from 'actions/CollectionActions';
import {fetchMyContacts} from 'actions/ContactActions';
import {fetchMyFriends} from 'actions/UserActions';
import {toggleSidebar} from 'actions/LayoutActions';

const isVerbose = DEBUG && true;
const prefix = '- - - AuthActions';

function trace(msg, ...other) { if (isVerbose) { console.log(`${prefix}.${msg}`, ...other); }}
function traceWarn(msg, ...other) { if (isVerbose) { console.warn(`${prefix}.${msg}`, ...other); }}
function traceError(msg, ...other) { console.error(`${prefix}.${msg}`, ...other); }

// FixMe: з певного моменту не піднімається сокет, тому не можемо отримати дані акаунта !!!
//
// Attn: 1) await fetchMyContacts + виклик першим, щоб створити юзерів з полями contact**,
//            більш детально причини описані в описі до функції;
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function login(isMobile = true) {
  const authToken = await apiLogin();
  if (authToken) {
    await wsockInit('login');
    const {myId, mySlug, languageId} = await fetchMyAccount();
    await fetchMyContacts(); // sic!: 1)
    fetchMyFriends();
    fetchMyCollections();
    fetchUnreadMessages();
    fetchStarredStapleIds();
    fetchFollowedCollectionIds();
    fetchCSubscribedCollectionIds();
    toggleSidebar(!isMobile);
    Dispatcher.dispatch({type: LOGGED_IN_ACTION});
    ReactGA.ga('set', 'userId', myId);
    if (window && window.doorbellOptions) {
      window.doorbellOptions.tags = mySlug;
    }
    return {authToken, mySlug, languageId};
  } else {
    toggleSidebar(false);
    Dispatcher.dispatch({type: LOGGED_OUT_ACTION});
    ReactGA.event({
      category: gaCategories.AUTHENTICATION,
      action: gaActions.LOGIN_FAILED
    });
    return {};
  }
}

// Attn: 1) await fetchMyContacts + виклик першим, щоб створити юзерів з полями contact**,
//          більш детально причини описані в описі до функції;
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function relogin() {
  const authToken = localStorage.getItem('authToken');
  if (authToken) {
    await wsockInit('relogin');
    const {myId, mySlug, languageId} = await fetchMyAccount();
    await fetchMyContacts(); // sic!: 1)
    fetchMyFriends();
    fetchMyCollections();
    fetchUnreadMessages();
    fetchStarredStapleIds();
    fetchFollowedCollectionIds();
    fetchCSubscribedCollectionIds();
    window.addEventListener('online', wsockInit); // wsockInit restarts websocket when Internet connection is restored
    Dispatcher.dispatch({type: LOGGED_IN_ACTION});
    ReactGA.ga('set', 'userId', myId);
    if (window && window.doorbellOptions) {
      window.doorbellOptions.tags = mySlug;
    }
  } else {
    toggleSidebar(false);
    Dispatcher.dispatch({
      type: LOGGED_OUT_ACTION,
    });
    ReactGA.event({
      category: gaCategories.AUTHENTICATION,
      action: gaActions.RELOGIN_FAILED
    });
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function logout(mode) {
  await wsockDrop('logout');
  // - - - - -
  //  sic!:
  //  Затирання попередніх локейшенів повинно бути ДО вилогінювання,
  //  інакше LayerSwitch переадресовує на ще незатерту адресу,
  //  що дає помилку 404 якщо юзер вилогінився (коли попередня адреса є адресою приватної
  //  сторінки цього юзера).
  // - - - - -
  localStorage.setItem(LS_PREV_BASE_LOCATION, null);
  localStorage.setItem(LS_PREV_SUBMODAL_LOCATION, null);
  Dispatcher.dispatch({
    type: LOGGED_OUT_ACTION,
  });
  toggleSidebar(false);
  await apiLogout();
}
