// -------------------------------------------------------------------------------------------------
//  uiTypes.js
//  - - - - - - - - - -
//  Типи, що відносяться до користувацького інтерфейсу (UI).
// -------------------------------------------------------------------------------------------------

// sidebarTabTypes:
export const COLLECTION_TAB                         = 'cx';       // вкладка колекцій (список колекцій)
export const GROUP_TAB                              = 'gx';       // вкладка груп (список груп)
export const SUBJECT_TAB                            = 'jx';       // вкладка предметів (список предметів)
export const COMMUNITY_TAB                          = 'cmt';      // вкладка кола спілкування (список активних чатів + список друзів)
export const ACTION_TAB                             = 'act';      // вкладка можливих дій (кнопка створення прямого чату, ...)
export const CHAT_TAB                               = 'h';        // вкладка чату (чат)
export const RTC_TAB                                = 'rtc';      // вкладка WebRTC дзвінків
export const SELECTION_TAB                          = 'sel';      // вкладка роботи з виділеними обʼєктами (список виділених стейплів + контактів)

// viewModes:
export const BRICK_VIEW                             = 'b';        // блочний вигляд
export const MASONRY_VIEW                           = 'm';        // masonry вигляд
export const SNIPPET_VIEW                           = 'n';        // фрагментний вигляд
export const STRIPE_VIEW                            = 's';        // стрічковий вигляд

// masonry:
export const MASONRY_ITEM_CSS                       = 'msItem';   // css-клас, до якого додаються masonry-стилі (len=6, щоб не було конфліктів з css) !!!
export const MASONRY_COLUMN_WIDTH                   = 256;        // ширина колонок
export const MASONRY_GUTTER_SIZE                    = 10;         // ширина проміжку між колонками

// sendModes:
export const ALL_SELECTED_MODE                      = '1';        //
export const SEND_FSR_MODE                          = '2';        //
export const SEND_DOCUMENT_MODE                     = '3';        //

// feedTypes: при внесенні змін оновлювати функцію AccountStore.UISettings() !!!
export const MY_TOPIC_COLLECTIONS_FEED              = 'mco';      // my topic collections feed
export const MY_TOPIC_COURSES_FEED                  = 'mcr';      // my topic courses feed
export const MY_TOPIC_STAPLES_FEED                  = 'mst';      // my topic staples feed
export const MY_CSUBSCRIPTIONS_FEED                 = 'mss';      // my csubscriptions feed
export const MY_CSUBSCRIPTION_STAPLES_FEED          = 'sss';      // my csubscription staples feed
export const MY_COMMUNITY_USERS_FEED                = 'mcu';      // my community users feed
export const MY_FRIENDS_FEED                        = 'mfr';      // my friends feed
export const MY_FSR_USERS_FEED                      = 'mfs';      // my friendship request users feed
export const MY_CONTACTS_FEED                       = 'mkx';      // my contacts feed
export const MY_GROUPS_FEED                         = 'mgx';      // my groups feed
export const MY_GROUP_CONTACTS_FEED                 = 'mgk';      // my group contacts feed
export const MY_WORKSPACE_STAPLES_FEED              = 'mws';      // my workspace staples feed
export const DISCOVER_ADVERTS_FEED                  = 'dad';      // discover adverts feed
export const DISCOVER_COLLECTIONS_FEED              = 'dco';      // discover collections feed
export const DISCOVER_COURSES_FEED                  = 'dcr';      // discover courses feed
export const DISCOVER_STAPLES_FEED                  = 'dst';      // discover staples feed
export const DISCOVER_USERS_FEED                    = 'dus';      // discover users feed
export const FEATURED_COLLECTIONS_FEED              = 'fco';      // featured collections feed
export const FEATURED_COURSES_FEED                  = 'fcr';      // featured courses feed
export const FEATURED_STAPLES_FEED                  = 'fst';      // featured staples feed
export const FEATURED_USERS_FEED                    = 'fus';      // featured users feed
export const FOLLOWING_STAPLES_FEED                 = 'fwg';      // following staples feed
export const SEARCHED_COLLECTIONS_FEED              = 'sco';      // searched collections feed
export const SEARCHED_COURSES_FEED                  = 'scr';      // searched courses feed
export const SEARCHED_STAPLES_FEED                  = 'sst';      // searched staples feed
export const SEARCHED_USERS_FEED                    = 'sus';      // searched users feed
export const COLLECTION_STAPLES_FEED                = 'cos';      // collection staples feed
export const COURSE_STAPLES_FEED                    = 'cst';      // collection staples feed
export const SUBJECT_ADVERTS_FEED                   = 'jad';      // subject adverts feed
export const SUBJECT_COLLECTIONS_FEED               = 'jco';      // subject collections feed
export const SUBJECT_COURSES_FEED                   = 'jcr';      // subject courses feed
export const USER_ADVERTS_FEED                      = 'uad';      // user adverts feed
export const USER_COLLECTIONS_FEED                  = 'uco';      // user collections feed
export const USER_COURSES_FEED                      = 'ucr';      // user courses feed
export const USER_STAPLES_FEED                      = 'ust';      // user staples feed
export const USER_TOPIC_STAPLES_FEED                = 'uts';      // user topic staples feed
