// -------------------------------------------------------------------------------------------------
//  CollectionStore.js
//  - - - - - - - - - -
//  Collections & MyCollections.
//
//  Attn: *)
//  - - - - -
//  - поля id, value, label потрібні для Select2 (геттери: value, label);
//  - при розміщенні обʼєктів в стор перетворюємо назви полів: snake_case --> camelCase;
//  - список власних колекцій завантажуюємо з серверу весь список відразу;
//    після додавання нових колекцій на клієнті ми перезавантажуємо список з сервера заново (???);
//  - суфікс '...Feed' в назві функції показує, що функція повертає відразу повний набір
//    параметрів фіду (напр: {collections, collectionCursor, areCollectionsLoaded});
//
//  Attn: a)
//  - - - - -
//  - для кожного фіду колекцій є курсор зі значенням ідентифікатору найдавнішої колекції (collectionFeeds);
//  - власні колекції користувач отримує одним запитом, тому для зменшення к-сті перевірок
//    створююємо курсори для кожного типу з ТИМ САМИМ значенням;
//  - для колекцій інших юзерів для кожного типу колекції є окремий курсор з окремим значенням;
//  - формат ключів collectionFeeds:
//    PREFIX + userId + collectionType <-- для фідів кожного типу колекцій інших юзерів (напр: 'u19c4bGGhN00.')
//
//  Attn: b)
//  - - - - -
//  - список колекцій, за якими користувач стежить (followedCollections) зберігаємо в collection,
//    тому при отриманні додаємо порожній запис з заповнений флагом isFollowed;
//  - список колекцій, на які користувач підписаний (subscribedCollections) зберігаємо в collection,
//    тому при отриманні додаємо порожній запис з заповнений флагом isSubscribed;
//
//  Attn: c)
//  - - - - -
//  - підписка CSubscription є надмножиною над Collection, тому просто розширив record колекції
//    полями підписки; з тієї ж причини що не робив окремої обробки та окремого списку
//    для підписок користувача на курс-колекції;
//
//  Attn: d)
//  - - - - -
//  - завантажена колекція = це коли про неї є ПОВНА інфа (признак: заповнене поле ownerId),
//    бо може прилітати часткова інформація (напр: список ідентифікаторів зафоловлених колекцій);
// -------------------------------------------------------------------------------------------------
import {ReduceStore} from 'flux/utils';
import {Map, Set, Record} from 'immutable';
import camelcaseKeys from 'camelcase-keys';
import Dispatcher from 'dispatcher/Dispatcher';
import {
  MY_ACCOUNT_WAS_FETCHED_ACTION,
  STAPLE_WAS_CREATED_ACTION,
  STAPLE_WAS_CLONED_ACTION,
  STAPLE_WAS_UPDATED_ACTION,
  STAPLES_WERE_DELETED_ACTION,
  DISCOVER_COLLECTIONS_WERE_FETCHED_ACTION,
  FEATURED_COLLECTIONS_WERE_FETCHED_ACTION,
  SEARCHED_COLLECTIONS_WERE_FETCHED_ACTION,
  SUBJECT_COLLECTIONS_WERE_FETCHED_ACTION,
  USER_COLLECTIONS_WERE_FETCHED_AS_GUEST_ACTION,
  USER_COLLECTIONS_WERE_FETCHED_ACTION,
  MY_CSUBSCRIPTIONS_WERE_FETCHED_ACTION,
  MY_COLLECTIONS_WERE_FETCHED_ACTION,
  COLLECTION_WAS_FETCHED_AS_GUEST_ACTION,
  COLLECTION_WAS_FETCHED_ACTION,
  COLLECTION_WAS_CREATED_ACTION,
  COLLECTION_WAS_UPDATED_ACTION,
  COLLECTION_WAS_BANNED_ACTION,
  COLLECTION_WAS_DELETED_ACTION,
  COLLECTION_WAS_FOLLOWED_ACTION,
  COLLECTION_WAS_UNFOLLOWED_ACTION,
  FOLLOWED_COLLECTION_IDS_WERE_FETCHED_ACTION,
  CSUBSCRIBED_COLLECTION_IDS_WERE_FETCHED_ACTION,
  CSUBSCRIPTION_WAS_FETCHED_ACTION,
  CSUBSCRIPTION_WAS_CREATED_ACTION,
  CSUBSCRIPTION_WAS_CANCELED_ACTION,
  COLLECTION_PENDING_FIELDS_WERE_CHANGED_ACTION,
  COLLECTION_PENDING_FIELDS_WERE_SENT_ACTION,
  COLLECTION_PENDING_FIELDS_WERE_PROCESSED_ACTION,
  LOGGED_OUT_ACTION} from 'core/actionTypes';
import {
  ID_FLD,
  TYPE_FLD,
  OWNER_ID_FLD,
  LANGUAGE_ID_FLD,
  CSUBSCRIPTION_FLD,
  CSUBSCRIPTION_ID_FLD,
  CSUBSCRIPTION_IDS_FLD,
  CSUBSCRIPTION_STATUS_FLD,
  CSUBSCRIPTION_ACCESS_COST_FLD,
  CSUBSCRIPTION_PAID_AMOUNT_FLD,
  CSUBSCRIPTION_CREATED_AT_FLD,
  CSUBSCRIPTION_STARTED_AT_FLD,
  CSUBSCRIPTION_ENDED_AT_FLD,
  CSUBSCRIPTIONS_FLD,
  NAME_FLD,
  DESCRIPTION_FLD,
  COMMENTS_FLD,
  ATTACHMENTS_FLD,
  SUBJECTS_FLD,
  INTERESTS_FLD,
  ACCESS_COST_FLD,
  INITIAL_DROP_SIZE_FLD,
  RECURRING_DROP_SIZE_FLD,
  NEXT_DROP_DELAY_FLD,
  FOLLOWERS_QTY_FLD,
  STAPLES_QTY_FLD,
  PICTURE_XHASH2_FLD,
  PICTURE_EXT_FLD,
  PICTURES_FLD,
  BLOB_FLD,
  BLOBS_FLD,
  COLOR_TAGS_FLD,
  COLOR_LABELS_FLD,
  CREATED_AT_FLD,
  IS_PUBLIC_FLD,
  IS_PUBLISHED_FLD,
  IS_EDITED_FLD} from 'core/apiFields';
import {
  DEFAULT_COLLECTION,
  COURSE_COLLECTION,
  STAR_COLLECTION,
  PRIVATE_PRIVACY,
  PUBLIC_PRIVACY,
  MISC_PRIVACY,
  LS_SETTINGS,
  DEFAULT_TSN12_CURSOR} from 'core/commonTypes';
import {
  MY_CSUBSCRIPTIONS_FEED,
  DISCOVER_COLLECTIONS_FEED,
  FEATURED_COLLECTIONS_FEED,
  SEARCHED_COLLECTIONS_FEED,
  SUBJECT_COLLECTIONS_FEED,
  USER_COLLECTIONS_FEED} from 'core/uiTypes';
import {ENGLISH_LANG, UKRAINIAN_LANG} from 'core/languageTypes';
import {toReact} from 'components/RichEditor/rich-conmark-processors';
import {composePictureUrl} from 'utils/settingsTools';
import {slugify} from 'utils/converters';

const DISCOVER_COLLECTIONS_PREFIX         = DISCOVER_COLLECTIONS_FEED;  // префікс ключа для discover-фіду колекцій
const FEATURED_COLLECTIONS_PREFIX         = FEATURED_COLLECTIONS_FEED;  // префікс ключа для featured-фіду колекцій
const SEARCHED_COLLECTIONS_PREFIX         = SEARCHED_COLLECTIONS_FEED;  // префікс ключа для search-фіду колекцій
const SUBJECT_COLLECTIONS_PREFIX          = SUBJECT_COLLECTIONS_FEED;   // префікс ключа для фіду колекцій до предмету
const USER_COLLECTIONS_PREFIX             = USER_COLLECTIONS_FEED;      // префікс ключа для фіду колекцій юзера
const MY_CSUBSCRIPTIONS_PREFIX            = MY_CSUBSCRIPTIONS_FEED;     // префікс ключа для фіду підписок користувача на курс-колекції

const isVerbose = DEBUG && true;
const prefix = '- - - CollectionStore';

function trace(msg, ...other) { if (isVerbose) { console.log(`${prefix}.${msg}`, ...other); }}
function traceWarn(msg, ...other) { if (isVerbose) { console.warn(`${prefix}.${msg}`, ...other); }}
function traceError(msg, ...other) { console.error(`${prefix}.${msg}`, ...other); }

// Attn: 1) - теоретично до колекції можна вкласти довільну к-сть зображень, тому формат {{ ATTACHMENTS }} містить СПИСОК картинок;
//          - практично ПОКИ ЩО використовуємо лише ОДНЕ зображення, тому в сторі Collection містить поля {xhash2, ext, blob};
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
const MyCollectionRecord = Record({
  id: null,                               // sic!: required by SelectField !!!
  type: '',                               // тип колекції
  ownerId: '',                            // sic!: d) власник колекції (признак завантаження !!!)
  [LANGUAGE_ID_FLD]: ENGLISH_LANG,        // мова колекції
  sline: '',                              // sic!: required by SelectField !!!
  name: '',                               // назва колекції
  description: '',                        // опис колекції від власника (Conmark)
  comments: '',                           // непублічні коментарі від власника про колекцію (Conmark)
  [SUBJECTS_FLD]: '[]',                   // список ідентифікаторів предметів в форматі {{ SJ-LIST-STR }}
  [INTERESTS_FLD]: '[]',                  // список ідентифікаторів інтересів в форматі {{ IT-LIST-STR }}
  [STAPLES_QTY_FLD]: 0,                   // к-сть стейплів в колекції
  [FOLLOWERS_QTY_FLD]: 0,                 // к-сть послідовників колекції
  [PICTURE_XHASH2_FLD]: '',               // sic!: 1) хеш-код файлу превьюшки
  [PICTURE_EXT_FLD]: '',                  // sic!: 1) розширення файлу превьюшки
  [BLOB_FLD]: '',                         // sic!: 1) завантажене в памʼять зображення
  [CREATED_AT_FLD]: null,                 // дата/час створення колекції
  isPublic: true,                         // sic!: для розрахунку privacy чужих колекцій (true = щоб не було значка приватності)
  isPublished: false,                     // флаг публікації колекції/курсу
  colorTags: 0,                           // біти кольорових тегів колекції (2byte - 1bit = 15bit)
  // ...csubscription-settings:
  accessCost: 0,                          // вартість доступу до контенту колекції
  [INITIAL_DROP_SIZE_FLD]: 0,             // к-сть стейплів для початкового дропу (промо-стейпли)
  [RECURRING_DROP_SIZE_FLD]: 1,           // к-сть стейплів для чергового дропу (якщо 0, то вручну)
  [NEXT_DROP_DELAY_FLD]: 0,               // затримка до висилки наступного дропу (в секундах)
  // ...csubscription:
  [CSUBSCRIPTION_ID_FLD]: '',             // sic!: c) код підписки
  [CSUBSCRIPTION_STATUS_FLD]: '',         // sic!: c) статус підписки (initial, active, paused, canceled, completed, ...)
  [CSUBSCRIPTION_ACCESS_COST_FLD]: 0,     // sic!: c) вартість доступу (копія з поля Collection.access_cost)
  [CSUBSCRIPTION_PAID_AMOUNT_FLD]: 0,     // sic!: c) сума отриманих коштів за підписку
  [CSUBSCRIPTION_CREATED_AT_FLD]: null,   // sic!: c) дата/час створення підписки
  [CSUBSCRIPTION_STARTED_AT_FLD]: null,   // sic!: c) дата/час початку дії підписки
  // ...custom:
  isFollowed: undefined,                  // sic!: undefined = чи фоловить користувач цю колекцію?
  isSubscribed: undefined,                // sic!: undefined = чи підписаний користувач на цю колекцію?
});

class MyCollection extends MyCollectionRecord {
  get privacy() {
    return this.isPublic ?
      PUBLIC_PRIVACY :
      PRIVATE_PRIVACY;
  }
  get value() {                           // required by SelectField !!!
    return this.sline;
  }
  get label() {                           // required by SelectField !!!
    return this.name;
  }
  get languageId() {
    return this[LANGUAGE_ID_FLD];
  }
  get descriptionHtml() {
    return toReact(this.description);
  }
  get commentsHtml() {
    return toReact(this.comments);
  }
  get lstSubjects() {
    return JSON.parse(this[SUBJECTS_FLD]); // {{ SJ-LIST-STR }} --> {{ SJ-LIST }}
  }
  get lstInterests() {
    return JSON.parse(this[INTERESTS_FLD]); // {{ IT-LIST-STR }} --> {{ IT-LIST }}
  }
  get staplesQty() {
    return this[STAPLES_QTY_FLD];
  }
  get followersQty() {
    return this[FOLLOWERS_QTY_FLD];
  }
  get previewSsUrl() {
    return this[BLOB_FLD] ? this[BLOB_FLD] : this[PICTURE_XHASH2_FLD] ? composePictureUrl(this[PICTURE_XHASH2_FLD], 's0', this[PICTURE_EXT_FLD]) : undefined;
  }
  get previewMdUrl() {
    return this[BLOB_FLD] ? this[BLOB_FLD] : this[PICTURE_XHASH2_FLD] ? composePictureUrl(this[PICTURE_XHASH2_FLD], 'm0', this[PICTURE_EXT_FLD]) : undefined;
  }
  get previewXlUrl() {
    return this[BLOB_FLD] ? this[BLOB_FLD] : this[PICTURE_XHASH2_FLD] ? composePictureUrl(this[PICTURE_XHASH2_FLD], 'x0', this[PICTURE_EXT_FLD]) : undefined;
  }
  get defaultSortKey() {
    return this.name.toLowerCase(); // кастомний порядок сортування звичайних колекцій (в фідах)
  }
  get courseSortKey() {
    return this.id; // кастомний порядок сортування курс-колекцій (в фідах)
  }
  get csubscriptionSortKey() {
    return this[CSUBSCRIPTION_CREATED_AT_FLD]; // кастомний порядок сортування підписок (в фідах)
  }
}

// Attn: {{ MY-COLLECTION }} --> inner format ('i' --> 'id', ...)
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
function mergeCollection(state, patchObject) {
  const collId = patchObject[ID_FLD] || patchObject['id'];
  return state.updateIn(['collections', collId], prevCollection => {
    const {
      [ID_FLD]:id,
      [TYPE_FLD]:type,
      [OWNER_ID_FLD]:ownerId,
      [LANGUAGE_ID_FLD]:languageId,
      [NAME_FLD]:name,
      [DESCRIPTION_FLD]:description,
      [COMMENTS_FLD]:comments,
      [ATTACHMENTS_FLD]:strAttachments,
      [SUBJECTS_FLD]:strSubjects,
      [INTERESTS_FLD]:strInterests,
      [ACCESS_COST_FLD]:accessCost,
      [INITIAL_DROP_SIZE_FLD]:initialDropSize,
      [RECURRING_DROP_SIZE_FLD]:recurringDropSize,
      [NEXT_DROP_DELAY_FLD]:nextDropDelay,
      [STAPLES_QTY_FLD]:staplesQty,
      [FOLLOWERS_QTY_FLD]:followersQty,
      [CREATED_AT_FLD]:createdAt,
      [IS_PUBLIC_FLD]:isPublic,
      [IS_PUBLISHED_FLD]:isPublished,
      [COLOR_TAGS_FLD]:colorTags,
      [BLOB_FLD]:pictureBlobUrl, // blob-превьюшку передаємо через обʼєкт колекції !!!
      // ...csubscription:
      [CSUBSCRIPTION_ID_FLD]:csubId,                  // sic!: c)
      [CSUBSCRIPTION_STATUS_FLD]:csubStatus,          // sic!: c)
      [CSUBSCRIPTION_ACCESS_COST_FLD]:csubAccessCost, // sic!: c)
      [CSUBSCRIPTION_PAID_AMOUNT_FLD]:csubPaidAmount, // sic!: c)
      [CSUBSCRIPTION_CREATED_AT_FLD]:csubCreatedAt,   // sic!: c)
      [CSUBSCRIPTION_STARTED_AT_FLD]:csubStartedAt,   // sic!: c)
      ...innerFormatFields} = patchObject;
    const formattedObject = camelcaseKeys(innerFormatFields, {deep: true});
    // - - - присвоєння робиться тільки коли є значення !!!
    if (id !== undefined)                 {formattedObject.id = collId;}
    if (type !== undefined)               {formattedObject.type = type;}
    if (ownerId !== undefined)            {formattedObject.ownerId = ownerId;}
    if (languageId !== undefined)         {formattedObject[LANGUAGE_ID_FLD] = languageId;}
    if (name !== undefined)               {formattedObject.name = name;
                                           formattedObject.sline = slugify(name);}
    if (description !== undefined)        {formattedObject.description = description;}
    if (comments !== undefined)           {formattedObject.comments = comments;}
    if (accessCost !== undefined)         {formattedObject.accessCost = accessCost;}
    if (initialDropSize !== undefined)    {formattedObject[INITIAL_DROP_SIZE_FLD] = initialDropSize;}
    if (recurringDropSize !== undefined)  {formattedObject[RECURRING_DROP_SIZE_FLD] = recurringDropSize;}
    if (nextDropDelay !== undefined)      {formattedObject[NEXT_DROP_DELAY_FLD] = nextDropDelay;}
    if (staplesQty !== undefined)         {formattedObject[STAPLES_QTY_FLD] = staplesQty;}
    if (followersQty !== undefined)       {formattedObject[FOLLOWERS_QTY_FLD] = followersQty;}
    if (createdAt !== undefined)          {formattedObject[CREATED_AT_FLD] = createdAt;}
    if (isPublic !== undefined)           {formattedObject.isPublic = isPublic;}
    if (isPublished !== undefined)        {formattedObject.isPublished = isPublished;}
    if (colorTags !== undefined)          {formattedObject.colorTags = colorTags;}
    if (strSubjects !== undefined)        {formattedObject[SUBJECTS_FLD] = strSubjects;}
    if (strInterests !== undefined)       {formattedObject[INTERESTS_FLD] = strInterests;}
    if (strAttachments !== undefined)     {
      const picture = extractPictures(JSON.parse(strAttachments))[0];
      if (picture) {
        formattedObject[PICTURE_XHASH2_FLD] = picture.xhash2;
        formattedObject[PICTURE_EXT_FLD] = picture.ext;
      }
    }
    if (pictureBlobUrl !== undefined)     {formattedObject[BLOB_FLD] = pictureBlobUrl;}
    // ...csubscription:
    if (csubId !== undefined)             {formattedObject[CSUBSCRIPTION_ID_FLD] = csubId;}                   // sic!: c)
    if (csubStatus !== undefined)         {formattedObject[CSUBSCRIPTION_STATUS_FLD] = csubStatus;}           // sic!: c)
    if (csubAccessCost !== undefined)     {formattedObject[CSUBSCRIPTION_ACCESS_COST_FLD] = csubAccessCost;}  // sic!: c)
    if (csubPaidAmount !== undefined)     {formattedObject[CSUBSCRIPTION_PAID_AMOUNT_FLD] = csubPaidAmount;}  // sic!: c)
    if (csubCreatedAt !== undefined)      {formattedObject[CSUBSCRIPTION_CREATED_AT_FLD] = csubCreatedAt;}    // sic!: c)
    if (csubStartedAt !== undefined)      {formattedObject[CSUBSCRIPTION_STARTED_AT_FLD] = csubStartedAt;}    // sic!: c)
    // - - -
    return (prevCollection || new MyCollection()).merge(formattedObject);
  });
}

// 	Extract pictures from {{ ATTACHMENTS }} format.
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
//  {                                     {{ ATTACHMENTS }}:
//    PICTURES_FLD: {
//      "1Qj0oh31ft0...SB": {             // xhash2
//        xt: "jpeg",                     // extension = розширення файлу зображення
//      },
//      ...
//    }
//  }
//
//  [                                     {{ PICTURE_LIST }}:
//    {
//      xhash2: "1Qj0oh31ft0...SB"        // xhash2
//      ext: "jpeg"                       // extension = розширення файлу зображення
//    },
//    ...
//  ]
//
export function extractPictures(attachments) {
  const {[PICTURES_FLD]:attachedPicts} = attachments;
  let pictures = [];
  if (attachedPicts && Object.keys(attachedPicts).length > 0) {
    for (let x in attachedPicts) {
      const {[PICTURE_EXT_FLD]:pictureExt} = attachedPicts[x];
      if (pictureExt) {
        pictures = pictures.concat([{
          xhash2: x,
          ext: pictureExt,
        }]);
      }
    }
  }
  return pictures;
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
function mergeCollectionSomeFields(state, patchObject) {
  const collId = patchObject[ID_FLD] || patchObject['Id'];
  if (!collId) return state;
  const prevCollection = state.get('collections').find(coll => coll.id === collId);
  if (collId) {
    const {[COLOR_TAGS_FLD]:colorTags} = patchObject;
    const formattedObject = {};
    // - - - присвоєння робиться тільки коли є значення !!!
    if (colorTags !== undefined)          {formattedObject.colorTags = colorTags;}
    // - - -
    return state.setIn(['collections', collId], prevCollection.merge(formattedObject));
  } else {
    return state;
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
const CollectionFeed = Record({
  collectionIds: Set(),                   // ідентифікатори колекцій, що входять в фід
  collectionCursor: '',                   // наступний курсор для даного фіду (sic!: '', бо ініціалізуємо в Actions/API)
  areCollectionsLoaded: false,            // чи завантажені усі колекції цього фіду в стор?
});

function mergeCollectionFeed(state, key, patchObject) {
  return state.updateIn(['collectionFeeds', key], prevFeed => {
    return (prevFeed || new CollectionFeed()).merge(patchObject);
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
const CollectionPendingFields = Record({
  id: '',                                 // ідентифікатор колекції
  colorTags: null,                        // новий вміст поля 'colorTags' (якщо null, то не оновлюємо)
  isEdited: false,                        // чи були зміни за період між запитами до серверу (якщо true, то запис буде надіслано повторно)
});

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
function mergeCollectionPendingFields(state, patchObject) {
  const cpfId = patchObject[ID_FLD] || patchObject['id'];
  return state.updateIn(['pndCollectionFields', cpfId], prevCollectionFields => {
    const {
      [ID_FLD]:id2,
      [COLOR_TAGS_FLD]:colorTags,
      [IS_EDITED_FLD]:isEdited,
      ...innerFormatFields} = patchObject;
    const formattedObject = camelcaseKeys(innerFormatFields, {deep: true});
    // - - - присвоєння робиться тільки коли є значення !!!
    if (cpfId !== undefined)              {formattedObject.id = cpfId;}
    if (colorTags !== undefined)          {formattedObject.colorTags = colorTags;}
    if (isEdited !== undefined)           {formattedObject.isEdited = isEdited;}
    // - - -
    return (prevCollectionFields || new CollectionPendingFields()).merge(formattedObject);
  });
}

// Attn: 1) значення myId потрібно відразу (тому з LS, бо з серверу приходить з затримкою) !!!
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
class CollectionStore extends ReduceStore {
  getInitialState() {
    trace(`getInitialState`);
    const {myAccount} = JSON.parse(localStorage.getItem(LS_SETTINGS)) || {}; // sic!: 1)
    const {id:myId = ''} = myAccount || {};
    return Map({
      myId: myId,                         // ідентифікатор користувача
      collections: Map(),                 // завантажені в стор колекції = sic!: b) !!!
      collectionFeeds: Map(),             // дані для фідів колекцій (в т.ч. для всіх юзерів)
      pndCollectionFields: Map(),         // відтерміновані зміни колекцій стосовно зет-полів
    });
  }

  // - - - predicates:

  areMyCollectionsLoaded(collectionType = DEFAULT_COLLECTION) { // attn: a) !!!
    const myId = this.getState().get('myId');
    const result = !!this.getState().getIn(['collectionFeeds', USER_COLLECTIONS_PREFIX + myId + collectionType, 'areCollectionsLoaded']);
    trace(`areMyCollectionsLoaded: loaded=${result}`);
    return result;
  }

  areUserCollectionsByTypeLoaded(userId, collectionType) {
    const result = !!this.getState().getIn(['collectionFeeds', USER_COLLECTIONS_PREFIX + userId + collectionType, 'areCollectionsLoaded']);
    trace(`areUserCollectionsByTypeLoaded: loaded=${result}`);
    return result;
  }

  isCollectionLoaded(id) {
    const result = !!this.getState().getIn(['collections', id, 'ownerId']); // sic!: d) = признак завантаженості !!!
    trace(`isCollectionLoaded: collectionId=${id}, loaded=${result}`);
    return result;
  }

  isCSubscriptionLoaded(id) {
    const result = !!this.getState().getIn(['collections', id, 'ownerId']); // sic!: d) = признак завантаженості !!!
    trace(`isCSubscriptionLoaded: collectionId=${id}, loaded=${result}`);
    return result;
  }

  // - - - getters:

  getMyId() {
    trace(`getMyId`);
    return this.getState().get('myId');
  }

  getCollection(id) {
    return this.getState().getIn(['collections', id]);
  }

  getCollections() {
    trace(`getCollections`);
    return this.getState().get('collections');
  }

  getCSubscription(collectionId) {
    return this.getState().getIn(['collections', collectionId]);
  }

  getMyCollections() {
    trace(`getMyCollections`);
    const myId = this.getState().get('myId');
    return this.getState().get('collections')
      .filter(coll => coll.ownerId === myId)
      .toList()
      .sort((a, b) => { return a.defaultSortKey < b.defaultSortKey ? -1 : 1; });
  }

  getUserCollectionsByType(userId, collectionType, areEmptyVisible) { // ToDo: повертати {collections, collectionCursor, areCollectionsLoaded} !!!
    if (userId) {
      trace(`getUserCollectionsByType`);
      const minStaplesQty = areEmptyVisible ? 0 : 1; // 0 = all, >1 = non empty collections
      return this.getState().get('collections')
        .filter(coll => coll.ownerId === userId && coll.type === collectionType && coll[STAPLES_QTY_FLD] >= minStaplesQty)
        .toList()
        .sort((a, b) => {
          return a.type === COURSE_COLLECTION ?
            a.courseSortKey > b.courseSortKey ? -1 : 1 :
            a.defaultSortKey < b.defaultSortKey ? -1 : 1 ;
        });
    }
    return Map(); // empty value
  }

  getDiscoverCollectionsFeedByType(collectionType) {
    trace(`getDiscoverCollectionsFeedByType`);
    const {
      collectionIds,
      collectionCursor = DEFAULT_TSN12_CURSOR,
      areCollectionsLoaded} = this.getState().getIn(['collectionFeeds', DISCOVER_COLLECTIONS_PREFIX + collectionType]) || {};
    const collections = this.getSomeCollections(collectionIds)
      .sort((a, b) => { return a.defaultSortKey > b.defaultSortKey ? -1 : 1; });
    return {collections, collectionCursor, areCollectionsLoaded}
  }

  getSubjectCollectionsFeedByType(collectionType, subjectId) {
    trace(`getSubjectCollectionsFeedByType`);
    const {
      collectionIds,
      collectionCursor = DEFAULT_TSN12_CURSOR,
      areCollectionsLoaded} = this.getState().getIn(['collectionFeeds', SUBJECT_COLLECTIONS_PREFIX + collectionType + subjectId]) || {};
    const collections = this.getSomeCollections(collectionIds)
      .sort((a, b) => { return a.defaultSortKey > b.defaultSortKey ? -1 : 1; });
    return {collections, collectionCursor, areCollectionsLoaded}
  }

  getMyCSubscriptionsFeed() {
    trace(`getMyCSubscriptionsFeed`);
    const {
      collectionIds,
      collectionCursor = DEFAULT_TSN12_CURSOR,
      areCollectionsLoaded} = this.getState().getIn(['collectionFeeds', MY_CSUBSCRIPTIONS_PREFIX]) || {};
    const collections = this.getSomeCollections(collectionIds)
      .sort((a, b) => { return a.csubscriptionSortKey > b.csubscriptionSortKey ? -1 : 1; });
    return {collections, collectionCursor, areCollectionsLoaded}
  }

  getSomeCollections(ids = []) {
    trace(`getSomeCollections`);
    return ids.reduce((acc, id) => {
      const collection = this.getCollection(id);
      if (!collection) {
        traceError(`getSomeCollections: collectionId=${id}`);
        return acc;
      }
      return acc.add(collection);
    }, Set());
  }

  getSomeCollectionsMap(ids = []) {
    trace(`getSomeCollectionsMap`);
    return ids.reduce((acc, id) => {
      const collection = this.getCollection(id);
      if (!collection) {
        traceError(`getSomeCollectionsMap: collectionId=${id}`);
        return acc;
      }
      return acc.set(id, collection); // 'set' зберігає Record
    }, Map());
  }

  getUserCollectionCursorByType(userId, collectionType) {
    trace(`getUserCollectionCursorByType`);
    return this.getState().getIn(['collectionFeeds', USER_COLLECTIONS_PREFIX + userId + collectionType, 'collectionCursor']) || DEFAULT_TSN12_CURSOR;
  }

  getCollectionPendingFields() {
    trace(`getCollectionPendingFields`);
    return this.getState().get('pndCollectionFields').filter(cpf => cpf.isEdited === true); // sic!: лише змінені поля надсилаються на BE !!!
  }

  // - - - reducers:

  // оновлення лічильників колекцій, якщо юзер НЕ додавав нові колекції (бо тоді викликаємо fetchMyCollections(true))
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [STAPLE_WAS_CREATED_ACTION] = (state, {payload}) => {
    const {tgxCollections = {}} = payload;
    const collectionIds = Object.keys(tgxCollections);
    return !collectionIds.find(elem => elem.startsWith('+')) ?
      collectionIds.reduce((accState, currId) => {
          const prevStaplesQty = accState.getIn(['collections', currId, STAPLES_QTY_FLD]);
          return mergeCollection(accState, {[ID_FLD]: currId, [STAPLES_QTY_FLD]: prevStaplesQty + 1 }); // counts: STAPLES_QTY_FLD
        }, state) :
      state;
  }

  // оновлення лічильників колекцій, якщо юзер НЕ додавав нові колекції (бо тоді викликаємо fetchMyCollections(true))
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [STAPLE_WAS_CLONED_ACTION] = (state, {payload}) => {
    const {tgxCollections = {}} = payload;
    const collectionIds = Object.keys(tgxCollections);
    return !collectionIds.find(elem => elem.startsWith('+')) ?
      collectionIds.reduce((accState, currId) => {
          const prevStaplesQty = accState.getIn(['collections', currId, STAPLES_QTY_FLD]);
          return mergeCollection(accState, {[ID_FLD]: currId, [STAPLES_QTY_FLD]: prevStaplesQty + 1 }); // counts: STAPLES_QTY_FLD
        }, state) :
      state;
  }

  // оновлення лічильників колекцій, якщо юзер НЕ додавав нові колекції (бо тоді викликаємо fetchMyCollections(true))
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [STAPLE_WAS_UPDATED_ACTION] = (state, {payload}) => {
    const {addedCollectionIds, removedCollectionIds} = payload;
    const nextState = addedCollectionIds ?
      addedCollectionIds.reduce((accState, currId) => {
        const prevStaplesQty = accState.getIn(['collections', currId, STAPLES_QTY_FLD]);
        return mergeCollection(accState, {[ID_FLD]: currId, [STAPLES_QTY_FLD]: prevStaplesQty + 1 }); // counts: STAPLES_QTY_FLD
      }, state) :
      state;
    return removedCollectionIds ?
      removedCollectionIds.reduce((accState, currId) => {
        const prevStaplesQty = accState.getIn(['collections', currId, STAPLES_QTY_FLD]);
        return mergeCollection(accState, {[ID_FLD]: currId, [STAPLES_QTY_FLD]: prevStaplesQty - 1 }); // counts: STAPLES_QTY_FLD
      }, nextState) :
      nextState;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [STAPLES_WERE_DELETED_ACTION] = (state, {payload}) => {
    const {affectedCollectionIds} = payload;
    return affectedCollectionIds ?
      affectedCollectionIds.reduce((accState, currId) => {
        const prevStaplesQty = accState.getIn(['collections', currId, STAPLES_QTY_FLD]);
        return mergeCollection(accState, {[ID_FLD]: currId, [STAPLES_QTY_FLD]: prevStaplesQty - 1 }); // counts: STAPLES_QTY_FLD
      }, state) :
      state;
  }

  // ToDo: COLLECTION_CTX !!!
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // [COLLECTION_CCTX_CHATS_WERE_FETCHED_ACTION] = (state, {payload}) => {
  //   const {collections} = payload;
  //   return collections ?
  //     collections.reduce(mergeCollection, state) :
  //     state;
  // }

  // FixMe: перевіряти чи не видалено на сервері якусь із колекцій --> видаляти із стору (???)
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [MY_COLLECTIONS_WERE_FETCHED_ACTION] = (state, {payload}) => {
    const {myId, collections, collectionCursor, areCollectionsLoaded} = payload;
    if (!collections) {
      return state;
    }
    const nextState = collections.reduce(mergeCollection, state);
    const nextState1 = mergeCollectionFeed(nextState, USER_COLLECTIONS_PREFIX + myId + DEFAULT_COLLECTION, {collectionCursor, areCollectionsLoaded}); // attn: a) !!!
    return mergeCollectionFeed(nextState1, USER_COLLECTIONS_PREFIX + myId + COURSE_COLLECTION, {collectionCursor, areCollectionsLoaded}); // attn: a) !!!
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [DISCOVER_COLLECTIONS_WERE_FETCHED_ACTION] = (state, {payload}) => {
    const {type:collectionType, collections, collectionCursor, areCollectionsLoaded} = payload;
    if (!collections) {
      return state;
    }
    const nextState = collections.reduce(mergeCollection, state);
    const {collectionIds:prevCollectionIds} = nextState.getIn(['collectionFeeds', DISCOVER_COLLECTIONS_PREFIX + collectionType]) || new CollectionFeed();
    const collectionIds = collections.reduce((acc, coll) => acc.add(coll[ID_FLD]), prevCollectionIds);
    return mergeCollectionFeed(nextState, DISCOVER_COLLECTIONS_PREFIX + collectionType, {collectionCursor, areCollectionsLoaded, collectionIds});
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [SUBJECT_COLLECTIONS_WERE_FETCHED_ACTION] = (state, {payload}) => {
    const {subjectId, type:collectionType, collections, collectionCursor, areCollectionsLoaded} = payload;
    if (!collections) {
      return state;
    }
    const nextState = collections.reduce(mergeCollection, state);
    const {collectionIds:prevCollectionIds} = nextState.getIn(['collectionFeeds', SUBJECT_COLLECTIONS_PREFIX + collectionType + subjectId]) || new CollectionFeed();
    const collectionIds = collections.reduce((acc, coll) => acc.add(coll[ID_FLD]), prevCollectionIds);
    return mergeCollectionFeed(nextState, SUBJECT_COLLECTIONS_PREFIX + collectionType + subjectId, {collectionCursor, areCollectionsLoaded, collectionIds});
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [USER_COLLECTIONS_WERE_FETCHED_AS_GUEST_ACTION] = (state, {payload}) => {
    const {userId, type:collectionType, collections, collectionCursor, areCollectionsLoaded} = payload;
    if (!collections) {
      return state;
    }
    const nextState = collections.reduce(mergeCollection, state);
    return mergeCollectionFeed(nextState, USER_COLLECTIONS_PREFIX + userId + collectionType, {collectionCursor, areCollectionsLoaded});
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [USER_COLLECTIONS_WERE_FETCHED_ACTION] = (state, {payload}) => {
    const {userId, type:collectionType, collections, collectionCursor, areCollectionsLoaded} = payload;
    if (!collections) {
      return state;
    }
    const nextState = collections.reduce(mergeCollection, state);
    return mergeCollectionFeed(nextState, USER_COLLECTIONS_PREFIX + userId + collectionType, {collectionCursor, areCollectionsLoaded});
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [MY_CSUBSCRIPTIONS_WERE_FETCHED_ACTION] = (state, {payload}) => {
    const {
      csubscriptions:collections,
      csubscriptionCursor:collectionCursor,
      areCSubscriptionsLoaded:areCollectionsLoaded} = payload; // attn: c) !!!
    if (!collections) {
      return state;
    }
    const nextState = collections.reduce(mergeCollection, state);
    const {collectionIds:prevCollectionIds} = nextState.getIn(['collectionFeeds', MY_CSUBSCRIPTIONS_PREFIX]) || new CollectionFeed();
    const collectionIds = collections.reduce((acc, coll) => acc.add(coll[ID_FLD]), prevCollectionIds);
    return mergeCollectionFeed(nextState, MY_CSUBSCRIPTIONS_PREFIX, {collectionCursor, areCollectionsLoaded, collectionIds});
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [COLLECTION_WAS_FETCHED_ACTION] = (state, {payload}) => {
    const {collection} = payload;
    return collection ?
      mergeCollection(state, collection) :
      state;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [COLLECTION_WAS_FETCHED_AS_GUEST_ACTION] = (state, {payload}) => {
    const {collection} = payload;
    return collection ?
      mergeCollection(state, collection) :
      state;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [COLLECTION_WAS_CREATED_ACTION] = (state, {payload}) => {
    const {collection} = payload;
    return collection ?
      mergeCollection(state, collection) :
      state;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [COLLECTION_WAS_UPDATED_ACTION] = (state, {payload}) => {
    const {collection} = payload;
    return collection ?
      mergeCollection(state, collection) :
      state;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [COLLECTION_WAS_BANNED_ACTION] = (state, {payload}) => {
    const {collectionId, type:collectionType} = payload;
    if (!collectionId) {
      return state;
    }
    return state.deleteIn(['collections', collectionId]);
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [COLLECTION_WAS_DELETED_ACTION] = (state, {payload}) => {
    const {collectionId} = payload;
    return collectionId ?
      state.deleteIn(['collections', collectionId]) :
      state;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [COLLECTION_WAS_FOLLOWED_ACTION] = (state, {payload}) => {
    const {collectionId} = payload;
    return collectionId ?
      mergeCollection(state, {id: collectionId, isFollowed: true}) :
      state;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [COLLECTION_WAS_UNFOLLOWED_ACTION] = (state, {payload}) => {
    const {collectionId} = payload;
    return collectionId ?
      mergeCollection(state, {id: collectionId, isFollowed: false}) :
      state;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [FOLLOWED_COLLECTION_IDS_WERE_FETCHED_ACTION] = (state, {payload}) => {
    const {followedCollectionIds} = payload;
    if (!followedCollectionIds) {
      return state;
    }
    return followedCollectionIds.reduce((accState, collectionId) => {
      return mergeCollection(accState, {
        id: collectionId, type: DEFAULT_COLLECTION, isFollowed: true, isSubscribed: false // sic!: set isFollowed true
      });
    }, state);
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [CSUBSCRIBED_COLLECTION_IDS_WERE_FETCHED_ACTION] = (state, {payload}) => {
    const {csubscribedCollectionIds} = payload;
    if (!csubscribedCollectionIds) {
      return state;
    }
    return csubscribedCollectionIds.reduce((accState, collectionId) => {
      return mergeCollection(accState, {
        id: collectionId, type: COURSE_COLLECTION, isFollowed: false, isSubscribed: true // sic!: set isSubscribed true
      });
    }, state);
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [CSUBSCRIPTION_WAS_FETCHED_ACTION] = (state, {payload}) => {
    const {csubscription:collection} = payload; // attn: c) !!!
    return collection ?
      mergeCollection(state, collection) :
      state;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [CSUBSCRIPTION_WAS_CREATED_ACTION] = (state, {payload}) => {
    const {csubscription:collection} = payload;
    const {[ID_FLD]:collectionId} = collection;
    const nextState = collectionId ? mergeCollection(state, collection) : state;
    const nextState1 = collectionId ? mergeCollection(nextState, {id: collectionId, isSubscribed: true}) : nextState;
    const {collectionIds:prevCSubscriptionIds} = nextState1.getIn(['collectionFeeds', MY_CSUBSCRIPTIONS_PREFIX]) || new CollectionFeed();
    const csubscriptionIds = prevCSubscriptionIds.add(collectionId);
    return mergeCollectionFeed(nextState1, MY_CSUBSCRIPTIONS_PREFIX, {collectionIds:csubscriptionIds});
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [CSUBSCRIPTION_WAS_CANCELED_ACTION] = (state, {payload}) => {
    const {collectionId} = payload;
    return collectionId ?
      mergeCollection(state, {id: collectionId, isSubscribed: false}) :
      state;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [COLLECTION_PENDING_FIELDS_WERE_CHANGED_ACTION] = (state, {payload}) => {
    return mergeCollectionPendingFields(mergeCollectionSomeFields(state, payload), payload);
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [COLLECTION_PENDING_FIELDS_WERE_SENT_ACTION] = (state, {payload}) => {
    const {collectionPendingFields:cpf} = payload;
    return cpf && cpf.size > 0?
      cpf.reduce((accState, curr) => {
        const {id:prevId, colorTags:prevColorTags} = curr;
        return mergeCollectionPendingFields(accState, {
          [ID_FLD]: prevId,
          [COLOR_TAGS_FLD]: prevColorTags,
          [IS_EDITED_FLD]: false // sic!: false = запобігає повторному висиланню на бекенд !!!
        });
      }, state) :
      state;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [COLLECTION_PENDING_FIELDS_WERE_PROCESSED_ACTION] = (state, {payload}) => {
    const {processedCollectionIds} = payload;
    return processedCollectionIds.reduce((accState, collectionId) => {
      return accState.getIn(['pndCollectionFields', collectionId, 'isEdited']) === false ?
        accState.set('pndCollectionFields', accState.get('pndCollectionFields').delete(collectionId)) :
        accState;
    }, state);
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [MY_ACCOUNT_WAS_FETCHED_ACTION] = (state, {payload}) => {
    const {account} = payload;
    return account ?
      state.mergeIn(['myId'], account[ID_FLD]) :
      state;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [LOGGED_OUT_ACTION] = (state, {payload}) => {
    return this.getInitialState();
  }

  reduce(state, action) {
    const reducer = this[action.type];
    if (reducer) {
      const nextState = reducer(state, action);
      trace(`reduce:`, action, nextState.toJS());
      return nextState;
    }
    return state;
  }
}

export default new CollectionStore(Dispatcher);
