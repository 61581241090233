// -------------------------------------------------------------------------------------------------
//  CrmStore.js
//  - - - - - - - - - -
//  Contacts, groups & CRM-related.
//
//  Attn:
//  - - - - -
//  - поля id, value, label потрібні для Select2 (геттери: value, label);
//  - при розміщенні обʼєктів в стор перетворюємо назви полів: snake_case --> camelCase;
//  - список груп завантажуюємо з серверу відразу;
//  - якщо юзер при створенні нового контакту додав нові групи (т.з. plus-groups),
//    то перезавантажуємо весь список з сервера заново (замість отримувати інфу лише по цим групам);
//  - список контактів (принаймні поки що) завантажуюємо з серверу відразу;
//  - фільтрацію контактів, що відносяться до конкретної групи виконуємо при запиті;
// -------------------------------------------------------------------------------------------------
import {ReduceStore} from 'flux/utils';
import {Map, Set, Record} from 'immutable';
import camelcaseKeys from 'camelcase-keys';
import Dispatcher from 'dispatcher/Dispatcher';
import {
  MY_ACCOUNT_WAS_FETCHED_ACTION,
  MY_FRIENDS_WERE_FETCHED_ACTION,
  FRIENDSHIP_REQUEST_WAS_SENT_ACTION,
  FRIENDSHIP_REQUEST_WAS_SENT_BY_EMAIL_ACTION,
  FRIENDSHIP_REQUEST_WAS_SENT_TO_CONTACTS_ACTION,
  FRIENDSHIP_REQUEST_WAS_ACCEPTED_BY_USER_ACTION,
  FRIENDSHIP_REQUEST_WAS_ACCEPTED_BY_ME_ACTION,
  FRIENDSHIP_REQUEST_WAS_REJECTED_ACTION,
  FRIENDSHIP_REQUEST_WAS_RECEIVED_ACTION,
  FRIENDSHIP_WAS_CANCELED_ACTION,
  USERS_WERE_FETCHED_ACTION,
  MY_CONTACTS_WERE_FETCHED_ACTION,
  CONTACT_WAS_CREATED_ACTION,
  CONTACT_WAS_UPDATED_ACTION,
  CONTACT_WAS_DELETED_ACTION,
  CONTACT_WAS_SELECTED_ACTION,
  CONTACT_WAS_UNSELECTED_ACTION,
  CONTACTS_WERE_UNSELECTED_ACTION,
  CONTACTS_OF_GROUP_WERE_SELECTED_ACTION,
  CONTACTS_OF_GROUP_WERE_UNSELECTED_ACTION,
  CONTACT_UPDATED_NTF_WAS_RECEIVED_ACTION,
  SCTX_CHATS_WERE_CREATED_ACTION,
  MY_GROUPS_WERE_FETCHED_ACTION,
  GROUP_WAS_FETCHED_ACTION,
  GROUP_WAS_CREATED_ACTION,
  GROUP_WAS_UPDATED_ACTION,
  GROUP_WAS_DELETED_ACTION,
  CONTACT_PENDING_FIELDS_WERE_CHANGED_ACTION,
  CONTACT_PENDING_FIELDS_WERE_SENT_ACTION,
  CONTACT_PENDING_FIELDS_WERE_PROCESSED_ACTION,
  GROUP_PENDING_FIELDS_WERE_CHANGED_ACTION,
  GROUP_PENDING_FIELDS_WERE_SENT_ACTION,
  GROUP_PENDING_FIELDS_WERE_PROCESSED_ACTION,
  LOGGED_OUT_ACTION} from 'core/actionTypes';
import {
  ID_FLD,
  SLUG_FLD,
  BLOB_FLD,
  ALIAS_FLD,
  NAME_FLD,
  FIRST_NAME_FLD,
  MIDDLE_NAME_FLD,
  LAST_NAME_FLD,
  LABEL_FLD,
  DESCRIPTION_FLD,
  COMMENTS_FLD,
  ADDRESSES_FLD,
  POSITION_FLD,
  COLOR_TAGS_FLD,
  COLOR_LABELS_FLD,
  DETAILS_FLD,
  PHONES_FLD,
  EMAILS_FLD,
  URLS_FLD,
  CONTACT_FLD,
  CONTACTS_FLD,
  GROUPS_FLD,
  CONTACTS_QTY_FLD,
  AVATAR_SOURCE_URL_FLD,
  AVATAR_XHASH2_FLD,
  AVATAR_EXT_FLD,
  USER_ID_FLD,
  USER_SLUG_FLD,
  USER_ALIAS_FLD,
  USER_AVATAR_SOURCE_URL_FLD,
  USER_XHASH2_FLD,
  USER_EXT_FLD,
  FRIENDSHIP_STATUS_FLD,
  IS_EDITED_FLD,
  IS_FRIEND_FLD} from 'core/apiFields';
import {LS_SETTINGS} from 'core/commonTypes';
import {
  FS_NONE_STATUS,
  FS_FRIEND_STATUS,
  FS_REQUEST_SENT_STATUS,
  FS_REQUEST_RECEIVED_STATUS} from 'core/friendshipTypes';
import {toReact} from 'components/RichEditor/rich-conmark-processors';
import {toDxListStr} from 'components/UI/fields/DetailSelectField';
import {toPnListStr} from 'components/UI/fields/PhoneSelectField';
import {toUrListStr} from 'components/UI/fields/UrlSelectField';
import {toAdListStr, toEmListStr, toGrListStr} from 'components/UI/fields/SelectField';
import {composeAvatarUrl} from 'utils/settingsTools';
import {slugify} from 'utils/converters';

const isVerbose = DEBUG && true;
const prefix = '- - - CrmStore';

function trace(msg, ...other) { if (isVerbose) { console.log(`${prefix}.${msg}`, ...other); }}
function traceWarn(msg, ...other) { if (isVerbose) { console.warn(`${prefix}.${msg}`, ...other); }}
function traceError(msg, ...other) { console.error(`${prefix}.${msg}`, ...other); }

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
const ContactPendingFields = Record({
  id: '',                                 // ідентифікатор контакту
  position: null,                         // новий вміст поля 'position' (якщо null, то не оновлюємо)
  colorTags: null,                        // новий вміст поля 'colorTags' (якщо null, то не оновлюємо)
  isEdited: false,                        // чи були зміни за період між запитами до серверу (якщо true, то запис буде надіслано повторно)
});

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
function mergeContactPendingFields(state, patchObject) {
  const ucfId = patchObject[ID_FLD] || patchObject['id'];
  return state.updateIn(['pndContactFields', ucfId], prevContactFields => {
    const {
      [ID_FLD]:id2,
      [POSITION_FLD]:position,
      [COLOR_TAGS_FLD]:colorTags,
      [IS_EDITED_FLD]:isEdited,
      ...innerFormatFields} = patchObject;
    const formattedObject = camelcaseKeys(innerFormatFields, {deep: true});
    // - - - присвоєння робиться тільки коли є значення !!!
    if (ucfId !== undefined)              {formattedObject.id = ucfId;}
    if (position !== undefined)           {formattedObject.position = position;}
    if (colorTags !== undefined)          {formattedObject.colorTags = colorTags;}
    if (isEdited !== undefined)           {formattedObject.isEdited = isEdited;}
    // - - -
    return (prevContactFields || new ContactPendingFields()).merge(formattedObject);
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
const GroupPendingFields = Record({
  id: '',                                 // ідентифікатор контакту
  colorTags: null,                        // новий вміст поля 'colorTags' (якщо null, то не оновлюємо)
  isEdited: false,                        // чи були зміни за період між запитами до серверу (якщо true, то запис буде надіслано повторно)
});

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
function mergeGroupPendingFields(state, patchObject) {
  const ugfId = patchObject[ID_FLD] || patchObject['id'];
  return state.updateIn(['pndGroupFields', ugfId], prevGroupFields => {
    const {
      [ID_FLD]:id2,
      [COLOR_TAGS_FLD]:colorTags,
      [IS_EDITED_FLD]:isEdited,
      ...innerFormatFields} = patchObject;
    const formattedObject = camelcaseKeys(innerFormatFields, {deep: true});
    // - - - присвоєння робиться тільки коли є значення !!!
    if (ugfId !== undefined)              {formattedObject.id = ugfId;}
    if (colorTags !== undefined)          {formattedObject.colorTags = colorTags;}
    if (isEdited !== undefined)           {formattedObject.isEdited = isEdited;}
    // - - -
    return (prevGroupFields || new GroupPendingFields()).merge(formattedObject);
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
const ContactRecord = Record({
  id: null,                               // sic!: required by SelectField !!!
  sline: '',                              // sic!: required by SelectField !!!
  name: '',                               // назва контакту (будь-яка, що характеризує контакт) // ToDo: OBSOLETE ???
  firstName: '',                          // імʼя
  middleName: '',                         // по-батькові
  lastName: '',                           // прізвище
  comments: '',                           // непублічні коментарі про цей контакт (Conmark)
  strAddresses: '[]',                     // список адрес       = {{ AD-LIST-STR }}
  strDetails: '[]',                       // список приміток    = {{ DX-LIST-STR }}
  strPhones: '[]',                        // список телефонів   = {{ PN-LIST-STR }}
  strEmails: '[]',                        // список мейлів      = {{ EM-LIST-STR }}
  strUrls: '[]',                          // список посилань    = {{ UR-LIST-STR }}
  strGroups: '[]',                        // список груп        = {{ GR-LIST-STR }}
  avatarSourceUrl: '',                    // url-адреса джерела аватарки
  avatarXhash2: '',                       // хеш-код превьюшки контакта
  avatarExt: '',                          // розширення файлу превьюшки контакту
  blob: '',                               // джерело превьюшки контакту
  isSelected: false,                      // флаг відмітки контакту для множинної операції
  position: 0,                            // позиція для сортування
  colorTags: 0,                           // біти кольорових тегів контакту (2byte - 1bit = 15bit)
  // ...user:
  userId: null,                           // код привʼязаного до контакту юзера
  userSlug: null,                         // слаг привʼязаного до контакту юзера
  userAlias: '',                          // аліас привʼязаного до контакту юзера
  userAvatarSourceUrl: '',                // url-адреса джерела аватарки привʼязаного юзера
  userXhash2: '',                         // хеш-код превьюшки юзера
  userExt: '',                            // розширення файлу превьюшки юзера
  friendshipStatus: FS_NONE_STATUS,       // sic!: до моменту отримання списку друзів !!! // ToDo: переробити на undefined + ініціалізація (як в UserStore) !!!
  isFriend: false,                        // флаг статусу дружби (якщо заповнене поле userId) // ToDo: OBSOLETE ??? <-- дублює поле friendshipStatus !!!
});

class Contact extends ContactRecord {
  get value() {                           // required by SelectField !!!
    return this.sline;
  }
  get label() {                           // required by SelectField !!!
    return this.name;
  }
  get commentsHtml() {
    return toReact(this.comments);
  }
  get lstAddresses() {
    return JSON.parse(this.strAddresses); // '[]' --> []
  }
  get lstDetails() {
    return JSON.parse(this.strDetails);   // '[]' --> []
  }
  get lstPhones() {
    return JSON.parse(this.strPhones);    // '[]' --> []
  }
  get lstEmails() {
    return JSON.parse(this.strEmails);    // '[]' --> []
  }
  get lstUrls() {
    return JSON.parse(this.strUrls);      // '[]' --> []
  }
  get lstGroups() {
    return JSON.parse(this.strGroups);    // '[]' --> []
  }
  get defaultSortKey() {
    return this.lastName.toLowerCase() + this.firstName.toLowerCase(); // кастомний порядок сортування колекцій (в фідах)
  }
  get phones() {
    const pnx = JSON.parse(this.strPhones || '[]');
    return pnx.reduce((acc, pn) => {
      const lbl = (pn[LABEL_FLD] || '');
      // const lbl = (pn[LABEL_FLD] || '').replace(/[ ]/g, '-').replace(')-', ') ').replace('-(', '(');
      return acc + (acc && lbl ? ', ' : '') + lbl;
    }, '');
  }
  get title() {
    return this.userAlias && this.userAlias !== this.name ? `${this.name} (${this.userAlias})` : this.name;
  }
  get avatarSsUrl() {
    return this.blob ? this.blob : composeAvatarUrl(this.avatarXhash2, 's', this.avatarExt);
  }
  get avatarMdUrl() {
    return this.blob ? this.blob : composeAvatarUrl(this.avatarXhash2, 'm', this.avatarExt);
  }
  get avatarXlUrl() {
    return this.blob ? this.blob : composeAvatarUrl(this.avatarXhash2, 'x', this.avatarExt);
  }
  get contactAvatarSsUrl() {
    return this.blob ? this.blob : this.avatarXhash2 ? composeAvatarUrl(this.avatarXhash2, 's', this.avatarExt) : this.avatarSourceUrl;
  }
  get contactAvatarMdUrl() {
    return this.blob ? this.blob : this.avatarXhash2 ? composeAvatarUrl(this.avatarXhash2, 'm', this.avatarExt) : this.avatarSourceUrl;
  }
  get contactAvatarXlUrl() {
    return this.blob ? this.blob : this.avatarXhash2 ? composeAvatarUrl(this.avatarXhash2, 'x', this.avatarExt) : this.avatarSourceUrl;
  }
  get userAvatarSsUrl() {
    return this.userXhash2 ? composeAvatarUrl(this.userXhash2, 's', this.userExt) : this.userAvatarSourceUrl;
  }
  get userAvatarMdUrl() {
    return this.userXhash2 ? composeAvatarUrl(this.userXhash2, 'm', this.userExt) : this.userAvatarSourceUrl;
  }
  get userAvatarXlUrl() {
    return this.userXhash2 ? composeAvatarUrl(this.userXhash2, 'x', this.userExt) : this.userAvatarSourceUrl;
  }
}

// Attn: {{ CONTACT }} --> inner format ('i' --> 'id', ...)
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
function mergeContact(state, patchObject) {
  const contactId = patchObject[ID_FLD] || patchObject['id'];
  return state.updateIn(['myContacts', contactId], prevContact => {
    const {
      [ID_FLD]:id,
      [FIRST_NAME_FLD]:firstName,
      [MIDDLE_NAME_FLD]:middleName,
      [LAST_NAME_FLD]:lastName,
      [COMMENTS_FLD]:comments,
      [ADDRESSES_FLD]:tgxAddresses,
      [DETAILS_FLD]:tgxDetails,
      [PHONES_FLD]:tgxPhones,
      [EMAILS_FLD]:tgxEmails,
      [URLS_FLD]:tgxUrls,
      [GROUPS_FLD]:tgxGroups,
      [AVATAR_SOURCE_URL_FLD]:avatarSourceUrl = '',
      [AVATAR_XHASH2_FLD]:avatarXhash2,
      [AVATAR_EXT_FLD]:avatarExt,
      [BLOB_FLD]:blob = '',
      [POSITION_FLD]:position,
      [COLOR_TAGS_FLD]:colorTags,
      [USER_ID_FLD]:userId,
      [USER_SLUG_FLD]:userSlug,
      [USER_ALIAS_FLD]:userAlias,
      [USER_AVATAR_SOURCE_URL_FLD]:userAvSrcUrl,
      [USER_XHASH2_FLD]:userXhash2,
      [USER_EXT_FLD]:userExt,
      [FRIENDSHIP_STATUS_FLD]:friendshipStatus,
      [IS_FRIEND_FLD]:isFriend = false,
      ...innerFormatFields} = patchObject;
    const formattedObject = camelcaseKeys(innerFormatFields, {deep: true});
    const name = (firstName ? firstName + ' ' : '') + (middleName ? middleName + ' ' : '') + lastName;
    // - - - присвоєння робиться тільки коли є значення !!!
    if (id !== undefined)                 {formattedObject.id = contactId;}
    if (!!name)                           {formattedObject.name = name;
                                           formattedObject.sline = slugify(formattedObject.name);}
    if (firstName !== undefined)          {formattedObject.firstName = firstName;}
    if (middleName !== undefined)         {formattedObject.middleName = middleName;}
    if (lastName !== undefined)           {formattedObject.lastName = lastName;}
    if (comments !== undefined)           {formattedObject.comments = comments;}
    if (avatarXhash2 !== undefined)       {formattedObject.avatarXhash2 = avatarXhash2;}
    if (avatarExt !== undefined)          {formattedObject.avatarExt = avatarExt;}
    if (blob !== undefined)               {formattedObject.blob = blob;}
    if (position !== undefined)           {formattedObject.position = position;}
    if (colorTags !== undefined)          {formattedObject.colorTags = colorTags;}
    // ...
    formattedObject.avatarSourceUrl = avatarSourceUrl || ''; // sic!: non-null value !!!
    formattedObject.strAddresses = toAdListStr(JSON.parse(tgxAddresses));
    formattedObject.strDetails = toDxListStr(JSON.parse(tgxDetails));
    formattedObject.strPhones = toPnListStr(JSON.parse(tgxPhones));
    formattedObject.strEmails = toEmListStr(JSON.parse(tgxEmails));
    formattedObject.strUrls = toUrListStr(JSON.parse(tgxUrls));
    formattedObject.strGroups = toGrListStr(JSON.parse(tgxGroups));
    // ...user
    if (userId !== undefined)             {formattedObject.userId = userId;}
    if (userSlug !== undefined)           {formattedObject.userSlug = userSlug;}
    if (userAlias !== undefined)          {formattedObject.userAlias = userAlias;}
    if (userAvSrcUrl !== undefined)       {formattedObject.userAvatarSourceUrl = userAvSrcUrl;}
    if (userXhash2 !== undefined)         {formattedObject.userXhash2 = userXhash2;}
    if (userExt !== undefined)            {formattedObject.userExt = userExt;}
    if (friendshipStatus !== undefined)   {formattedObject.friendshipStatus = friendshipStatus;}
    if (isFriend !== undefined)           {formattedObject.isFriend = isFriend;}
    // - - -
    return (prevContact || new Contact()).merge(formattedObject);
  });
}

// Attn: обробляємо лише контакти в яких вказано userId !!!
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
function mergeContactUserInfo(state, patchObject) {
  // {{ USER }} --> inner format ('i' --> 'userId', ...)
  const userId = patchObject[ID_FLD] || patchObject['userId'];
  if (!userId) return state;
  const prevContact = state.get('myContacts').find(contact => contact.userId === userId);
  const {id:contactId} = prevContact || {};
  if (contactId) {
    const {
      [SLUG_FLD]:userSlug,
      [ALIAS_FLD]:userAlias,
      [AVATAR_SOURCE_URL_FLD]:userAvSrcUrl,
      [AVATAR_XHASH2_FLD]:userXhash2,
      [AVATAR_EXT_FLD]:userExt,
      [FRIENDSHIP_STATUS_FLD]:friendshipStatus,
      [IS_FRIEND_FLD]:isFriend} = patchObject;
    const formattedObject = {};
    // - - - присвоєння робиться тільки коли є значення !!!
    if (userId !== undefined)             {formattedObject.userId = userId;}
    if (userSlug !== undefined)           {formattedObject.userSlug = userSlug;}
    if (userAlias !== undefined)          {formattedObject.userAlias = userAlias;}
    if (userAvSrcUrl !== undefined)       {formattedObject.userAvatarSourceUrl = userAvSrcUrl;}
    if (userXhash2 !== undefined)         {formattedObject.userXhash2 = userXhash2;}
    if (userExt !== undefined)            {formattedObject.userExt = userExt;}
    if (friendshipStatus !== undefined)   {formattedObject.friendshipStatus = friendshipStatus;}
    if (isFriend !== undefined)           {formattedObject.isFriend = isFriend;}
    // - - -
    return state.setIn(['myContacts', contactId], prevContact.merge(formattedObject));
  } else {
    return state;
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
function mergeContactSomeFields(state, patchObject) {
  const contactId = patchObject[ID_FLD] || patchObject['Id'];
  if (!contactId) return state;
  const prevContact = state.get('myContacts').find(contact => contact.id === contactId);
  if (contactId) {
    const {
      [POSITION_FLD]:position,
      [COLOR_TAGS_FLD]:colorTags} = patchObject;
    const formattedObject = {};
    // - - - присвоєння робиться тільки коли є значення !!!
    if (position !== undefined)           {formattedObject.position = position;}
    if (colorTags !== undefined)          {formattedObject.colorTags = colorTags;}
    // - - -
    return state.setIn(['myContacts', contactId], prevContact.merge(formattedObject));
  } else {
    return state;
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
const GroupRecord = Record({
  id: null,                               // sic!: required by SelectField !!!
  sline: '',                              // sic!: required by SelectField !!!
  name: '',                               // назва групи
  comments: '',                           // приватні коментарі щодо групи від власника (Conmark)
  contactsQty: 0,                         // к-сть контактів в групі
  isPublic: false,                        // sic!: required by SelectField --> групи завжди приватні !!!
  colorTags: 0,                           // біти кольорових тегів групи (2byte - 1bit = 15bit)
});

class Group extends GroupRecord {
  get value() {                           // required by SelectField !!!
    return this.sline;
  }
  get label() {                           // required by SelectField !!!
    return this.name;
  }
  get commentsHtml() {
    return toReact(this.comments);
  }
  get defaultSortKey() {
    return this.name.toLowerCase();       // кастомний порядок сортування колекцій (в фідах)
  }
}

// Attn: {{ GROUP }} --> inner format ('i' --> 'id', ...)
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
function mergeGroup(state, patchObject) {
  const groupId = patchObject[ID_FLD] || patchObject['id'];
  return state.updateIn(['myGroups', groupId], prevGroup => {
    const {
      [ID_FLD]:id,
      [NAME_FLD]:name,
      [COMMENTS_FLD]:comments,
      [CONTACTS_QTY_FLD]:contactsQty,
      [COLOR_TAGS_FLD]:colorTags,
      ...innerFormatFields} = patchObject;
    const formattedObject = camelcaseKeys(innerFormatFields, {deep: true});
    // - - - присвоєння робиться тільки коли є значення !!!
    if (id !== undefined)                 {formattedObject.id = groupId;}
    if (name !== undefined)               {formattedObject.name = name;
                                           formattedObject.sline = slugify(name);}
    if (comments !== undefined)           {formattedObject.comments = comments;}
    if (contactsQty !== undefined)        {formattedObject.contactsQty = contactsQty;}
    if (colorTags !== undefined)          {formattedObject.colorTags = colorTags;}
    // - - -
    return (prevGroup || new Group()).merge(formattedObject);
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
function mergeGroupSomeFields(state, patchObject) {
  const groupId = patchObject[ID_FLD] || patchObject['Id'];
  if (!groupId) return state;
  const prevGroup = state.get('myGroups').find(grp => grp.id === groupId);
  if (groupId) {
    const {
      [COLOR_TAGS_FLD]:colorTags} = patchObject;
    const formattedObject = {};
    // - - - присвоєння робиться тільки коли є значення !!!
    if (colorTags !== undefined)          {formattedObject.colorTags = colorTags;}
    // - - -
    return state.setIn(['myGroups', groupId], prevGroup.merge(formattedObject));
  } else {
    return state;
  }
}

//  Attn: 1) значення myId потрібно відразу (тому з LS, бо з серверу приходить з затримкою) !!!
//  Attn: 2) потрібен окремий флаг з дефолтним значенням false, бо може бути порожній список !!!
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
class CrmStore extends ReduceStore {
  getInitialState() {
    trace(`getInitialState`);
    const {myAccount} = JSON.parse(localStorage.getItem(LS_SETTINGS)) || {}; // sic!: 1)
    const {id:myId = ''} = myAccount || {};
    return Map({
      myId: myId,                         // ідентифікатор користувача
      myGroups: Map(),                    // завантажені в стор групи користувача
      myContacts: Map(),                  // завантажені в стор контакти користувача
      selectedContactIds: Set(),          // множина ідентифікаторів відібраних користувачем контактів
      areMyContactsLoaded: false,         // sic!: 2); чи усі контакти користувача завантажені з серверу?
      areMyGroupsLoaded: false,           // sic!: 2); чи усі групи користувача завантажені з серверу?
      pndContactFields: Map(),            // відтерміновані зміни контактів стосовно зет-полів
      pndGroupFields: Map(),              // відтерміновані зміни груп стосовно зет-полів
    });
  }

  // - - - predicates:

  areMyContactsLoaded() {
    const result = this.getState().get('areMyContactsLoaded');
    trace(`areMyContactsLoaded: loaded=${result}`);
    return result;
  }

  isMyContactLoaded(id) {
    const result = !!this.getState().getIn(['myContacts', id]);
    trace(`isMyContactLoaded: contactId=${id}, loaded=${result}`);
    return result;
  }

  areMyGroupsLoaded() {
    const result = this.getState().get('areMyGroupsLoaded');
    trace(`areMyGroupsLoaded: loaded=${result}`);
    return result;
  }

  isMyGroupLoaded(id) {
    const result = !!this.getState().getIn(['myGroups', id]);
    trace(`isMyGroupLoaded: groupId=${id}, loaded=${result}`);
    return result;
  }

  hasSelectedContacts() {
    const result = this.getState().get('selectedContactIds').size > 0;
    trace(`hasSelectedContacts: ${result}`);
    return result;
  }

  // - - - getters:

  getMyId() {
    trace(`getMyId`);
    return this.getState().get('myId');
  }

  getMyGroup(id) {
    return this.getState().getIn(['myGroups', id]);
  }

  getMyGroups() {
    trace(`getMyGroups`);
    return this.getState().get('myGroups')
      .toList()
      .sort((a, b) => { return a.defaultSortKey < b.defaultSortKey ? -1 : 1; });
  }

  getMyContact(id) {
    return this.getState().getIn(['myContacts', id]);
  }

  getMyContacts() {
    trace(`getMyContacts`);
    return this.getState().get('myContacts')
      .toList()
      .sort((a, b) => { return a.defaultSortKey < b.defaultSortKey ? -1 : 1; });
  }

  getMyGroupContacts(groupId) {
    trace(`getMyGroupContacts`);
    return this.getState().get('myContacts')
      .filter(contact => {
        const groups = contact.lstGroups;
        return !!groups.find(gr => gr[ID_FLD] === groupId);
      })
      .toList()
      .sort((a, b) => { return a.defaultSortKey < b.defaultSortKey ? -1 : 1; });
  }

  getSelectedContacts() {
    trace(`getSelectedContacts`);
    return this.getState().get('selectedContactIds').map(id => this.getMyContact(id))
      .toList()
      .sort((a, b) => { return a.defaultSortKey < b.defaultSortKey ? -1 : 1; });
  }

  getQts() {
    trace(`getQts`);
    const groupsQty = this.getState().get('myGroups').size;
    const contactsQty = this.getState().get('myContacts').size;
    return {groupsQty, contactsQty};
  }

  getContactPendingFields() {
    trace(`getContactPendingFields`);
    return this.getState().get('pndContactFields').filter(cpf => cpf.isEdited === true); // sic!: лише змінені поля надсилаються на BE !!!
  }

  getGroupPendingFields() {
    trace(`getGroupPendingFields`);
    return this.getState().get('pndGroupFields').filter(gpf => gpf.isEdited === true); // sic!: лише змінені поля надсилаються на BE !!!
  }

  // - - - reducers:

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [MY_CONTACTS_WERE_FETCHED_ACTION] = (state, {payload}) => {
    const {contacts, areContactsLoaded} = payload;
    if (!contacts) return state;
    const nextState = contacts.reduce(mergeContact, state);
    return areContactsLoaded ?
      nextState.mergeIn(['areMyContactsLoaded'], areContactsLoaded) :
      nextState;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [CONTACT_UPDATED_NTF_WAS_RECEIVED_ACTION] = (state, {payload}) => {
    const {[CONTACT_FLD]:contact} = payload;
    if (!contact) return state;
    const {[IS_FRIEND_FLD]:isFriend} = contact;
    return mergeContact(state, {[FRIENDSHIP_STATUS_FLD]: isFriend ? FS_FRIEND_STATUS : FS_NONE_STATUS, ...contact});
  }

  // 1) оновлення груп лише якщо юзер НЕ додавав нові групи (бо тоді викликаємо fetchMyGroups())
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [CONTACT_WAS_CREATED_ACTION] = (state, {payload}) => {
    const {contact, hasPlusGroups, appendedGroupIds} = payload;
    if (!contact) return state;
    if (hasPlusGroups) {
      return mergeContact(state, contact); // оновлюємо лише контакт, бо викликається fetchMyGroups() !!!
    }
    // 1) --> збільшуємо лічильники в групах, у які додали контакт (appendedGroupIds) !!!
    const nextState = appendedGroupIds.reduce((accState, currId) => {
        const prevContactsQty = accState.getIn(['myGroups', currId, 'contactsQty']);
        return mergeGroup(accState, {id: currId, contactsQty: prevContactsQty + 1 }); // counts: contactsQty
      }, state);
    return mergeContact(nextState, contact);
  }

  // 1) лічильники в групах оновлюються лише тоді, якщо юзер НЕ створював нових груп (т.з. плюс-групи) !!!
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [CONTACT_WAS_UPDATED_ACTION] = (state, {payload}) => {
    const {contact, hasPlusGroups, appendedGroupIds, removedGroupIds} = payload;
    const {[ID_FLD]:contactId} = contact || {};
    if (!contactId) return state;
    if (hasPlusGroups) {
      return mergeContact(state, contact); // оновлюємо лише контакт, бо викликається fetchMyGroups() !!!
    }
    // 1) --> збільшуємо лічильники в групах, у які додали контакт (appendedGroupIds) !!!
    const nextState1 = appendedGroupIds.reduce((accState, currId) => {
        const prevContactsQty = accState.getIn(['myGroups', currId, 'contactsQty']);
        return mergeGroup(accState, {id: currId, contactsQty: prevContactsQty + 1 }); // counts: contactsQty
      }, state);
    // 1) --> зменшуємо лічильники в групах, у які входив контакт (removedGroupIds) !!!
    const nextState2 = removedGroupIds.reduce((accState, currId) => {
        const prevContactsQty = accState.getIn(['myGroups', currId, 'contactsQty']);
        return mergeGroup(accState, {id: currId, contactsQty: prevContactsQty - 1 }); // counts: contactsQty
      }, nextState1);
    return mergeContact(nextState2, contact);
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [CONTACT_WAS_DELETED_ACTION] = (state, {payload}) => {
    const {contactId} = payload;
    if (!contactId) return state;
    const affectedGroups = (state.getIn(['myContacts', contactId]) || new Contact).lstGroups;
    const nextState = affectedGroups.reduce((accState, elem) => {
      const currId = elem[ID_FLD];
      const prevContactsQty = accState.getIn(['myGroups', currId, 'contactsQty']);
      return mergeGroup(accState, {id: currId, contactsQty: prevContactsQty - 1 }); // counts: contactsQty
    }, state);
    const nextState2 = nextState.deleteIn(['myContacts', contactId]);
    return nextState2.set('selectedContactIds', nextState2.get('selectedContactIds').delete(contactId));
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [CONTACT_WAS_SELECTED_ACTION] = (state, {payload}) => {
    const {contactId} = payload;
    return contactId ?
      state
        .set('selectedContactIds', state.get('selectedContactIds').add(contactId))
        .setIn(['myContacts', contactId, 'isSelected'], true) :
      state;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [CONTACT_WAS_UNSELECTED_ACTION] = (state, {payload}) => {
    const {contactId} = payload;
    return contactId ?
      state
        .set('selectedContactIds', state.get('selectedContactIds').delete(contactId))
        .setIn(['myContacts', contactId, 'isSelected'], false) :
      state;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [CONTACTS_WERE_UNSELECTED_ACTION] = (state, {payload}) => {
    return state.get('selectedContactIds').reduce((accState, contactId) => {
      return accState
        .set('selectedContactIds', accState.get('selectedContactIds').delete(contactId))
        .setIn(['myContacts', contactId, 'isSelected'], false);
    }, state);
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [FRIENDSHIP_REQUEST_WAS_SENT_TO_CONTACTS_ACTION] = (state, {payload}) => {
    const {contactIds} = payload;
    return contactIds.reduce((accState, contactId) => {
      return accState
        .set('selectedContactIds', accState.get('selectedContactIds').delete(contactId))
        .setIn(['myContacts', contactId, 'isSelected'], false);
    }, state);
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [SCTX_CHATS_WERE_CREATED_ACTION] = (state, {payload}) => {
    const {contactIds} = payload;
    return contactIds.reduce((accState, contactId) => {
      return accState
        .set('selectedContactIds', accState.get('selectedContactIds').delete(contactId))
        .setIn(['myContacts', contactId, 'isSelected'], false);
    }, state);
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [MY_GROUPS_WERE_FETCHED_ACTION] = (state, {payload}) => {
    const {groups, areGroupsLoaded} = payload;
    if (!groups) {
      return state;
    }
    const nextState = groups.reduce(mergeGroup, state);
    return areGroupsLoaded ?
      nextState.mergeIn(['areMyGroupsLoaded'], areGroupsLoaded) :
      nextState;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [GROUP_WAS_FETCHED_ACTION] = (state, {payload}) => {
    const {group} = payload;
    return group ?
      mergeGroup(state, group) :
      state;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [GROUP_WAS_CREATED_ACTION] = (state, {payload}) => {
    const {group} = payload;
    return group ?
      mergeGroup(state, group) :
      state;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [GROUP_WAS_UPDATED_ACTION] = (state, {payload}) => {
    const {prevGroup, group} = payload;
    if (!group) return state;
    // 1) при зміні 'name' групи --> змінюються назви груп в lstGroups
    const {name:prevName = ''} = prevGroup;
    const {[ID_FLD]:groupId, [NAME_FLD]:name} = group;
    const isNameChanged = prevName !== name;
    const nextState = state.get('myContacts').reduce((accState, contact) => {
      const {id:contactId, lstGroups} = contact;
      if (lstGroups) {
        const pos = lstGroups.findIndex(grp => grp[ID_FLD] === groupId);
        if (pos >= 0) {
          lstGroups.splice(pos, 1, Object.assign(lstGroups[pos], {[LABEL_FLD]: name}));
          return accState
            .setIn(['myContacts', contactId, 'strGroups'], JSON.stringify(
              isNameChanged ?
                lstGroups.sort((a, b) => { return a[LABEL_FLD] < b[LABEL_FLD] ? -1 : 1; }) :
                lstGroups
            ));
        }
      }
      return accState;
    }, state);
    // 2) власне оновлення групи
    return mergeGroup(nextState, group);
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [GROUP_WAS_DELETED_ACTION] = (state, {payload}) => {
    const {groupId} = payload;
    if (!groupId) return state;
    // 1) при видаленні групи оновлюємо поле strGroups для кожного контакту з видаленої групи
    const nextState = state.get('myContacts').reduce((accState, contact) => {
      const {id:contactId, lstGroups} = contact;
      if (lstGroups) {
        const pos = lstGroups.findIndex(grp => grp[ID_FLD] === groupId);
        if (pos >= 0) {
          lstGroups.splice(pos, 1);
          return accState.setIn(['myContacts', contactId, 'strGroups'], JSON.stringify(lstGroups || []));
        }
      }
      return accState;
    }, state);
    // 2) власне видалення групи
    return nextState.deleteIn(['myGroups', groupId]);
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [CONTACTS_OF_GROUP_WERE_SELECTED_ACTION] = (state, {payload}) => {
    const {groupId} = payload;
    if (!groupId) return state;
    return state.get('myContacts').reduce((accState, contact) => {
      const {id:contactId, lstGroups} = contact;
      if (lstGroups) {
        const pos = lstGroups.findIndex(grp => grp[ID_FLD] === groupId);
        if (pos >= 0) {
          return accState
            .set('selectedContactIds', accState.get('selectedContactIds').add(contactId))
            .setIn(['myContacts', contactId, 'isSelected'], true);
        }
      }
      return accState;
    }, state);
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [CONTACTS_OF_GROUP_WERE_UNSELECTED_ACTION] = (state, {payload}) => {
    const {groupId} = payload;
    if (!groupId) return state;
    return state.get('myContacts').reduce((accState, contact) => {
      const {id:contactId, lstGroups} = contact;
      if (lstGroups) {
        const pos = lstGroups.findIndex(grp => grp[ID_FLD] === groupId);
        if (pos >= 0) {
          return accState
            .set('selectedContactIds', accState.get('selectedContactIds').delete(contactId))
            .setIn(['myContacts', contactId, 'isSelected'], false);
        }
      }
      return accState;
    }, state);
  }

  // ToDo: IMPLEMENT !!!
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // [CONTACT_WAS_CONNECTED_TO_USER_ACTION] = (state, {payload}) => {
  //   const {contactId, user} = payload;
  //   if (!user) return state;
  //   const {
  //     id:userId,
  //     slug:userSlug,
  //     alias:userAlias,
  //     avatarSourceUrl:userAvatarSourceUrl,
  //     avatarXhash2:userXhash2,
  //     avatarExt:userExt,
  //     friendshipStatus} = user; // attn: user should be in inner format !!!
  //   const patchObject = {
  //     [ID_FLD]:contactId,
  //     [USER_ID_FLD]:userId,
  //     [USER_SLUG_FLD]:userSlug,
  //     [USER_ALIAS_FLD]:userAlias,
  //     [USER_AVATAR_SOURCE_URL_FLD]:userAvatarSourceUrl,
  //     [USER_XHASH2_FLD]:userXhash2,
  //     [USER_EXT_FLD]:userExt,
  //     // [FRIENDSHIP_STATUS_FLD]:friendshipStatus, // ???
  //     [IS_FRIEND_FLD]:friendshipStatus === FS_FRIEND_STATUS,
  //   };
  //   return mergeContactUserInfo(state, patchObject);
  // }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [MY_FRIENDS_WERE_FETCHED_ACTION] = (state, {payload}) => {
    const {friends, sentRequests, receivedRequests} = payload;
    const nextState = sentRequests.reduce((accState, user) => {
      return mergeContactUserInfo(accState, {
        [FRIENDSHIP_STATUS_FLD]: FS_REQUEST_SENT_STATUS, [IS_FRIEND_FLD]: false, ...user});
    }, state);
    const nextState1 = receivedRequests.reduce((accState, user) => {
      return mergeContactUserInfo(accState, {
        [FRIENDSHIP_STATUS_FLD]: FS_REQUEST_RECEIVED_STATUS, [IS_FRIEND_FLD]: false, ...user});
    }, nextState);
    return friends.reduce((accState, user) => {
      return mergeContactUserInfo(accState, {
        [FRIENDSHIP_STATUS_FLD]: FS_FRIEND_STATUS, [IS_FRIEND_FLD]: true, ...user});
    }, nextState1);
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [FRIENDSHIP_REQUEST_WAS_SENT_ACTION] = (state, {payload}) => {
    const {userId} = payload;
    return userId ?
      mergeContactUserInfo(state, {[ID_FLD]: userId, [FRIENDSHIP_STATUS_FLD]: FS_REQUEST_SENT_STATUS}) :
      state;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [FRIENDSHIP_REQUEST_WAS_SENT_BY_EMAIL_ACTION] = (state, {payload}) => {
    const {user} = payload;
    const prevFriendshipStatus = state.getIn(['myContacts', user[ID_FLD], 'friendshipStatus']);
    return prevFriendshipStatus !== FS_FRIEND_STATUS ?
      mergeContactUserInfo(state, {[FRIENDSHIP_STATUS_FLD]: FS_REQUEST_SENT_STATUS, ...user}) :
      state;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [FRIENDSHIP_REQUEST_WAS_RECEIVED_ACTION] = (state, {payload}) => {
    const {user} = payload;
    return user ?
      mergeContactUserInfo(state, {[FRIENDSHIP_STATUS_FLD]: FS_REQUEST_RECEIVED_STATUS, [IS_FRIEND_FLD]: false, ...user}) :
      state;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [FRIENDSHIP_REQUEST_WAS_ACCEPTED_BY_USER_ACTION] = (state, {payload}) => {
    const {user} = payload;
    return user ?
      mergeContactUserInfo(state, {[FRIENDSHIP_STATUS_FLD]: FS_FRIEND_STATUS, [IS_FRIEND_FLD]: true, ...user}) :
      state;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [FRIENDSHIP_REQUEST_WAS_ACCEPTED_BY_ME_ACTION] = (state, {payload}) => {
    const {userId} = payload;
    return userId ?
      mergeContactUserInfo(state, {[ID_FLD]: userId, [FRIENDSHIP_STATUS_FLD]: FS_FRIEND_STATUS, [IS_FRIEND_FLD]: true}) :
      state;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [FRIENDSHIP_REQUEST_WAS_REJECTED_ACTION] = (state, {payload}) => {
    const {userId} = payload;
    return userId ?
      mergeContactUserInfo(state, {[ID_FLD]: userId, [FRIENDSHIP_STATUS_FLD]: FS_NONE_STATUS, [IS_FRIEND_FLD]: false}) :
      state;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [FRIENDSHIP_WAS_CANCELED_ACTION] = (state, {payload}) => {
    const {userId} = payload;
    return userId ?
      mergeContactUserInfo(state, {[ID_FLD]: userId, [FRIENDSHIP_STATUS_FLD]: FS_NONE_STATUS, [IS_FRIEND_FLD]: false}) :
      state;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [USERS_WERE_FETCHED_ACTION] = (state, {payload}) => {
    const {users} = payload;
    return users ?
      users.reduce(mergeContactUserInfo, state) :
      state;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [CONTACT_PENDING_FIELDS_WERE_CHANGED_ACTION] = (state, {payload}) => {
    return mergeContactPendingFields(mergeContactSomeFields(state, payload), payload);
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [CONTACT_PENDING_FIELDS_WERE_SENT_ACTION] = (state, {payload}) => {
    const {contactPendingFields:cpf} = payload;
    return cpf && cpf.size > 0 ?
      cpf.reduce((accState, curr) => {
        const {id:prevId, position:prevPosition, colorTags:prevColorTags} = curr;
        return mergeContactPendingFields(accState, {
          [ID_FLD]: prevId,
          [POSITION_FLD]: prevPosition,
          [COLOR_TAGS_FLD]: prevColorTags,
          [IS_EDITED_FLD]: false // sic!: false = запобігає повторному висиланню на бекенд !!!
        });
      }, state) :
      state;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [CONTACT_PENDING_FIELDS_WERE_PROCESSED_ACTION] = (state, {payload}) => {
    const {processedContactIds} = payload;
    return processedContactIds.reduce((accState, contactId) => {
      return accState.getIn(['pndContactFields', contactId, 'isEdited']) === false ?
        accState.set('pndContactFields', accState.get('pndContactFields').delete(contactId)) :
        accState;
    }, state);
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [GROUP_PENDING_FIELDS_WERE_CHANGED_ACTION] = (state, {payload}) => {
    return mergeGroupPendingFields(mergeGroupSomeFields(state, payload), payload);
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [GROUP_PENDING_FIELDS_WERE_SENT_ACTION] = (state, {payload}) => {
    const {groupPendingFields:gpf} = payload;
    return gpf && gpf.size > 0?
      gpf.reduce((accState, curr) => {
        const {id:prevId, colorTags:prevColorTags} = curr;
        return mergeGroupPendingFields(accState, {
          [ID_FLD]: prevId,
          [COLOR_TAGS_FLD]: prevColorTags,
          [IS_EDITED_FLD]: false // sic!: false = запобігає повторному висиланню на бекенд !!!
        });
      }, state) :
      state;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [GROUP_PENDING_FIELDS_WERE_PROCESSED_ACTION] = (state, {payload}) => {
    const {processedGroupIds} = payload;
    return processedGroupIds.reduce((accState, groupId) => {
      return accState.getIn(['pndGroupFields', groupId, 'isEdited']) === false ?
        accState.set('pndGroupFields', accState.get('pndGroupFields').delete(groupId)) :
        accState;
    }, state);
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [MY_ACCOUNT_WAS_FETCHED_ACTION] = (state, {payload}) => {
    const {account} = payload;
    return account ?
      state.mergeIn(['myId'], account[ID_FLD]) :
      state;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [LOGGED_OUT_ACTION] = (state, {payload}) => {
    return this.getInitialState();
  }

  reduce(state, action) {
    const reducer = this[action.type];
    if (reducer) {
      const nextState = reducer(state, action);
      trace(`reduce:`, action, nextState.toJS());
      return nextState;
    }
    return state;
  }
}

export default new CrmStore(Dispatcher);
