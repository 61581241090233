// -------------------------------------------------------------------------------------------------
//  AccountStore.js
//  - - - - - - - - - -
//  Current user account and UI settings (Auth + Account + Layout).
//
//  Attn:
//  - - - - -
//  - стор перманентно зберігається в localStorage і відновлюється при наступній сесії;
//  - оскільки акаунт відновлюється із localStorage, то myId задане раніше, аніж в інших сторах;
//    ось чому значення myId НЕОБХІДНО отримувати за допомогою AccountSore.getMyId() !!!
// -------------------------------------------------------------------------------------------------
import {ReduceStore} from 'flux/utils';
import {Map, Record} from 'immutable';
import camelcaseKeys from 'camelcase-keys';
import Dispatcher from 'dispatcher/Dispatcher';
import {
  MY_ACCOUNT_WAS_FETCHED_ACTION,
  MY_ACCOUNT_WAS_UPDATED_ACTION,
  SIDEBAR_WAS_TOGGLED_ACTION,
  VIEWMODE_WAS_TOGGLED_ACTION,
  LOGGED_IN_ACTION,
  LOGGED_OUT_ACTION} from 'core/actionTypes';
import {
  ID_FLD,
  SLUG_FLD,
  TYPE_FLD,
  BILLING_TYPE_FLD,
  ALIAS_FLD,
  NAME_FLD,
  AVATAR_ID_FLD,
  AVATAR_SOURCE_URL_FLD,
  AVATAR_XHASH2_FLD,
  AVATAR_EXT_FLD,
  BLOB_FLD,
  BLOBS_FLD,
  PREVIEW_SS_URL_FLD,
  PREVIEW_MD_URL_FLD,
  PREVIEW_XL_URL_FLD,
  LANGUAGE_ID_FLD,
  COUNTRY_ID_FLD,
  ZIP_CODE_FLD,
  EMAIL_FLD,
  DESCRIPTION_FLD,
  ADDRESSES_FLD,
  DETAILS_FLD,
  PHONES_FLD,
  EMAILS_FLD,
  URLS_FLD,
  GROUPS_FLD,
  SUBJECTS_FLD,
  EXPIRED_AT_FLD,
  COINS_AMOUNT_FLD,
  STAPLES_QTY_FLD,
  COLLECTIONS_QTY_FLD,
  ADVERTS_QTY_FLD,
  MASTERS_QTY_FLD,
  FOLLOWERS_QTY_FLD,
  POSITION_FLD,
  IS_HELP_MODE_FLD} from 'core/apiFields';
import {LS_SETTINGS} from 'core/commonTypes';
import {BRICK_VIEW, MASONRY_VIEW, SNIPPET_VIEW, STRIPE_VIEW,
  MY_TOPIC_COLLECTIONS_FEED,
  MY_TOPIC_COURSES_FEED,
  MY_TOPIC_STAPLES_FEED,
  MY_CSUBSCRIPTIONS_FEED,
  MY_CSUBSCRIPTION_STAPLES_FEED,
  MY_COMMUNITY_USERS_FEED,
  MY_FRIENDS_FEED,
  MY_FSR_USERS_FEED,
  MY_CONTACTS_FEED,
  MY_GROUP_CONTACTS_FEED,
  MY_GROUPS_FEED,
  MY_WORKSPACE_STAPLES_FEED,
  DISCOVER_ADVERTS_FEED,
  DISCOVER_COLLECTIONS_FEED,
  DISCOVER_COURSES_FEED,
  DISCOVER_STAPLES_FEED,
  DISCOVER_USERS_FEED,
  FEATURED_COLLECTIONS_FEED,
  FEATURED_COURSES_FEED,
  FEATURED_STAPLES_FEED,
  FEATURED_USERS_FEED,
  FOLLOWING_STAPLES_FEED,
  SEARCHED_COLLECTIONS_FEED,
  SEARCHED_COURSES_FEED,
  SEARCHED_STAPLES_FEED,
  SEARCHED_USERS_FEED,
  COLLECTION_STAPLES_FEED,
  COURSE_STAPLES_FEED,
  SUBJECT_ADVERTS_FEED,
  SUBJECT_COLLECTIONS_FEED,
  SUBJECT_COURSES_FEED,
  USER_ADVERTS_FEED,
  USER_COLLECTIONS_FEED,
  USER_COURSES_FEED,
  USER_STAPLES_FEED,
  USER_TOPIC_STAPLES_FEED} from 'core/uiTypes';
import {toReact} from 'components/RichEditor/rich-conmark-processors';
import {toDxListStr} from 'components/UI/fields/DetailSelectField';
import {toPnListStr} from 'components/UI/fields/PhoneSelectField';
import {toUrListStr} from 'components/UI/fields/UrlSelectField';
import {toAdListStr, toEmListStr} from 'components/UI/fields/SelectField';
import {composeAvatarUrl} from 'utils/settingsTools';

const isVerbose = DEBUG && true;
const prefix = '- - - AccountStore';

function trace(msg, ...other) { if (isVerbose) { console.log(`${prefix}.${msg}`, ...other); }}
function traceWarn(msg, ...other) { if (isVerbose) { console.warn(`${prefix}.${msg}`, ...other); }}
function traceError(msg, ...other) { console.error(`${prefix}.${msg}`, ...other); }

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
const MyAccountRecord = Record({
  id: '',                                 //
  slug: '',                               //
  alias: '',                              //
  description: '',                        //
  [ADDRESSES_FLD]: '[]',                  // список адрес       = {{ AD-LIST-STR }}
  [DETAILS_FLD]: '[]',                    // список приміток    = {{ DX-LIST-STR }}
  [PHONES_FLD]: '[]',                     // список телефонів   = {{ PN-LIST-STR }}
  [EMAILS_FLD]: '[]',                     // список мейлів      = {{ EM-LIST-STR }}
  [URLS_FLD]: '[]',                       // список посилань    = {{ UR-LIST-STR }}
  [SUBJECTS_FLD]: '[]',                   // список предметів   = {{ SJ-LIST-STR }}
  avatarId: '',                           // код аватара, якщо '' то значить аватар не завантажували
  [AVATAR_SOURCE_URL_FLD]: '',            // url-адреса джерела аватарки
  [PREVIEW_SS_URL_FLD]: '',               // url-адреса превьюшки (SS)
  [PREVIEW_MD_URL_FLD]: '',               // url-адреса превьюшки (MD)
  [PREVIEW_XL_URL_FLD]: '',               // url-адреса превьюшки (XL)
  [LANGUAGE_ID_FLD]: '',                  // код мови інтерфейсу
  [COUNTRY_ID_FLD]: '',                   // код країни приналежності
  zipCode: '',                            // поштовий індекс
  email: '',                              // підтверджений мейл
  accountType: '',                        // тип акаунта (BASIC_ACCOUNT, ...)
  billingType: null,                      // тип білінгу (NO_BILLING, ...)
  expiredAt: '',                          // до якого часу діє accountType
  coinsAmount: 0,                         // к-сть коінів на рахунку
  isHelpMode: false,                      // чи включений режим допомоги (help mode)?
});

class MyAccount extends MyAccountRecord {
  get descriptionHtml() {
    return toReact(this.description);
  }
  get languageId() {
    return this[LANGUAGE_ID_FLD];
  }
  get countryId() {
    return this[COUNTRY_ID_FLD];
  }
  get lstAddresses() {
    return JSON.parse(this[ADDRESSES_FLD]);
  }
  get lstDetails() {
    return JSON.parse(this[DETAILS_FLD]);
  }
  get lstPhones() {
    return JSON.parse(this[PHONES_FLD]);
  }
  get lstEmails() {
    return JSON.parse(this[EMAILS_FLD]);
  }
  get lstUrls() {
    return JSON.parse(this[URLS_FLD]);
  }
  get lstSubjects() {
    return JSON.parse(this[SUBJECTS_FLD]);
  }
  get avatarSsUrl() {
    return this[PREVIEW_SS_URL_FLD] ? this[PREVIEW_SS_URL_FLD] : this[AVATAR_SOURCE_URL_FLD];
  }
  get avatarMdUrl() {
    return this[PREVIEW_MD_URL_FLD] ? this[PREVIEW_MD_URL_FLD] : this[AVATAR_SOURCE_URL_FLD];
  }
  get avatarXlUrl() {
    return this[PREVIEW_XL_URL_FLD] ? this[PREVIEW_XL_URL_FLD] : this[AVATAR_SOURCE_URL_FLD];
  }
}

// Attn: 1) формат {{ ACCOUNT }} містить поля staplesQty, collectionsQty, masterQty, followerQty,
//          але в цьому сторі вони не зберігаються і використовуються лише для отримання початкової
//          інформації по юзеру для розміщення в стор UserStore;
// Attn: 2) {{ ACCOUNT }} --> inner format ('i' --> 'id', ...)
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
function mergeMyAccount(state, patchObject) {
  const accountId = patchObject[ID_FLD] || patchObject['id'];
  return state.update('myAccount', prevMyAccount => {
    const {
      // user fields:
      [ID_FLD]:id,
      [SLUG_FLD]:slug,
      [ALIAS_FLD]:alias,
      [DESCRIPTION_FLD]:description,
      [ADDRESSES_FLD]:tgxAddresses,
      [DETAILS_FLD]:tgxDetails,
      [PHONES_FLD]:tgxPhones,
      [EMAILS_FLD]:tgxEmails,
      [URLS_FLD]:tgxUrls,
      [SUBJECTS_FLD]:strSubjects,
      [STAPLES_QTY_FLD]:staplesQty,           // sic!: 1)
      [COLLECTIONS_QTY_FLD]:collectionsQty,   // sic!: 1)
      [ADVERTS_QTY_FLD]:advertsQty,           // sic!: 1)
      [AVATAR_SOURCE_URL_FLD]:avatarSourceUrl,
      [AVATAR_XHASH2_FLD]:avatarXhash2,
      [AVATAR_EXT_FLD]:avatarExt,
      // account fields:
      [TYPE_FLD]:accountType,
      [BILLING_TYPE_FLD]:billingType,
      [EXPIRED_AT_FLD]:expiredAt,
      [COINS_AMOUNT_FLD]:coinsAmount,
      [LANGUAGE_ID_FLD]:languageId,
      [COUNTRY_ID_FLD]:countryId,
      [ZIP_CODE_FLD]:zipCode,
      [EMAIL_FLD]:email,
      [AVATAR_ID_FLD]:avatarId,
      [IS_HELP_MODE_FLD]:isHelpMode,
      ...innerFormatFields} = patchObject;
    const formattedObject = camelcaseKeys(innerFormatFields, {deep: true});
    const previewSsUrl = avatarXhash2 ? composeAvatarUrl(avatarXhash2, 's', avatarExt) : undefined; // sic!: тільки якщо отримали щось
    const previewMdUrl = avatarXhash2 ? composeAvatarUrl(avatarXhash2, 'm', avatarExt) : undefined; // sic!: тільки якщо отримали щось
    const previewXlUrl = avatarXhash2 ? composeAvatarUrl(avatarXhash2, 'x', avatarExt) : undefined; // sic!: тільки якщо отримали щось
    // - - - присвоєння робиться тільки коли є значення !!!
    if (id !== undefined)                 {formattedObject.id = accountId;}
    if (slug !== undefined)               {formattedObject.slug = slug;}
    if (alias !== undefined)              {formattedObject.alias = alias;}
    if (accountType !== undefined)        {formattedObject.accountType = accountType;}
    if (billingType !== undefined)        {formattedObject.billingType = billingType;}
    if (description !== undefined)        {formattedObject.description = description;}
    if (languageId !== undefined)         {formattedObject[LANGUAGE_ID_FLD] = languageId;}
    if (countryId !== undefined)          {formattedObject[COUNTRY_ID_FLD] = countryId;}
    if (zipCode !== undefined)            {formattedObject.zipCode = zipCode;}
    if (email !== undefined)              {formattedObject.email = email;}
    if (coinsAmount !== undefined)        {formattedObject.coinsAmount = coinsAmount;}
    if (expiredAt !== undefined)          {formattedObject.expiredAt = expiredAt;}
    if (tgxAddresses !== undefined)       {formattedObject[ADDRESSES_FLD] = toAdListStr(JSON.parse(tgxAddresses));}
    if (tgxDetails !== undefined)         {formattedObject[DETAILS_FLD] = toDxListStr(JSON.parse(tgxDetails));}
    if (tgxPhones !== undefined)          {formattedObject[PHONES_FLD] = toPnListStr(JSON.parse(tgxPhones));}
    if (tgxEmails !== undefined)          {formattedObject[EMAILS_FLD] = toEmListStr(JSON.parse(tgxEmails));}
    if (tgxUrls !== undefined)            {formattedObject[URLS_FLD] = toUrListStr(JSON.parse(tgxUrls));}
    if (strSubjects !== undefined)        {formattedObject[SUBJECTS_FLD] = strSubjects;}
    if (avatarId !== undefined)           {formattedObject.avatarId = avatarId;}
    if (avatarSourceUrl !== undefined)    {formattedObject[AVATAR_SOURCE_URL_FLD] = avatarSourceUrl;}
    if (previewSsUrl !== undefined)       {formattedObject[PREVIEW_SS_URL_FLD] = previewSsUrl;}
    if (previewMdUrl !== undefined)       {formattedObject[PREVIEW_MD_URL_FLD] = previewMdUrl;}
    if (previewXlUrl !== undefined)       {formattedObject[PREVIEW_XL_URL_FLD] = previewXlUrl;}
    if (isHelpMode !== undefined)         {formattedObject.isHelpMode = isHelpMode;}
    // - - -
    return (prevMyAccount || new MyAccount()).merge(formattedObject);
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
const UISettings = Record({
  isSidebarOpen:                          false,          // FirstSidebar open flag
  [MY_TOPIC_COLLECTIONS_FEED]:            BRICK_VIEW,     // default mode for MyTopicCollections(DEFAULT_COLLECTION)
  [MY_TOPIC_COURSES_FEED]:                BRICK_VIEW,     // default mode for MyTopicCollections(COURSE_COLLECTION)
  [MY_TOPIC_STAPLES_FEED]:                BRICK_VIEW,     // default mode for MyTopicStaples
  [MY_CSUBSCRIPTIONS_FEED]:               MASONRY_VIEW,   // default mode for MyCSubscription
  [MY_CSUBSCRIPTION_STAPLES_FEED]:        BRICK_VIEW,     // default mode for MyCSubscriptionStaples
  [MY_COMMUNITY_USERS_FEED]:              BRICK_VIEW,     // default mode for MyCommunityUsers
  [MY_FRIENDS_FEED]:                      BRICK_VIEW,     // default mode for MyFriends
  [MY_FSR_USERS_FEED]:                    BRICK_VIEW,     // default mode for MyFSRUsers
  [MY_CONTACTS_FEED]:                     BRICK_VIEW,     // default mode for MyContacts
  [MY_GROUPS_FEED]:                       BRICK_VIEW,     // default mode for MyGroups
  [MY_GROUP_CONTACTS_FEED]:               BRICK_VIEW,     // default mode for MyGroupContacts
  [MY_WORKSPACE_STAPLES_FEED]:            BRICK_VIEW,     // default mode for MyWorkspaceStaples
  [DISCOVER_ADVERTS_FEED]:                BRICK_VIEW,     // default mode for DiscoverAdverts
  [DISCOVER_COLLECTIONS_FEED]:            BRICK_VIEW,     // default mode for DiscoverCollections(DEFAULT_COLLECTION)
  [DISCOVER_COURSES_FEED]:                SNIPPET_VIEW,   // default mode for DiscoverCollections(COURSE_COLLECTION)
  [DISCOVER_STAPLES_FEED]:                BRICK_VIEW,     // default mode for DiscoverStaples
  [DISCOVER_USERS_FEED]:                  BRICK_VIEW,     // default mode for DiscoverUsers
  [FEATURED_COLLECTIONS_FEED]:            BRICK_VIEW,     // default mode for FeaturedCollections(DEFAULT_COLLECTION)
  [FEATURED_COURSES_FEED]:                SNIPPET_VIEW,   // default mode for FeaturedCollections(COURSE_COLLECTION)
  [FEATURED_STAPLES_FEED]:                BRICK_VIEW,     // default mode for FeaturedStaples
  [FEATURED_USERS_FEED]:                  BRICK_VIEW,     // default mode for FeaturedUsers
  [FOLLOWING_STAPLES_FEED]:               BRICK_VIEW,     // default mode for FollowingStaples
  [SEARCHED_COLLECTIONS_FEED]:            BRICK_VIEW,     // default mode for SearchedCollections(DEFAULT_COLLECTION)
  [SEARCHED_COURSES_FEED]:                SNIPPET_VIEW,   // default mode for SearchedCollections(COURSE_COLLECTION)
  [SEARCHED_STAPLES_FEED]:                BRICK_VIEW,     // default mode for SearchedStaples
  [SEARCHED_USERS_FEED]:                  BRICK_VIEW,     // default mode for SearchedUsers
  [COLLECTION_STAPLES_FEED]:              BRICK_VIEW,     // default mode for CollectionStaples(DEFAULT_COLLECTION)
  [COURSE_STAPLES_FEED]:                  BRICK_VIEW,     // default mode for CollectionStaples(COURSE_COLLECTION)
  [SUBJECT_ADVERTS_FEED]:                 BRICK_VIEW,     // default mode for SubjectAdverts
  [SUBJECT_COLLECTIONS_FEED]:             BRICK_VIEW,     // default mode for SubjectCollections(DEFAULT_COLLECTION)
  [SUBJECT_COURSES_FEED]:                 SNIPPET_VIEW,   // default mode for SubjectCollections(COURSE_COLLECTION)
  [USER_ADVERTS_FEED]:                    BRICK_VIEW,     // default mode for UserAdverts
  [USER_COLLECTIONS_FEED]:                BRICK_VIEW,     // default mode for UserCollections(DEFAULT_COLLECTION)
  [USER_COURSES_FEED]:                    SNIPPET_VIEW,   // default mode for UserCollections(COURSE_COLLECTION)
  [USER_STAPLES_FEED]:                    BRICK_VIEW,     // default mode for UserStaples
  [USER_TOPIC_STAPLES_FEED]:              BRICK_VIEW,     // default mode for UserTopicStaples
});

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
class AccountStore extends ReduceStore {
  static get STORAGE_KEY() {
    return LS_SETTINGS;
  }

  // save state into browser's local storage
  constructor(dispatcher) {
    super(dispatcher);
    this.addListener(() => {
      const token = localStorage.getItem('authToken');
      if (token) {
        // attn: записуємо установки тільки для залогінених юзерів !!!
        localStorage.setItem(AccountStore.STORAGE_KEY, JSON.stringify(this.getState().toJS()));
      }
    });
  }

  // remove saved state from browser's local storage
  clearLocalStorage() {
    localStorage.removeItem(AccountStore.STORAGE_KEY);
    localStorage.removeItem('authToken');
  }

  // read saved state from browser's local storage
  readLocalStorage() {
    const data = localStorage.getItem(AccountStore.STORAGE_KEY);
    try {
      return JSON.parse(data) || {};
    } catch(error) {
      return {};
    }
  }

  getInitialState() {
    trace(`getInitialState`);
    const saved = this.readLocalStorage();
    return Map({
      isLoggedIn: !!saved.isLoggedIn,
      myAccount: new MyAccount(saved.myAccount),
      ui: new UISettings(saved.ui),
    });
  }

  // - - - predicates:

  isLoggedIn() {
    return this.getState().get('isLoggedIn');
  }

  // - - - getters:

  getMyAccount() {
    trace(`getMyAccount`);
    return this.getState().get('myAccount');
  }

  getMyId() {
    trace(`getMyId`);
    return this.getState().getIn(['myAccount', 'id']);
  }

  getMyAccountType() {
    trace(`getMyId`);
    return this.getState().getIn(['myAccount', 'accountType']);
  }

  getUISettings() {
    trace(`getUISettings`);
    return this.getState().get('ui');
  }

  // - - - reducers:

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [VIEWMODE_WAS_TOGGLED_ACTION] = (state, {payload}) => {
    const {feedType, viewMode} = payload;
    return state.mergeIn(['ui', feedType], viewMode)
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [SIDEBAR_WAS_TOGGLED_ACTION] = (state, {payload}) => {
    const {isSidebarOpen} = payload;
    return state.mergeIn(['ui', 'isSidebarOpen'], isSidebarOpen);
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [MY_ACCOUNT_WAS_FETCHED_ACTION] = (state, {payload}) => {
    const {account} = payload;
    return account ?
      mergeMyAccount(state, account) :
      state;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [MY_ACCOUNT_WAS_UPDATED_ACTION] = (state, {payload}) => {
    const {account} = payload;
    return account ?
      mergeMyAccount(state, account) :
      state;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [LOGGED_IN_ACTION] = (state, {payload}) => {
    return state.merge({isLoggedIn: true});
  }

  // Attn: 3) при виході з акаунта потрібно очистити localStorage (бо цей стор зберігається в LS) !!!
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [LOGGED_OUT_ACTION] = (state, {payload}) => {
    this.clearLocalStorage();
    return this.getInitialState()
      .set('isLoggedIn', false)           // sic!: 3)
      .set('myAccount', new MyAccount())  // sic!: 3)
      .set('ui', new UISettings());       // sic!: 3)
  }

  reduce(state, action) {
    const reducer = this[action.type];
    if (reducer) {
      const nextState = reducer(state, action);
      trace(`reduce:`, action, nextState.toJS());
      return nextState;
    }
    return state;
  }
}

export default new AccountStore(Dispatcher);
