// -------------------------------------------------------------------------------------------------
//  StapleAPI.js
//  - - - - - - - - - -
//  Staple & MyStaple APIs.
//
//  Attn:
//  - - - - -
//  - для collections потрібен порядок сортування обернений до бажаного (API розвертає список);
//  - функції повертають response і по факту є обгортками до API-ендпоінтів;
//  - усі назви експортних функцій починаються на api*;
//  - параметр cursor або повинен містити значення дати-часу, або бути відсутнім в запиті;
//
//  Attn: a)
//  - - - - -
//  -   підписка CSubscription є надмножиною над Collection;
//  -   в базі даних унікальність пари [collection_id, user_id] гарантується унікальним індексом;
//  -   на фронтенді клас Collection просто розширений полями підписки (див. формат {{ CSUBSCRIPTION }});
//  -   тому вся робота з підписками здійснюється НЕ через csubscriptionId, а через collectionId !!!
// -------------------------------------------------------------------------------------------------
import {
  ID_FLD,
  USER_ID_FLD,
  ANCESTOR_ID_FLD,
  LANGUAGE_ID_FLD,
  NAME_FLD,
  DESCRIPTION_FLD,
  COMMENTS_FLD,
  ELEMENTS_FLD,
  BODY_FLD,
  EMBED_CODE_FLD,
  EMBED_TYPE_FLD,
  ORIGIN_URL_FLD,
  PICTURE_SOURCE_URL_FLD,
  PICTURE_XHASH2_FLD,
  PICTURE_EXT_FLD,
  PICTURE_ID_FLD,
  DOCUMENT_TYPE_FLD,
  DOCUMENT_XHASH2_FLD,
  DOCUMENT_EXT_FLD,
  SOURCE_FLD,
  STAPLE_FLD,
  STAPLE_ID_FLD,
  STAPLE_IDS_FLD,
  COLLECTION_ID_FLD,
  COLLECTIONS_FLD,
  GRADE_ID_FLD,
  CHAT_ID_FLD,
  CURSOR_FLD,
  LIMIT_FLD,
  ORDER_FLD,
  POSITION_FLD} from 'core/apiFields';
import {
  REF, CMD, NTF, STATUS, PAYLOAD, ERRORS,
  FETCH_DISCOVER_STAPLES_AS_GUEST_CMD,
  FETCH_DISCOVER_STAPLES_CMD,
  FETCH_FEATURED_STAPLES_AS_GUEST_CMD,
  FETCH_FEATURED_STAPLES_CMD,
  FETCH_FOLLOWING_STAPLES_CMD,
  FETCH_SEARCHED_STAPLES_CMD,
  FETCH_CSUBSCRIPTION_STAPLES_CMD,
  FETCH_COLLECTION_STAPLES_AS_GUEST_CMD,
  FETCH_COLLECTION_STAPLES_CMD,
  FETCH_USER_STAPLES_AS_GUEST_CMD,
  FETCH_USER_STAPLES_CMD,
  FETCH_STARRED_STAPLE_IDS_CMD,
  FETCH_STAPLE_AS_GUEST_CMD,
  FETCH_STAPLE_CMD,
  PARSE_ELEMENT_CMD,
  CREATE_STAPLE_CMD,
  CLONE_STAPLE_CMD,
  UPDATE_STAPLE_CMD,
  BAN_STAPLE_CMD,
  DELETE_STAPLES_CMD,
  CREATE_STAPLE_GRADE_CMD} from 'core/apiTypes';
import {DEFAULT_TSN12_CURSOR, DEFAULT_POS10_CURSOR} from 'core/commonTypes';
import {ENGLISH_LANG, UKRAINIAN_LANG} from 'core/languageTypes';
import {httpFetch} from 'api/HttpAPI';
import {wsockFetch} from 'api/WebSocketAPI';

const STAPLES_PER_REQUEST_LIMIT = 16;     // типова к-сть стейплів за один запит до бекенду

const isVerbose = DEBUG && true;
const prefix = '- - - StapleAPI';

function trace(msg, ...other) { if (isVerbose) { console.log(`${prefix}.${msg}`, ...other); }}
function traceWarn(msg, ...other) { if (isVerbose) { console.warn(`${prefix}.${msg}`, ...other); }}
function traceError(msg, ...other) { console.error(`${prefix}.${msg}`, ...other); }

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiFetchDiscoverStaplesAsGuest(opts = {}) {
  trace(`apiFetchDiscoverStaplesAsGuest`);
  const {
    cursor = DEFAULT_TSN12_CURSOR,        // значення курсора для фільтрації обʼєктів
    order = 'DESC',                       // порядок сортування записів в запиті ('DESC' || 'ASC')
    limit = STAPLES_PER_REQUEST_LIMIT,    // к-сть записів що повертаються; якщо прийде <= 0, то це значить що отримано УСІ
  } = opts;
  return await httpFetch(FETCH_DISCOVER_STAPLES_AS_GUEST_CMD, {
    method: 'POST',
    headers: {
      'accept-language': ENGLISH_LANG,
      'content-type': 'application/json'
    },
    body: JSON.stringify(
      cursor ? {
        [CURSOR_FLD]: cursor,
        [ORDER_FLD]: order,
        [LIMIT_FLD]: limit,
      } : {
        [ORDER_FLD]: order,
        [LIMIT_FLD]: limit,
      }
    ),
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiFetchDiscoverStaples(opts = {}) {
  trace(`apiFetchDiscoverStaples`);
  const {
    cursor = DEFAULT_TSN12_CURSOR,        // значення курсора для фільтрації обʼєктів
    order = 'DESC',                       // порядок сортування записів в запиті ('DESC' || 'ASC')
    limit = STAPLES_PER_REQUEST_LIMIT,    // к-сть записів що повертаються; якщо прийде <= 0, то це значить що отримано УСІ
  } = opts;
  return await wsockFetch({
    [CMD]: FETCH_DISCOVER_STAPLES_CMD,
    [PAYLOAD]: cursor ? {
      [CURSOR_FLD]: cursor,
      [ORDER_FLD]: order,
      [LIMIT_FLD]: limit,
    } : {
      [ORDER_FLD]: order,
      [LIMIT_FLD]: limit,
    }
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiFetchFeaturedStaplesAsGuest(opts = {}) {
  trace(`apiFetchFeaturedStaplesAsGuest`);
  const {
    languageId = ENGLISH_LANG,            // мова, для якої відображаємо матеріали у фіді
    cursor = DEFAULT_TSN12_CURSOR,        // значення курсора для фільтрації обʼєктів
    order = 'DESC',                       // порядок сортування записів в запиті ('DESC' || 'ASC')
    limit = STAPLES_PER_REQUEST_LIMIT,    // к-сть записів що повертаються; якщо прийде <= 0, то це значить що отримано УСІ
  } = opts;
  return await httpFetch(FETCH_FEATURED_STAPLES_AS_GUEST_CMD, {
    method: 'POST',
    headers: {
      'accept-language': ENGLISH_LANG,
      'content-type': 'application/json'
    },
    body: JSON.stringify(
      cursor ? {
        [LANGUAGE_ID_FLD]: languageId,
        [CURSOR_FLD]: cursor,
        [ORDER_FLD]: order,
        [LIMIT_FLD]: limit,
      } : {
        [LANGUAGE_ID_FLD]: languageId,
        [ORDER_FLD]: order,
        [LIMIT_FLD]: limit,
      }
    ),
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiFetchFeaturedStaples(opts = {}) {
  trace(`apiFetchFeaturedStaples`);
  const {
    languageId = ENGLISH_LANG,            // мова, для якої відображаємо матеріали у фіді
    cursor = DEFAULT_TSN12_CURSOR,        // значення курсора для фільтрації обʼєктів
    order = 'DESC',                       // порядок сортування записів в запиті ('DESC' || 'ASC')
    limit = STAPLES_PER_REQUEST_LIMIT,    // к-сть записів що повертаються; якщо прийде <= 0, то це значить що отримано УСІ
  } = opts;
  return await wsockFetch({
    [CMD]: FETCH_FEATURED_STAPLES_CMD,
    [PAYLOAD]: cursor ? {
      [LANGUAGE_ID_FLD]: languageId,
      [CURSOR_FLD]: cursor,
      [ORDER_FLD]: order,
      [LIMIT_FLD]: limit,
    } : {
      [LANGUAGE_ID_FLD]: languageId,
      [ORDER_FLD]: order,
      [LIMIT_FLD]: limit,
    }
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiFetchFollowingStaples(opts = {}) {
  trace(`apiFetchFollowingStaples`);
  const {
    cursor = DEFAULT_TSN12_CURSOR,        // значення курсора для фільтрації обʼєктів
    order = 'DESC',                       // порядок сортування записів в запиті ('DESC' || 'ASC')
    limit = STAPLES_PER_REQUEST_LIMIT,    // к-сть записів що повертаються; якщо прийде <= 0, то це значить що отримано УСІ
  } = opts;
  return await wsockFetch({
    [CMD]: FETCH_FOLLOWING_STAPLES_CMD,
    [PAYLOAD]: cursor ? {
      [CURSOR_FLD]: cursor,
      [ORDER_FLD]: order,
      [LIMIT_FLD]: limit,
    } : {
      [ORDER_FLD]: order,
      [LIMIT_FLD]: limit,
    }
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// export async function apiFetchSearchStaples(opts = {}) {
//   trace(`apiFetchSearchStaples`);
//   const {
//     cursor = DEFAULT_TSN12_CURSOR,        // значення курсора для фільтрації обʼєктів
//     order = 'DESC',                       // порядок сортування записів в запиті ('DESC' || 'ASC')
//     limit = STAPLES_PER_REQUEST_LIMIT,    // к-сть записів що повертаються; якщо прийде <= 0, то це значить що отримано УСІ
//   } = opts;
//   return await wsockFetch({
//     [CMD]: FETCH_SEARCHED_STAPLES_CMD,
//     [PAYLOAD]: cursor ? {
//       [CURSOR_FLD]: cursor,
//       [ORDER_FLD]: order,
//       [LIMIT_FLD]: limit,
//     } : {
//       [ORDER_FLD]: order,
//       [LIMIT_FLD]: limit,
//     }
//   });
// }

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiFetchCSubscriptionStaples(opts = {}) {
  trace(`apiFetchCSubscriptionStaples`);
  const {
    collectionId,                         // код колекції <--- attn: a) !!!
    cursor = DEFAULT_POS10_CURSOR,        // значення курсора для фільтрації стейплів
    order = 'DESC',                       // порядок сортування записів в запиті ('DESC' || 'ASC')
    limit = STAPLES_PER_REQUEST_LIMIT,    // к-сть записів що повертаються; якщо прийде <= 0, то це значить що отримано УСІ
  } = opts;
  return await wsockFetch({
    [CMD]: FETCH_CSUBSCRIPTION_STAPLES_CMD,
    [PAYLOAD]: cursor ? {
      [COLLECTION_ID_FLD]: collectionId,
      [CURSOR_FLD]: cursor,
      [ORDER_FLD]: order,
      [LIMIT_FLD]: limit,
    } : {
      [COLLECTION_ID_FLD]: collectionId,
      [ORDER_FLD]: order,
      [LIMIT_FLD]: limit,
    }
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiFetchCollectionStaplesAsGuest(opts = {}) {
  trace(`apiFetchCollectionStaplesAsGuest`);
  const {
    collectionId,                         // код колекції, по якій запитуємо стейпли
    cursor = DEFAULT_POS10_CURSOR,        // значення курсора для фільтрації обʼєктів
    order = 'DESC',                       // порядок сортування записів в запиті ('DESC' || 'ASC')
    limit = STAPLES_PER_REQUEST_LIMIT,    // к-сть записів що повертаються; якщо прийде <= 0, то це значить що отримано УСІ
  } = opts;
  return await httpFetch(FETCH_COLLECTION_STAPLES_AS_GUEST_CMD, {
    method: 'POST',
    headers: {
      'accept-language': ENGLISH_LANG,
      'content-type': 'application/json'
    },
    body: JSON.stringify(
      cursor ? {
        [COLLECTION_ID_FLD]: collectionId,
        [CURSOR_FLD]: cursor,
        [ORDER_FLD]: order,
        [LIMIT_FLD]: limit,
      } : {
        [COLLECTION_ID_FLD]: collectionId,
        [ORDER_FLD]: order,
        [LIMIT_FLD]: limit,
      }
    ),
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiFetchCollectionStaples(opts = {}) {
  trace(`apiFetchCollectionStaples`);
  const {
    collectionId,                         // код колекції, по якій запитуємо стейпли
    cursor = DEFAULT_POS10_CURSOR,        // значення курсора для фільтрації стейплів
    order = 'DESC',                       // порядок сортування записів в запиті ('DESC' || 'ASC')
    limit = STAPLES_PER_REQUEST_LIMIT,    // к-сть записів що повертаються; якщо прийде <= 0, то це значить що отримано УСІ
  } = opts;
  return await wsockFetch({
    [CMD]: FETCH_COLLECTION_STAPLES_CMD,
    [PAYLOAD]: cursor ? {
      [COLLECTION_ID_FLD]: collectionId,
      [CURSOR_FLD]: cursor,
      [ORDER_FLD]: order,
      [LIMIT_FLD]: limit,
    } : {
      [COLLECTION_ID_FLD]: collectionId,
      [ORDER_FLD]: order,
      [LIMIT_FLD]: limit,
    }
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiFetchUserStaplesAsGuest(opts = {}) {
  trace(`apiFetchUserStaplesAsGuest`);
  const {
    userId,                               // код юзера, по якому запитуємо стейпли
    cursor = DEFAULT_TSN12_CURSOR,        // значення курсора для фільтрації стейплів
    order = 'DESC',                       // порядок сортування записів в запиті ('DESC' || 'ASC')
    limit = STAPLES_PER_REQUEST_LIMIT,    // к-сть записів що повертаються; якщо прийде <= 0, то це значить що отримано УСІ
  } = opts;
  return await httpFetch(FETCH_USER_STAPLES_AS_GUEST_CMD, {
    method: 'POST',
    headers: {
      'accept-language': ENGLISH_LANG,
      'content-type': 'application/json'
    },
    body: JSON.stringify(
      cursor ? {
        [USER_ID_FLD]: userId,
        [CURSOR_FLD]: cursor,
        [ORDER_FLD]: order,
        [LIMIT_FLD]: limit,
      } : {
        [USER_ID_FLD]: userId,
        [ORDER_FLD]: order,
        [LIMIT_FLD]: limit,
      }
    ),
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiFetchUserStaples(opts = {}) {
  trace(`apiFetchUserStaples`);
  const {
    userId,                               // код юзера, по якому запитуємо стейпли
    cursor = DEFAULT_TSN12_CURSOR,        // значення курсора для фільтрації обʼєктів
    order = 'DESC',                       // порядок сортування записів в запиті ('DESC' || 'ASC')
    limit = STAPLES_PER_REQUEST_LIMIT,    // к-сть записів що повертаються; якщо прийде <= 0, то це значить що отримано УСІ
  } = opts;
  return await wsockFetch({
    [CMD]: FETCH_USER_STAPLES_CMD,
    [PAYLOAD]: cursor ? {
      [USER_ID_FLD]: userId,
      [CURSOR_FLD]: cursor,
      [ORDER_FLD]: order,
      [LIMIT_FLD]: limit,
    } : {
      [USER_ID_FLD]: userId,
      [ORDER_FLD]: order,
      [LIMIT_FLD]: limit,
    }
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiFetchStarredStapleIds() {
  trace(`apiFetchStarredStapleIds`);
  return await wsockFetch({
    [CMD]: FETCH_STARRED_STAPLE_IDS_CMD,
    [PAYLOAD]: {}
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiFetchStapleAsGuest(id) {
  trace(`apiFetchStapleAsGuest`);
  return await httpFetch(FETCH_STAPLE_AS_GUEST_CMD, {
    method: 'POST',
    headers: {
      'accept-language': ENGLISH_LANG,
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      [STAPLE_ID_FLD]: id,
    }),
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiFetchStaple(id) {
  trace(`apiFetchStaple: id=${id}`);
  return await wsockFetch({
    [CMD]: FETCH_STAPLE_CMD,
    [PAYLOAD]: {
      [STAPLE_ID_FLD]: id
    }
  });
}

// Attn: замість цього ендпоінту використовуємо: sendElementParsingRequest + handleElementParsingResponse !!!
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiParseElement(src) {
  trace(`apiParseElement: src=${src}`);
  return await wsockFetch({
    [CMD]: PARSE_ELEMENT_CMD,
    [PAYLOAD]: {
      [SOURCE_FLD]: src,
    }
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiCreateStaple(staple) {
  trace(`apiCreateStaple`);
  const {
    [LANGUAGE_ID_FLD]: languageId,
    [ELEMENTS_FLD]: elements,
    [COLLECTIONS_FLD]: collections} = staple;
  return await wsockFetch({
    [CMD]: CREATE_STAPLE_CMD,
    [PAYLOAD]: {
      [STAPLE_FLD]: {
        [LANGUAGE_ID_FLD]: languageId,
        [ELEMENTS_FLD]: elements,
        [COLLECTIONS_FLD]: collections,
      }
    }
  });
}

// Attn: - цей ендпоінт НЕ повинен містити поле is_public (поле is_public - системне);
// Attn: - при клонуванні значення is_public беремо зі стейпла-джерела;
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiCloneStaple(ancestorId, staple) {
  trace(`apiCloneStaple`);
  const {[COLLECTIONS_FLD]: collections} = staple;
  return await wsockFetch({
    [CMD]: CLONE_STAPLE_CMD,
    [PAYLOAD]: {
      [STAPLE_FLD]: {
        [ANCESTOR_ID_FLD]: ancestorId,
        [COLLECTIONS_FLD]: collections
      }
    }
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiUpdateStaple(staple, patchObject) {
  trace(`apiUpdateStaple`);
  const {id} = staple;
  const {
    [LANGUAGE_ID_FLD]: languageId,
    [ELEMENTS_FLD]: elements,
    [COLLECTIONS_FLD]: collections} = patchObject;
  return await wsockFetch({
    [CMD]: UPDATE_STAPLE_CMD,
    [PAYLOAD]: {
      [STAPLE_FLD]: {
        [ID_FLD]: id,
        [LANGUAGE_ID_FLD]: languageId,
        [ELEMENTS_FLD]: elements,
        [COLLECTIONS_FLD]: collections,
      }
    }
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiBanStaple(id) {
  trace(`apiBanStaple: id=${id}`);
  return await wsockFetch({
    [CMD]: BAN_STAPLE_CMD,
    [PAYLOAD]: {
      [STAPLE_ID_FLD]: id
    }
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiDeleteStaples(ids) {
  trace(`apiDeleteStaples: ids=${ids}`);
  return await wsockFetch({
    [CMD]: DELETE_STAPLES_CMD,
    [PAYLOAD]: {
      [STAPLE_IDS_FLD]: ids
    }
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export async function apiCreateStapleGrade(opts) {
  trace(`apiCreateStapleGrade`);
  const {
    gradeId = '',                         // код оцінки, яку виставлено юзеру за стейпл ('A1', 'A2', 'A3', ...)
    stapleId = '',                        // код стейплу, за який виставляємо оцінку
    chatId = '',                          // код чату, в який додаємо повідомлення про оцінку
    userId = '',                          // код юзера, якому виставляємо оцінку
    body = '',                            // текст меседжу (в форматі Conmark)
  } = opts;
  return await wsockFetch({
    [CMD]: CREATE_STAPLE_GRADE_CMD,
    [PAYLOAD]: {
      [GRADE_ID_FLD]: gradeId,
      [STAPLE_ID_FLD]: stapleId,
      [CHAT_ID_FLD]: chatId,
      [USER_ID_FLD]: userId,
      [BODY_FLD]: body,
    }
  });
}
