// -------------------------------------------------------------------------------------------------
//  ChatStore.js
//  - - - - - - - - - -
//  Chats, messages & related information.
//
//  Notes:
//  - - - - -
//  -   кожен чат містить:
//      -   список контекстів (contexts);
//      -   список повідомлень (messages);
//      -   список ідентифікаторів учасників (memberIds);
//
//  Attn: ідеологія чатів/фідів:
//  - - - - - - - - - - - - - -
//  -   в contextFeeds для кожного фіду зберігається:
//      -   areContextsLoaded = флаг завантаження усіх контекстів фіду;
//      -   contextCursor = стрічка, що містить поточний курсор для формування пагінації;
//      -   contextDatas = інформація про відношення між чатами та контекстами;
//      -
//  -   areContextsLoaded:
//      -   якщо при запиті наступної сторінки контекстів ми отримали к-сть меншу за запитану,
//          то значить ми дійшли на бекенді до дна списку (вибрали УСІ контексти);
//      -
//  -   contextCursor:
//      -   наступний курсор розраховується на фронтенді (мінімальний chatId з отриманих);
//      -
//  -   contextDatas:
//      -   {chatId: {ctxId, lastMessageId, hasUnreadMessages}}
//      -   містить записи для усіх невидалених та незакритих чатів;
//      -   список contextDatas оновлюється:
//          -   при отриманні непрочитаних повідомлень виставляємо HAS_UNREAD_MESSAGES_FLD -> оновлюємо/додаємо запис;
//          -   при отриманні порції контекстів -> оновлюємо/додаємо запис;
//          -   при кожному оновленні чату (якщо поле HAS_UNREAD_MESSAGES_FLD === true) -> оновлюємо/додаємо запис;
//          -   при видаленні чату -> видаляємо запис про чат;
//          -   при закритті чату ->  видаляємо запис про чат;
//          -
//      -   запис в contextDatas може бути видаленим лише тоді, коли поле HAS_UNREAD_MESSAGES_FLD
//          не виставлялось в true в поточному виклику функції mergeChat і НЕ виставлялось раніше;
//          це потрібно для ситуації, коли чати вже мають статус CLOSED_CHAT_STATUS,
//          але у юзерів залишились непрочитані повідомлення (такі чати прилітають разом зі списком
//          ПОЧАТКОВИХ непрочитаних повідомлень);
//      -   при видаленні усіх чатів не залишиться жодного запису в списку contextDatas і контекст
//          пропаде із показу в фіді;
//      -   при закритті чату контекст видаляється із contextDatas ЛИШЕ якщо немає (і не було в даному сеансі)
//          непрочитаних повідомлень; тобто із огляду юзером він може пропасти лише при наступному
//          перезавантаженні сторінки;
//      -
//  -   оновлення чату повинно містити повну інформацію про контексти;
//  -   непрочитані повідомлення поділяємо на дві групи: початкові і поточні;
//  -   список ПОЧАТКОВИХ непрочитаних повідомлень повинен мати повну інформацію про чати з контекстами;
//      тоді буде можливість показати: а) усі контексти з непрочитаними; б) показати їх ПЕРЕД
//      іншими контекстами наверху фіду;
//  -   поточні непрочитані повідомлення теж оновлюють contextDatas, але вони НЕ мають значення ctxId,
//      тому при отриманні поточних повідомлень потрібно додатково робити fetchChat(), якщо
//      його немає в сторі (чи fetchStaple() відповідно);
//  -   для unreadMessages треба щоб були завантажені чати (потрібні контексти чатів для розрахунків
//      к-стей непрочитаних повідомлень до кожного екземпляру);
//  -   для кожного контекстного фіду є унікальний префікс (напр: MY_TOPIC_CONTEXTS_PREFIX + ctxType);
//  -   для формування списку контекстів для відображення в фіді MyTopicStaplesFeed
//      виконуємо запит до contextDatas з фільтрацією по: chatId > cursor || hasUnreadMessages
//      і отримуємо список унікальних вудсортованих contextId;
//  -   нові ідентифікатори будуть БІЛЬШІ поточного курсора; завдяки цьому ми завжди можемо додати
//      нові контексти/чати (іхні ідентифікатори будуть ВИЩЕ курсора) і ми НЕ порушимо алгоритм пагінації;
//  -   правила сортування контекстів:
//      -   якщо лише один контекст-чат з непрочитаними = вище
//      -   якщо контекст-чати однакові в сенсі непрочитаних, тому новіший чат = вище (більший chatId)
//  -   такий спосіб впорядкування/сортування зменшує к-сть "стрибків", бо:
//      -   початкові чати з непрочитаними повідомленнями відображатимуться не залежно від поточного курсору;
//      -   при роботі з одним чатом він постійно триматиметься наверху списку;
//      -   при отриманні нових повідомлень чат переміщатиметься наверх;
//  -   можливо треба буде додати якісь обмеження на частоту зміни lastMessageId,
//      або лише на зміну, якщо lastMessageId належить до ЧУЖОГО меседжа;
//
//  Attn: ідеологія роботи з сайдбаром (FirstSidebar):
//  - - - - - - - - - - - - - - - - - - - - - - - - -
//  - sideCtxId, sideCtxType зберігають поточний контекст;
//  - для кожного контексту зберігаємо вектор поточних параметрів (sideOptions);
//  - при зміні контексту --> зберігаємо новий контекст + змінюємо вектор згідно нового контексту;
//  - контексти за типом поділяються на "тематичні" (для STAPLE, COLLECTION) та "прямі" (для USER);
//  - реалізовано режими роботи з вкладками: COLLECTIONS, CHATS_N_FRIENDS, ONE_CHAT, CALLS;
//  - в залежності від поточного sideTabMode при зміні контексту відкривається відповідна вкладка;
//    якщо поточним режимом є ONE_CHAT, але не вказаний/не вибраний chatId для поточного контексту,
//    то змінюємо режим вкладок на CHATS_N_FRIENDS;
//
//  Attn:
//  - - - - -
//  - НЕ обʼєднувати sideOptions з contextIds, щоб не було re-render для сайдбару при оновленні
//    лічильників contextIds;
//  - ендпоінти, що повертають дані в форматі {{ CHAT }} мають повну інформацію про учасників чатів;
//    окрім цього стору ця інфа обробляється в UserStore (зберігаємо повну інфу про юзерів);
//  - для контекстів використовуємо "розширені" ідентифікатори виду: cid = ctxId + ctxType,
//    (напр: 'cxy3IkXTyhe01' + 's'), що дозволяє отримати екземпляр контексту за одну операцію;
//  - функції getSomeChats/getSomeChatsMap повертають лише юзерів, що знайдені в сторі;
//  - списки ідентифікаторів учасників чату Chat.memberIds НЕ містять ідентифікатор користувача myId;
//
//  Attn: a) (оновлення превьюшок):
//  - - - - - - - - - - - - - - -
//  - формування превьюшок на сервері потребує часу, тому потрібно зробити re-fetch превьюшок в браузері;
//  - оновлення робимо через зміну регістру поля '.ext' (спочатку в upperCase, потім через пару секунд в LowerCase);
//
//  Attn: b)
//  - - - - -
//  - чат з непрочитаними повідомленнями повинен мати флаг HAS_UNREAD_MESSAGES щоб бути БЕЗУМОВНО показаним у фіді;
//
//  Attn: c) (тип чату === тип контексту):
//  - - - - - - - - - - - - - - - - - - -
//  - тип чату співпадає з типом контексту, до якого відноситься цей чат !!!
// -------------------------------------------------------------------------------------------------
import {ReduceStore} from 'flux/utils';
import {List, Map, Set, OrderedSet, Record} from 'immutable';
import camelcaseKeys from 'camelcase-keys';
import Dispatcher from 'dispatcher/Dispatcher';
import {
  MY_ACCOUNT_WAS_FETCHED_ACTION,
  MY_FRIENDS_WERE_FETCHED_ACTION,
  MY_CONTEXTS_WERE_FETCHED_ACTION,
  USER_CONTEXTS_WERE_FETCHED_ACTION,
  // MY_SCTX_CHATS_WERE_FETCHED_ACTION,
  // USER_SCTX_CHATS_WERE_FETCHED_ACTION,
  STAPLE_SCTX_CHATS_WERE_FETCHED_ACTION,
  // COLLECTION_CCTX_CHATS_WERE_FETCHED_ACTION,
  USER_UCTX_CHATS_WERE_FETCHED_ACTION,
  CHATS_WERE_FETCHED_ACTION,
  CHAT_WAS_CREATED_ACTION,
  SCTX_CHATS_WERE_CREATED_ACTION,
  CHAT_WAS_UPDATED_ACTION,
  CHAT_WAS_CLOSED_ACTION,
  CHAT_WAS_DELETED_ACTION,
  CHAT_MESSAGES_WERE_FETCHED_ACTION,
  CHAT_MESSAGE_WAS_CREATED_ACTION,
  CHAT_MESSAGE_WAS_UPDATED_ACTION,
  CHAT_MESSAGE_WAS_READ_ACTION,
  CHAT_MESSAGE_WAS_DELETED_ACTION,
  CHAT_MESSAGE_WAS_RECEIVED_ACTION,
  UNREAD_MESSAGES_WERE_FETCHED_ACTION,
  UNREAD_MESSAGES_WERE_READ_ACTION,
  UNREAD_MESSAGE_WAS_OBSERVED_ACTION,
  STAPLE_WAS_CREATED_ACTION,
  STAPLE_WAS_CLONED_ACTION,
  STAPLES_WERE_DELETED_ACTION,
  COLLECTION_WAS_DELETED_ACTION,
  SIDEBAR_CONTEXT_WAS_SUBSTITUTED_ACTION,
  SIDEBAR_CHAT_WAS_SUBSTITUTED_ACTION,
  SIDEBAR_TABMODE_WAS_SUBSTITUTED_ACTION,
  CONTACT_WAS_SELECTED_ACTION,
  CONTACTS_OF_GROUP_WERE_SELECTED_ACTION,
  STAPLE_WAS_SELECTED_ACTION,
  STAPLES_OF_COLLECTION_WERE_SELECTED_ACTION,
  LOGGED_OUT_ACTION} from 'core/actionTypes';
import {
  ID_FLD,
  OWNER_ID_FLD,
  AUTHOR_ID_FLD,
  TYPE_FLD,
  STATUS_FLD,
  BODY_FLD,
  ATTACHMENTS_FLD,
  CONTEXT_ID_FLD,
  CONTEXTS_FLD,
  GRADE_FLD,
  CHAT_ID_FLD,
  CHAT_TYPE_FLD,
  DIRECT_CHAT_ID_FLD,
  MEMBER_IDS_FLD,
  MESSAGE_FLD,
  MESSAGE_ID_FLD,
  LAST_MESSAGE_ID_FLD,
  LAST_MESSAGE_TYPE_FLD,
  LAST_MESSAGE_BODY_FLD,
  LAST_MESSAGE_ATTACHMENTS_FLD,
  PICTURE_XHASH2_FLD,
  PICTURE_EXT_FLD,
  PICTURES_FLD,
  BLOB_FLD,
  CREATED_AT_FLD,
  HAS_UNREAD_MESSAGES_FLD,
  // IS_OWNER_FLD,
  // IS_ADMIN_FLD,
  // IS_WRITER_FLD,
  // IS_READER_FLD,
  // IS_SUBSCRIBED_FLD,
  // IS_BLOCKED_FLD,
  // IS_BANNED_FLD,
  IS_READ_FLD,
  POSITION_FLD} from 'core/apiFields';
import {LS_SETTINGS, DEFAULT_TSN12_CURSOR} from 'core/commonTypes';
import {
  USER_CTX, STAPLE_CTX, COLLECTION_CTX, ADVERT_CTX, ROOM_CTX,
  UCTX_CHAT, SCTX_CHAT,
  INITIAL_CHAT_STATUS,
  ACTIVE_CHAT_STATUS,
  ASSIGNED_CHAT_STATUS,
  SUBMITTED_CHAT_STATUS,
  REVISION_CHAT_STATUS,
  GRADED_CHAT_STATUS,
  DISPUTE_CHAT_STATUS,
  CLOSED_CHAT_STATUS,
  INITIAL_MESSAGE,
  ACTIVATION_MESSAGE,
  ASSIGNED_MESSAGE,
  SUBMISSION_MESSAGE,
  REVISION_MESSAGE,
  DISPUTE_MESSAGE,
  TEXT_MESSAGE,
  MEDIA_MESSAGE,
  GRADE_MESSAGE,
  REPLY_MESSAGE,
  WIPED_MESSAGE,
  SYSTEM_MESSAGE,
  MISSED_CALL_MESSAGE,
  REJECTED_CALL_MESSAGE,
  FINISHED_CALL_MESSAGE,
  CLOSED_MESSAGE,
  chatStatusName} from 'core/communicationTypes';
import {COMMUNITY_TAB, CHAT_TAB, SELECTION_TAB} from 'core/uiTypes';
import {fetchChats} from 'actions/ChatActions';
import {toReact} from 'components/RichEditor/rich-conmark-processors';
import {isConmarkEmpty} from 'components/UI/fields/RichField';
import {composePictureUrl} from 'utils/settingsTools';
import {domain} from 'settings/local.yaml';

const isVerbose = DEBUG && true;
const prefix = '- - - ChatStore';

function trace(msg, ...other) { if (isVerbose) { console.log(`${prefix}.${msg}`, ...other); }}
function traceWarn(msg, ...other) { if (isVerbose) { console.warn(`${prefix}.${msg}`, ...other); }}
function traceError(msg, ...other) { console.error(`${prefix}.${msg}`, ...other); }

const MY_TOPIC_CONTEXTS_PREFIX            = 'myctxx'; // префікс ключа для усіх моїх фідів контекстів спілкування
const USER_TOPIC_CONTEXTS_PREFIX          = 'u'; // префікс ключа для спільних з юзерами фідів контекстів спілкування

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
const ChatContext = Record({
  ctxId: '',                              // ідентифікатор контексту
  ctxType: '',                            // тип контексту = {'s' || 'c' || 'u'}
  ctxOwnerId: '',                         // ідентифікатор власника контексту
});

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
const SideOption = Record({
  ctxId: '',                              // ідентифікатор контексту
  ctxType: '',                            // тип контексту = {'s' || 'c' || 'u'}
  ctxOwnerId: '',                         // ідентифікатор власника контексту
  chatId: '',                             // ідентифікатор активного чату в даному контексті
});

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
const UnreadMessage = Record({
  id: '',                                 // ідентифікатор непрочитаного повідомлення
  chatId: '',                             // код чату до якого відноситься повідомлення
  chatType: '',                           // тип чату до якого відноситься повідомлення
  authorId: '',                           // автор непрочитаного повідомлення
});

function mergeUnreadMessage(state, patchObject) {
  return state.updateIn(['unreadMessages', patchObject[MESSAGE_ID_FLD]], prevUnreadMessage => {
    // {{ UNREAD }} --> {{ UnreadMessage }}
    const {
      [MESSAGE_ID_FLD]:id,
      [CHAT_ID_FLD]:chatId,
      [CHAT_TYPE_FLD]:chatType,
      [AUTHOR_ID_FLD]:authorId} = patchObject;
    return (prevUnreadMessage || new UnreadMessage()).merge({id, chatId, chatType, authorId});
  });
}

// Attn: 1) - теоретично до меседжу можна вкласти довільну к-сть зображень, тому формат {{ ATTACHMENTS }} містить СПИСОК картинок;
//          - практично ПОКИ ЩО використовуємо лише ОДНЕ зображення, тому в сторі Message містить поля {xhash2, ext, blob};
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
const MessageRecord = Record({
  id: '',                                 // ідентифікатор повідомлення
  chatId: '',                             // чат, до якого відноситься повідомлен
  authorId: '',                           // автор повідомлення
  type: '',                               // тип меседжу = {'t' | 's' | ... }
  body: '',                               // текст повідомлення в форматі (Conmark)
  extraText: '',                          // додаткова інформація для відображення (напр: код оцінки, ...)
  createdAt: '',                          // дата/час створення повідомлення
  xhash2: '',                             // sic!: 1) хеш-код превьюшки
  ext: '',                                // sic!: 1) розширення файлу превьюшки
  blob: '',                               // sic!: 1) джерело превьюшки
  isRead: false,                          // статус "прочитано"
});

class Message extends MessageRecord {
  get bodyHtml() {
    return toReact(this.body);
  }
  get previewSsUrl() {
    return this.blob ? this.blob : this.xhash2 ? composePictureUrl(this.xhash2, 's0', this.ext) : undefined;
  }
  get previewMdUrl() {
    return this.blob ? this.blob : this.xhash2 ? composePictureUrl(this.xhash2, 'm0', this.ext) : undefined;
  }
  get previewXlUrl() {
    return this.blob ? this.blob : this.xhash2 ? composePictureUrl(this.xhash2, 'x0', this.ext) : undefined;
  }
}

// Attn: обовʼязкові поля: [ID_FLD], [CHAT_ID_FLD] !!!
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
function mergeChatMessage(state, patchObject) {
  return state.updateIn(['chats', patchObject[CHAT_ID_FLD], 'messages'], prevMessages => {
    // {{ MESSAGE }} --> {{ MessageRecord }}
    const {
      [ID_FLD]:id,
      [TYPE_FLD]:type,
      [CHAT_ID_FLD]:chatId,
      [AUTHOR_ID_FLD]:authorId,
      [BODY_FLD]:body,
      [CREATED_AT_FLD]:createdAt,
      [ATTACHMENTS_FLD]:strAttachments,
      [BLOB_FLD]:blob,
      [IS_READ_FLD]:isRead,
      isPictureReloadRequired,
      ...formattedObject} = patchObject; // без camelcaseKeys (бо є поля з '_') !!!
    // - - - присвоєння робиться тільки коли є значення !!!
    if (id !== undefined)                 {formattedObject.id = id;}
    if (chatId !== undefined)             {formattedObject.chatId = chatId;}
    if (authorId !== undefined)           {formattedObject.authorId = authorId;}
    if (type !== undefined)               {formattedObject.type = type;}
    if (body !== undefined)               {formattedObject.body = body;}
    if (createdAt !== undefined)          {formattedObject.createdAt = createdAt;}
    if (strAttachments !== undefined)     {
      // ...attachment is 'pictures':
      const picture = extractPictures(JSON.parse(strAttachments))[0];
      if (picture) {
        formattedObject.xhash2 = picture.xhash2;
        formattedObject.ext = picture.ext;
        if (isPictureReloadRequired) {
          formattedObject.ext = picture.ext.toUpperCase(); // sic!: a) = оновлення превьюшок
        }
      }
      // ...attachment is 'grade':
      const {[GRADE_FLD]:attachedGrade} = JSON.parse(strAttachments); // {{ ATTACHMENTS }}
      if (attachedGrade) {
        const {[ID_FLD]:gradeId} = attachedGrade;
        if (gradeId !== undefined)        {formattedObject.extraText = gradeId;}
      }
    }
    if (blob !== undefined)               {formattedObject.blob = blob;}
    if (isRead !== undefined)             {formattedObject.isRead = isRead;}
    // - - -
    const index = prevMessages ? prevMessages.findIndex(m => m.id === id) : -1; // ? може бути null ???
    return index >= 0 ?
      prevMessages.update(index, msg => (msg || new Message()).merge(formattedObject)) :
      prevMessages.push(new Message(formattedObject));
  });
}

// 	Extract pictures from {{ ATTACHMENTS }} format.
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
//  {                                     {{ ATTACHMENTS }}:
//    PICTURES_FLD: {
//      "1Qj0oh31ft0...SB": {             // xhash2
//        xt: "jpeg",                     // extension = розширення файлу зображення
//      },
//      ...
//    }
//  }
//
//  [                                     {{ PICTURE_LIST }}:
//    {
//      xhash2: "1Qj0oh31ft0...SB"        // xhash2
//      ext: "jpeg"                       // extension = розширення файлу зображення
//    },
//    ...
//  ]
//
export function extractPictures(attachments) {
  const {[PICTURES_FLD]:attachedPicts} = attachments;
  let pictures = [];
  if (attachedPicts && Object.keys(attachedPicts).length > 0) {
    for (let x in attachedPicts) {
      const {[PICTURE_EXT_FLD]:pictureExt} = attachedPicts[x];
      if (pictureExt) {
        pictures = pictures.concat([{
          xhash2: x,
          ext: pictureExt,
        }]);
      }
    }
  }
  return pictures;
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
const ChatRecord = Record({
  id: '',                                 // chat id
  type: '',                               // тип чату ('u'/user, 's'/staple, 'c'/collection, ..., '#'/system)
  [STATUS_FLD]: '',                       // статус чату (initial, assigned, ..., closed)
  ownerId: '',                            // юзер, який створив цей чат (власник чату)
  lastMessageId: '', // sic!              // id останнього повідомлення (якщо значення null, то не видає чат у запитах)
  lastMessageType: '',                    // тип останнього повідомлення (має значення null, якщо було видалено останнє повідомлення)
  lastMessageBody: '',                    // текст останнього повідомлення
  lastMessageAttachments: '',             // вкладення останнього повідомлення
  // isOwner: false,                      // owner status
  // isAdmin: false,                      // can administrate and moderate
  // isReader: false,                     // can read messages (non deleted and non banned)
  // isWriter: false,                     // can compose new messages
  // isSubscribed: false,                 // is user subscribed for new messages (subscription)
  // isBlocked: false,                    // is user blocked (blocking)
  // ...contexts:
  currCtxId: '',                          // ідентифікатор контексту (розраховується по 'contexts')
  currCtxType: '',                        // тип контексту (розраховується по 'contexts')
  currCtxOwnerId: '',                     // ідентифікатор власника контексту (розраховується по 'contexts')
  contexts: Map(),                        // список контекстів цього чату
  // ...members:
  memberIds: Map(),                       // список ідентифікаторів учасників цього чату (без myId)
  // ...messages:
  messages: List(),                       // список меседжів цього чату
  messageCursor: DEFAULT_TSN12_CURSOR,    // наступний курсор для списку повідомлень цього чату
  areMessagesLoaded: false,               // чи завантажені усі повідомлення цього чату в стор?
});

class Chat extends ChatRecord {
  get value() {                           // required by SelectField !!!
    return this.slug;
  }
  get label() {                           // required by SelectField !!!
    return this.alias;
  }
}

// Attn: 1) myId відкидаємо, бо чат НЕ повинен містити поточного користувача в учасниках чату;
// Attn: 2) {{ CHAT }} --> inner format ('i' --> 'id', ...)
// Attn: 3) не видаляються записи з contextDatas, коли чати мають стутус CLOSED_CHAT_STATUS,
//          але у юзерів залишились непрочитані повідомлення (такі чати прилітають разом зі списком
//          ПОЧАТКОВИХ непрочитаних повідомлень);
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// FixMe: 1) для прямих/direct-чатів з юзером ('u') залишати лише ОДИН контекст !!!
function mergeChat(state, patchObject) {
  const chatId = patchObject[ID_FLD] || patchObject['id'];
  const {
    [ID_FLD]:id,
    [TYPE_FLD]:type,
    [STATUS_FLD]:status,
    [OWNER_ID_FLD]:ownerId,
    [LAST_MESSAGE_ID_FLD]:lastMessageId,
    [LAST_MESSAGE_TYPE_FLD]:lastMessageType,
    [LAST_MESSAGE_BODY_FLD]:lastMessageBody,
    [LAST_MESSAGE_ATTACHMENTS_FLD]:lastMessageAttachments,
    [HAS_UNREAD_MESSAGES_FLD]:hasUnreadMessages,
    // [IS_OWNER_FLD]:isOwner,
    // [IS_ADMIN_FLD]:isAdmin,
    // [IS_WRITER_FLD]:isWriter,
    // [IS_READER_FLD]:isReader,
    // [IS_SUBSCRIBED_FLD]:isSubscribed,
    // [IS_BLOCKED_FLD]:isBlocked,
    [CONTEXTS_FLD]:contexts = [],
    [MEMBER_IDS_FLD]:memberWithMyIds = [],
    ...innerFormatFields} = patchObject;
  const formattedObject = camelcaseKeys(innerFormatFields, {deep: true});
  // - - - присвоєння робиться тільки коли є значення !!!
  if (id !== undefined)                     {formattedObject.id = chatId;}
  if (type !== undefined)                   {formattedObject.type = type;}
  if (status !== undefined)                 {formattedObject[STATUS_FLD] = status;}
  if (ownerId !== undefined)                {formattedObject.ownerId = ownerId;}
  if (lastMessageId !== undefined)          {formattedObject.lastMessageId = lastMessageId;}
  if (lastMessageType !== undefined)        {formattedObject.lastMessageType = lastMessageType;}
  if (lastMessageBody !== undefined)        {formattedObject.lastMessageBody = !isConmarkEmpty(lastMessageBody) ? lastMessageBody : '';} // ToDo: зробити перетворення Conmark --> Text !!!
  if (lastMessageAttachments !== undefined) {formattedObject.lastMessageAttachments = lastMessageAttachments;}
  // if (isOwner !== undefined)             {formattedObject.isOwner = isOwner;}
  // if (isAdmin !== undefined)             {formattedObject.isAdmin = isAdmin;}
  // if (isWriter !== undefined)            {formattedObject.isWriter = isWriter;}
  // if (isReader !== undefined)            {formattedObject.isReader = isReader;}
  // if (isSubscribed !== undefined)        {formattedObject.isSubscribed = isSubscribed;}
  // if (isBlocked !== undefined)           {formattedObject.isBlocked = isBlocked;}
  // - - -
  const myId = state.get('myId');
  const memberIds = memberWithMyIds.filter(memberId => memberId !== myId); // attn: 1)
  // ...
  let currCtxId = '';
  let currCtxType = '';
  let currCtxOwnerId = '';
  // ...1) chat:
  const nextState = state.updateIn(['chats', chatId], prevChat => {
    const nextChat0 = (prevChat || new Chat()).merge(formattedObject);
    currCtxId = nextChat0.get('currCtxId');
    currCtxType = nextChat0.get('currCtxType');
    currCtxOwnerId = nextChat0.get('currCtxOwnerId');
    const nextChat1 = contexts.reduce((accChat, context) => {
      const {[ID_FLD]:ctxId = '', [TYPE_FLD]:ctxType = '', [OWNER_ID_FLD]:ctxOwnerId = ''} = context;
      // ...відбираємо єдиний контекст до чату: - власні понад чужі; - нові понад давні
      if (ctxOwnerId === myId) {
        if (ctxId > currCtxId || currCtxOwnerId !== myId || currCtxOwnerId === '') {
          currCtxId = ctxId;
          currCtxType = ctxType;
          currCtxOwnerId = ctxOwnerId;
        }
      } else {
        if ((ctxId > currCtxId && currCtxOwnerId !== myId) || currCtxOwnerId === '') {
          currCtxId = ctxId;
          currCtxType = ctxType;
          currCtxOwnerId = ctxOwnerId;
        }
      }
      return accChat.updateIn(['contexts', ctxId + ctxType], () => {
        return new ChatContext({ctxId, ctxType, ctxOwnerId});
      });
    }, nextChat0);
    const nextChat2 = nextChat1
      .set('currCtxId', currCtxId)
      .set('currCtxType', currCtxType)
      .set('currCtxOwnerId', currCtxOwnerId);
    return memberIds.reduce((accChat, memberId) => {
      return accChat.updateIn(['memberIds', memberId], () => memberId);
    }, nextChat2);
  });
  // ...2) contextFeeds:
  switch (currCtxType) {
    case STAPLE_CTX:
      // ...видаляємо контекст при закритті чату (якщо нема непрочитаних повідомлень і раніше не був виставлений флаг HAS_UNREAD_MESSAGES_FLD)
      if (status === CLOSED_CHAT_STATUS && !hasUnreadMessages) {
        const {contextDatas:prevContextData} = nextState.getIn(['contextFeeds', MY_TOPIC_CONTEXTS_PREFIX + STAPLE_CTX]) || new ContextFeed();
        if (!prevContextData.getIn([chatId, HAS_UNREAD_MESSAGES_FLD])) { // attn: 3) !!!
          return mergeContextFeed(nextState, MY_TOPIC_CONTEXTS_PREFIX + STAPLE_CTX, {contextDatas: prevContextData.delete(chatId)});
        }
      }
      // ...додаємо усі контексти крім закритих + контексти з непрочитаними повідомленнями
      if (status !== CLOSED_CHAT_STATUS || hasUnreadMessages) {
        const {contextDatas:prevContextData} = nextState.getIn(['contextFeeds', MY_TOPIC_CONTEXTS_PREFIX + STAPLE_CTX]) || new ContextFeed();
        if (chatId && prevContextData && (!prevContextData.has(chatId) || hasUnreadMessages)) {
          const nextContext = {};
          if (currCtxId) {nextContext[CONTEXT_ID_FLD] = currCtxId;}
          if (lastMessageId) {nextContext[LAST_MESSAGE_ID_FLD] = lastMessageId;}
          if (hasUnreadMessages) {nextContext[HAS_UNREAD_MESSAGES_FLD] = true;}
          return mergeContextFeed(nextState, MY_TOPIC_CONTEXTS_PREFIX + STAPLE_CTX, {contextDatas: prevContextData.merge({[chatId]: nextContext})});
        }
      }
      break;
  }
  return nextState;
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
const ContextFeed = Record({
  contextDatas: Map(),                    // екстра-дані до контекстів спілкування
  contextCursor: '',                      // наступний курсор для даного фіду (sic!: '', бо ініціалізуємо в Actions/API)
  areContextsLoaded: false,               // чи завантажені усі контексти цього фіду в стор?
});

function mergeContextFeed(state, key, patchObject) {
  return state.updateIn(['contextFeeds', key], prevFeed => {
    return (prevFeed || new ContextFeed()).merge(patchObject);
  });
}

function resetContextFeed(state, key) {
  return state.updateIn(['contextFeeds', key], prevFeed => {
    return ContextFeed();
  });
}

// Attn: 1) значення myId потрібно відразу (тому з LS, бо з серверу приходить з затримкою) !!!
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
class ChatStore extends ReduceStore {
  getInitialState() {
    trace(`getInitialState`);
    const {myAccount} = JSON.parse(localStorage.getItem(LS_SETTINGS)) || {}; // attn: 1)
    const {id:myId = ''} = myAccount || {};
    return Map({
      myId: myId,                         // ідентифікатор користувача
      chats: Map(),                       // завантажені в стор чати
      contextFeeds: Map(),                // дані для фідів контекстів спілкування
      observedMessageIds: Set(),          // ідентифікатори переглянутих, але ще не видалених із сервера повідомлень
      unreadMessages: Map(),              // непрочитані повідомлення в форматі {{ UNREAD }}
      // ...sidebar:
      sideCtxId: '',                      // ідентифікатор поточного контексту сайдбару
      sideCtxType: '',                    // тип поточного контексту сайдбару
      sideOptions: Map(),                 // список параметрів сайдбару для кожного контексту
      sideTabMode: '',                    // поточна вкладка сайдбару
    });
  }

  // - - - predicates:

  isChatLoaded(id) {
    const result = !!this.getState().getIn(['chats', id]);
    trace(`isChatLoaded: chatId=${id}, loaded=${result}`);
    return result;
  }

  areChatMessagesLoaded(id) {
    const result = this.getState().getIn(['chats', id, 'areMessagesLoaded']);
    trace(`areStapleChatsLoaded: chatId=${id}, loaded=${result}`);
    return result;
  }

  // areMySctxChatsLoaded() {
  //   const result = this.getState().get('areMySctxChatsLoaded');
  //   trace(`areMySctxChatsLoaded: loaded=${result}`);
  //   return result;
  // }

  // - - - getters:

  getMyId() {
    trace(`getMyId`);
    return this.getState().get('myId');
  }

  getChat(id) {
    trace(`getChat`);
    return this.getState().getIn(['chats', id]);
  }

  getChats() {
    trace(`getChats`);
    return this.getState().get('chats').toList();
  }

  getSomeChats(ids = []) {
    trace(`getSomeChats`);
    return ids.reduce((acc, id) => {
      const chat = this.getState().getIn(['chats', id]);
      if (!chat) {
        traceError(`getSomeChats: chatId=${id}`);
        return acc;
      }
      return acc.add(chat);
    }, Set());
  }

  getSomeChatsMap(ids = []) {
    trace(`getSomeChatsMap`);
    return ids.reduce((acc, id) => {
      const chat = this.getState().getIn(['chats', id]);
      if (!chat) {
        traceError(`getSomeChatsMap: chatId=${id}`);
        return acc;
      }
      return acc.set(id, chat); // 'set' зберігає Record
    }, Map());
  }

  getTopicChats(ctxId, ctxType) {
    trace(`getTopicChats: ctxId=${ctxId}, ctxType=${ctxType}`);
    return this.getState().get('chats')
      .filter(chat => {
        return !!chat.getIn(['contexts', ctxId + ctxType]) && chat.type === SCTX_CHAT;
      })
      .toList()
      .sort((a, b) => { return a.id > b.id ? -1 : 1; }); // Chat order: старі чати нижче
  }

  getDirectChatWithUser(userId) {
    trace(`getDirectChatWithUser: userId=${userId}`);
    return this.getState().get('chats')
      .filter(chat => {
        return !!chat.getIn(['contexts', userId + USER_CTX]) && chat.type === UCTX_CHAT;
      })
      .toList()
      .find(chat => chat.memberIds.contains(userId));
  }

  // Attn:
  // - - - - -
  // 1) для показу новостворених контекстів в пустому фіді курсор ще не визначений, тому він може
  //    бути дефолтним чи порожнім і перевірка матиме вигляд: (cursor || contextCursor === '' || cursor === DEFAULT_TSN12_CURSOR) !!!
  // 2) відбираємо чати ДО курсора = це щоб не збити процедуру пагінації !!!
  // 3) ...але БЕЗУМОВНО показуємо усі контексти з непрочитаними повідомленнями !!!
  // 4) назва stapleCursor не зовсім добра, але й chatCursor поки не хотів би ставити !!!
  // - - - - -
  getMyTopicStaplesFeed() {
    trace(`getMyTopicStaplesFeed`);
    const {
      contextDatas,
      contextCursor = DEFAULT_TSN12_CURSOR,
      areContextsLoaded} = this.getState().getIn(['contextFeeds', MY_TOPIC_CONTEXTS_PREFIX + STAPLE_CTX]) || ContextFeed();
    const contextIds = (contextCursor || contextCursor === '' || contextCursor === DEFAULT_TSN12_CURSOR) && contextDatas ? // sic!: 1), b) !!!
      contextDatas
        .reduce((acc, ctxData, chatId) => {
          const contextId = ctxData.get(CONTEXT_ID_FLD);
          const hasUnreadMessages = !!ctxData.get(HAS_UNREAD_MESSAGES_FLD);
          return hasUnreadMessages || chatId >= contextCursor ?
            acc.push({[CHAT_ID_FLD]:chatId, [CONTEXT_ID_FLD]:contextId, [HAS_UNREAD_MESSAGES_FLD]:hasUnreadMessages}) :
            acc;
        }, List())
        .sort((a, b) => {
          if (!a[HAS_UNREAD_MESSAGES_FLD] &&  b[HAS_UNREAD_MESSAGES_FLD]) { return  1 } // чат b з непрочитаними = вище
          if ( a[HAS_UNREAD_MESSAGES_FLD] && !b[HAS_UNREAD_MESSAGES_FLD]) { return -1 } // чат a з непрочитаними = вище
          return a[CHAT_ID_FLD] > b[CHAT_ID_FLD] ? -1 : 1; // чати однакові в сенсі непрочитаних, тому новіший чат = вище
        })
        .map(zsData => zsData[CONTEXT_ID_FLD]) :
      List();
    return {stapleIds:contextIds, stapleCursor:contextCursor, areStaplesLoaded:areContextsLoaded};
  }

  // Attn:
  // - - - - -
  // 1) для показу новостворених контекстів в пустому фіді курсор ще не визначений, тому він може
  //    бути дефолтним чи порожнім і перевірка матиме вигляд: (cursor || contextCursor === '' || cursor === DEFAULT_TSN12_CURSOR) !!!
  // 2) відбираємо чати ДО курсора = це щоб не збити процедуру пагінації !!!
  // 3) ...але БЕЗУМОВНО показуємо усі контексти з непрочитаними повідомленнями !!!
  // 4) назва stapleCursor не зовсім добра, але й chatCursor поки не хотів би ставити !!!
  // - - - - -
  getUserTopicStaplesFeed(userId) {
    trace(`getUserTopicStaplesFeed`);
    const {
      contextDatas,
      contextCursor = DEFAULT_TSN12_CURSOR,
      areContextsLoaded} = this.getState().getIn(['contextFeeds', USER_TOPIC_CONTEXTS_PREFIX + userId + STAPLE_CTX]) || ContextFeed();
    const contextIds = (contextCursor || contextCursor === '' || contextCursor === DEFAULT_TSN12_CURSOR) && contextDatas ? // sic!: 1), b) !!!
      contextDatas
        .reduce((acc, ctxData, chatId) => {
          const contextId = ctxData.get(CONTEXT_ID_FLD);
          const hasUnreadMessages = !!ctxData.get(HAS_UNREAD_MESSAGES_FLD);
          return hasUnreadMessages || chatId >= contextCursor ?
            acc.push({[CHAT_ID_FLD]:chatId, [CONTEXT_ID_FLD]:contextId, [HAS_UNREAD_MESSAGES_FLD]:hasUnreadMessages}) :
            acc;
        }, List())
        .sort((a, b) => {
          if (!a[HAS_UNREAD_MESSAGES_FLD] &&  b[HAS_UNREAD_MESSAGES_FLD]) { return  1 } // чат b з непрочитаними = вище
          if ( a[HAS_UNREAD_MESSAGES_FLD] && !b[HAS_UNREAD_MESSAGES_FLD]) { return -1 } // чат a з непрочитаними = вище
          return a[CHAT_ID_FLD] > b[CHAT_ID_FLD] ? -1 : 1; // чати однакові в сенсі непрочитаних, тому новіший чат = вище
        })
        .map(zsData => zsData[CONTEXT_ID_FLD]) :
      List();
    return {stapleIds:contextIds, stapleCursor:contextCursor, areStaplesLoaded:areContextsLoaded};
  }

  getSideTabMode() {
    trace(`getSideTabMode`);
    return this.getState().get('sideTabMode');
  }

  getSideOptions() {
    const tabMode = this.getState().get('sideTabMode');
    const ctxId = this.getState().get('sideCtxId');
    const ctxType = this.getState().get('sideCtxType');
    if (ctxId && ctxType) {
      const chatId = this.getState().getIn(['sideOptions', ctxId + ctxType, 'chatId']);
      const ctxOwnerId = this.getState().getIn(['sideOptions', ctxId + ctxType, 'ctxOwnerId']);
      trace(`getSideOptions: tabMode=${tabMode}, ctxId=${ctxId}, ctxType=${ctxType}, ctxOwnerId=${ctxOwnerId}`);
      return {tabMode, ctxId, ctxType, ctxOwnerId, chatId};
    }
    trace(`getSideOptions: tabMode=${tabMode}`);
    return {tabMode};
  }

  // getMySctxChatsCursor() {
  //   trace(`getMySctxChatsCursor`);
  //   return this.getState().get('mySctxChatsCursor');
  // }

  getObservedIds() {
    trace(`getObservedIds`);
    return this.getState().get('observedMessageIds');
  }

  getUnreadIds() {
    trace(`getUnreadIds`);
    return this.getState().get('unreadMessages').reduce((acc, unreadMsg) => {
      return acc.add(unreadMsg.id)
    }, Set());
  }

  getUnreadCountsByUser() {
    trace(`getUnreadCountsByUser`);
    return this.getState().get('unreadMessages').reduce((acc, unreadMsg) => {
      const {chatType, authorId} = unreadMsg;
      return chatType === USER_CTX && authorId ?
        acc.setIn([authorId], (acc.getIn([authorId]) || 0) + 1) :
        acc;
    }, Map());
  }

  getUnreadCountsByChat() {
    trace(`getUnreadCountsByChat`);
    return this.getState().get('unreadMessages').reduce((acc, unreadMsg) => {
      const {chatId} = unreadMsg;
      return chatId ? acc.setIn([chatId], (acc.getIn([chatId]) || 0) + 1) : acc;
    }, Map());
  }

  getUnreadCountsByCtxType(selectedCtxType) {
    trace(`getUnreadCountsByCtxType`);
    // ...1) розрахунок для кожного чату
    const countsByChat = this.getUnreadCountsByChat();
    // ...2) розрахунок для кожного контексту
    return countsByChat.reduce((countsAcc, chatCount, chatId) => {
      const chatCtxs = this.getState().getIn(['chats', chatId, 'contexts']) || Map();
      return chatCtxs.reduce((accLocal, currContext) => {
        const {ctxId, ctxType} = currContext;
        const ctxCount = accLocal.get(ctxId + ctxType) || 0;
        return ctxType === selectedCtxType ? accLocal.set(ctxId + ctxType, ctxCount + chatCount) : accLocal;
      }, countsAcc);
    }, Map());
  }

  getUnreadCountsByChatType(selectedCtxType) {
    trace(`getUnreadCountsByChatType`);
    // ...розрахунок для кожного чату
    const countsByChat = this.getUnreadCountsByChat();
    // ...розрахунок для чатів певного типу
    return countsByChat.reduce((countsAcc, chatCount, chatId) => {
      const chatType = this.getState().getIn(['chats', chatId, 'type']) || undefined;
      return chatType === selectedCtxType ? countsAcc.set(chatId, chatCount) : countsAcc;
    }, Map());
  }

  getTopicUnreadCount() {
    trace(`getTopicUnreadCount`);
    // ...розрахунок для кожного чату
    const countsByChat = this.getUnreadCountsByChat();
    // ...розрахунок для чатів певного типу
    const count = countsByChat.reduce((countsAcc, chatCount, chatId) => {
      return this.getState().getIn(['chats', chatId, 'type']) === SCTX_CHAT ?
        countsAcc + chatCount :
        countsAcc;
    }, 0);
    return count > 0 ? count < 100 ? '' + count : '99+' : ''; // sic!: string !!!
  }

  getCommunityUnreadCount() {
    trace(`getCommunityUnreadCount`);
    // ...розрахунок для кожного чату
    const countsByChat = this.getUnreadCountsByChat();
    // ...розрахунок для чатів певного типу
    const count = countsByChat.reduce((countsAcc, chatCount, chatId) => {
      return this.getState().getIn(['chats', chatId, 'type']) === UCTX_CHAT ?
        countsAcc + chatCount :
        countsAcc;
    }, 0);
    return count > 0 ? count < 100 ? '' + count : '99+' : ''; // sic!: string !!!
  }

  // - - - reducers:

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [MY_CONTEXTS_WERE_FETCHED_ACTION] = (state, {payload}) => {
    const {contextType, contextDatas:newContextDatas, contextCursor, areContextsLoaded} = payload;
    if (!newContextDatas) {
      return state;
    }
    const {contextDatas:prevContextDatas} = state.getIn(['contextFeeds', MY_TOPIC_CONTEXTS_PREFIX + contextType]) || new ContextFeed();
    const nextContextData = newContextDatas.reduce((acc, {[ID_FLD]:ctxId, [CHAT_ID_FLD]:chatId, [LAST_MESSAGE_ID_FLD]:lastMessageId}) => {
      const nextContext = {[CONTEXT_ID_FLD]:ctxId, [LAST_MESSAGE_ID_FLD]:lastMessageId};
      return acc.has(chatId) ? acc.update(chatId, (ctx) => ctx.merge(nextContext)) : acc.merge({[chatId]: nextContext});
    }, prevContextDatas);
    return mergeContextFeed(state, MY_TOPIC_CONTEXTS_PREFIX + contextType, {contextCursor, areContextsLoaded, contextDatas:nextContextData});
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [USER_CONTEXTS_WERE_FETCHED_ACTION] = (state, {payload}) => {
    const {userId, contextType, contextDatas:newContextDatas, contextCursor, areContextsLoaded} = payload;
    if (!newContextDatas) {
      return state;
    }
    const {contextDatas:prevContextDatas} = state.getIn(['contextFeeds', USER_TOPIC_CONTEXTS_PREFIX + userId + contextType]) || new ContextFeed();
    const nextContextData = newContextDatas.reduce((acc, {[ID_FLD]:ctxId, [CHAT_ID_FLD]:chatId, [LAST_MESSAGE_ID_FLD]:lastMessageId}) => {
      const nextContext = {[CONTEXT_ID_FLD]:ctxId, [LAST_MESSAGE_ID_FLD]:lastMessageId};
      return acc.has(chatId) ? acc.update(chatId, (ctx) => ctx.merge(nextContext)) : acc.merge({[chatId]: nextContext});
    }, prevContextDatas);
    return mergeContextFeed(state, USER_TOPIC_CONTEXTS_PREFIX + userId + contextType, {contextCursor, areContextsLoaded, contextDatas:nextContextData});
  }

  // // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // [MY_SCTX_CHATS_WERE_FETCHED_ACTION] = (state, {payload}) => {
  //   const {chats, mySctxChatsCursor, areMySctxChatsLoaded} = payload;
  //   if (!chats) {
  //     return state;
  //   }
  //   const nextState = areMySctxChatsLoaded ?
  //     chats.reduce(mergeChat, state).set('areMySctxChatsLoaded', true) :
  //     chats.reduce(mergeChat, state);
  //   return nextState.set('mySctxChatsCursor', mySctxChatsCursor);
  // }

  // // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // [USER_SCTX_CHATS_WERE_FETCHED_ACTION] = (state, {payload}) => {
  //   const {chats} = payload;
  //   if (!chats) {
  //     return state;
  //   }
  //   return chats.reduce(mergeChat, state);
  // }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [STAPLE_SCTX_CHATS_WERE_FETCHED_ACTION] = (state, {payload}) => {
    const {chats} = payload;
    return chats ?
      chats.reduce(mergeChat, state) :
      state;
  }

  // ToDo: COLLECTION_CTX !!!
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // [COLLECTION_CCTX_CHATS_WERE_FETCHED_ACTION] = (state, {payload}) => {
  //   const {chats} = payload;
  //   return chats ?
  //     chats.reduce(mergeChat, state) :
  //     state;
  // }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [USER_UCTX_CHATS_WERE_FETCHED_ACTION] = (state, {payload}) => {
    const {chats} = payload;
    return chats ?
      chats.reduce(mergeChat, state) :
      state;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [CHATS_WERE_FETCHED_ACTION] = (state, {payload}) => {
    const {chats} = payload;
    return chats ?
      chats.reduce(mergeChat, state) :
      state;
  }

  //  Attn: 1) якщо чат існує, то messages буде порожнім --> НЕ виставляємо флаг areMessagesLoaded !!!
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [CHAT_WAS_CREATED_ACTION] = (state, {payload}) => {
    const {chat, messages} = payload;
    if (!chat) {
      return state;
    }
    // ...create chat
    const nextState1 = mergeChat(state, chat);
    // ...update chat messages & set all messages loaded
    const {[ID_FLD]:chatId} = chat;
    const nextState2 = messages ? messages.reduce(mergeChatMessage, nextState1).setIn(['chats', chatId, 'areMessagesLoaded'], true) : nextState1;
    // ...set OneChatTab as active tab
    const ctxId = nextState2.get('sideCtxId');
    const ctxType = nextState2.get('sideCtxType');
    if (ctxId && ctxType && chatId) {
      const nextSideOption = (nextState2.getIn(['sideOptions', ctxId + ctxType]) || SideOption()).merge({ctxId, ctxType, chatId});
      return nextState2
        .mergeIn(['sideOptions', ctxId + ctxType], nextSideOption)
        .mergeIn(['sideTabMode'], ctxType === USER_CTX ? CHAT_TAB : COMMUNITY_TAB); // sic!: визначає на яку табу переходимо після створення !!!
    }
    return nextState2;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [SCTX_CHATS_WERE_CREATED_ACTION] = (state, {payload}) => {
    const {chats} = payload;
    return chats ?
      chats.reduce(mergeChat, state) :
      state;
  }

  // Attn: 1) chat у форматі {{ CHAT_BASICS_PLUS }} не містить інфу про останній меседж, тому обʼєднуємо з даними із message!!!
  // Attn: 2) признак HAS_UNREAD_MESSAGES_FLD гарантує внесення змін у таблицю контекстів !!!
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [CHAT_WAS_UPDATED_ACTION] = (state, {payload}) => {
    const {chat:chatBasics, message} = payload;
    const {
      [ID_FLD]:msgId,
      [TYPE_FLD]:msgType,
      [BODY_FLD]:msgBody,
      [ATTACHMENTS_FLD]:msgAttachments} = message || {};
    const chat = Object.assign({}, chatBasics, { // attn: 1)
      [LAST_MESSAGE_ID_FLD]:msgId,
      [LAST_MESSAGE_TYPE_FLD]:msgType,
      [LAST_MESSAGE_BODY_FLD]:msgBody,
      [LAST_MESSAGE_ATTACHMENTS_FLD]:msgAttachments,
      [HAS_UNREAD_MESSAGES_FLD]:true}); // attn: 2) !!!
    return mergeChatMessage(mergeChat(state, chat), message);
  }

  // Attn: 1) chat у форматі {{ CHAT_BASICS_PLUS }} не містить інфу про останній меседж, тому обʼєднуємо з даними із message!!!
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [CHAT_WAS_CLOSED_ACTION] = (state, {payload}) => {
    const {chat:chatBasics, message} = payload;
    const {
      [ID_FLD]:msgId,
      [TYPE_FLD]:msgType,
      [BODY_FLD]:msgBody,
      [ATTACHMENTS_FLD]:msgAttachments} = message || {};
    const chat = Object.assign({}, chatBasics, { // attn: 1)
      [LAST_MESSAGE_ID_FLD]:msgId,
      [LAST_MESSAGE_TYPE_FLD]:msgType,
      [LAST_MESSAGE_BODY_FLD]:msgBody,
      [LAST_MESSAGE_ATTACHMENTS_FLD]:msgAttachments});
    return mergeChatMessage(mergeChat(state, chat), message);
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [CHAT_WAS_DELETED_ACTION] = (state, {payload}) => {
    const {chatId, ctxId, ctxType} = payload;
    if (!chatId) {
      return state;
    }
    // ...видаляємо контекст чату
    const contextDatas = state.getIn(['contextFeeds', MY_TOPIC_CONTEXTS_PREFIX + ctxType, 'contextDatas']);
    const nextState = contextDatas ? state.setIn(['contextFeeds', MY_TOPIC_CONTEXTS_PREFIX + ctxType, 'contextDatas'], contextDatas.delete(ctxId)) : state;
    // ...видаляємо чат
    const nextState1 = nextState.deleteIn(['chats', chatId]);
    // ...видаляємо непрочитані повідомлення, що залишились і належать до видаленого чату
    const nextUnreadMessages = nextState1.get('unreadMessages').filter(unreadMsg => unreadMsg.chatId !== chatId);
    return nextState1.set('unreadMessages', nextUnreadMessages);
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [CHAT_MESSAGES_WERE_FETCHED_ACTION] = (state, {payload}) => {
    const {chatId, messages, chatMessageCursor, areChatMessagesLoaded} = payload;
    if (!messages) {
      return state;
    }
    const nextState = areChatMessagesLoaded ?
      messages.reduce(mergeChatMessage, state).setIn(['chats', chatId, 'areMessagesLoaded'], true) :
      messages.reduce(mergeChatMessage, state);
    const nextMessages = nextState.getIn(['chats', chatId, 'messages'])
      .sort((a, b) => { return a.id < b.id ? -1 : 1; });
    return nextState
      .setIn(['chats', chatId, 'messages'], nextMessages)
      .setIn(['chats', chatId, 'messageCursor'], chatMessageCursor);
  }

  // Attn: 1) параметр chat присутній лише для меседжів зміни статусу чату !!!
  //       2) тому ідентифікатор чату chatId беремо з меседжу !!!
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [CHAT_MESSAGE_WAS_CREATED_ACTION] = (state, {payload}) => {
    const {chat, message} = payload;
    if (!message) {
      return state;
    }
    const {[STATUS_FLD]:chatStatus} = chat || {}; // attn: 1)
    const {
      [ID_FLD]:msgId,
      [CHAT_ID_FLD]:chatId, // attn: 2)
      [TYPE_FLD]:msgType,
      [BODY_FLD]:msgBody,
      [ATTACHMENTS_FLD]:msgAttachments} = message || {};
    return mergeChatMessage(
      mergeChat(state, {
        [ID_FLD]:chatId,
        [STATUS_FLD]:chatStatus,
        [LAST_MESSAGE_ID_FLD]:msgId,
        [LAST_MESSAGE_TYPE_FLD]:msgType,
        [LAST_MESSAGE_BODY_FLD]:msgBody,
        [LAST_MESSAGE_ATTACHMENTS_FLD]:msgAttachments}),
      message);
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [CHAT_MESSAGE_WAS_RECEIVED_ACTION] = (state, {payload}) => {
    const {[MESSAGE_FLD]:message, [CHAT_TYPE_FLD]:chatType, isPictureReloadRequired} = payload;
    const {
      [ID_FLD]:msgId,
      [AUTHOR_ID_FLD]:authorId,
      [CHAT_ID_FLD]:chatId,
      [TYPE_FLD]:msgType,
      [BODY_FLD]:msgBody,
      [ATTACHMENTS_FLD]:msgAttachments} = message || {};
    if (!msgId || !chatId || !authorId) {
      return state;
    }
    if (isPictureReloadRequired) {
      message.isPictureReloadRequired = true; // sic!: a) = оновлення превьюшок
    }
    const myId = this.getState().get('myId');
    const isChatLoaded = !!this.getState().getIn(['chats', chatId]);
    // ...в непрочитані додаємо ТІЛЬКИ чужі повідомлення, що не потребують перевантаження превьюшок
    const nextState = myId !== authorId && !isPictureReloadRequired ?
      mergeUnreadMessage(state, {[MESSAGE_ID_FLD]:msgId, [CHAT_ID_FLD]:chatId, [CHAT_TYPE_FLD]:chatType, [AUTHOR_ID_FLD]:authorId}) :
      state;
    if (isChatLoaded) {
      return mergeChatMessage(
        mergeChat(nextState, {
          [ID_FLD]:chatId,
          [LAST_MESSAGE_ID_FLD]:msgId,
          [LAST_MESSAGE_TYPE_FLD]:msgType,
          [LAST_MESSAGE_BODY_FLD]:msgBody,
          [LAST_MESSAGE_ATTACHMENTS_FLD]:msgAttachments,
          [HAS_UNREAD_MESSAGES_FLD]:true}), message);
    } else {
      fetchChats([chatId]); // xtra-fetch: Chats
      return mergeChatMessage(mergeChat(nextState, {[ID_FLD]:chatId}), message);
    }
  }

  // sic!: параметри повинні співпадати з NTF:ch.msg~
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [CHAT_MESSAGE_WAS_UPDATED_ACTION] = (state, {payload}) => {
    const {[MESSAGE_FLD]:message} = payload;
    return message ?
      mergeChatMessage(state, message) :
      state;
  }

  // sic!: параметри повинні співпадати з NTF:ch.msg-
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [CHAT_MESSAGE_WAS_DELETED_ACTION] = (state, {payload}) => {
    const {[MESSAGE_ID_FLD]:msgId, [CHAT_ID_FLD]:chatId} = payload;
    if (!msgId || !chatId) {
      return state;
    }
    // ...delete message from unread queue
    const nextState = state.deleteIn(['unreadMessages', msgId]);
    // ...delete message from chat
    const prevMessages = state.getIn(['chats', chatId, 'messages']);
    const index = prevMessages.findIndex(msg => msg.id === msgId);
    return index >= 0 ?
      nextState.setIn(['chats', chatId, 'messages'], prevMessages.delete(index)) :
      nextState;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [CHAT_MESSAGE_WAS_READ_ACTION] = (state, {payload}) => {
    const {[MESSAGE_ID_FLD]:msgId, [CHAT_ID_FLD]:chatId} = payload;
    return msgId && chatId ?
      mergeChatMessage(state, {[ID_FLD]:msgId, [CHAT_ID_FLD]:chatId, [IS_READ_FLD]:true}) :
      state;
  }

  // Attn: 1) щоб показати усі чати з непрочитаними повідомленнями додаємо флаг HAS_UNREAD_MESSAGES_FLD !!!
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [UNREAD_MESSAGES_WERE_FETCHED_ACTION] = (state, {payload}) => {
    const {unreadMessages, chats:chats0} = payload;
    const chats = chats0.map(chat => ({ ...chat, [HAS_UNREAD_MESSAGES_FLD]:true })); // sic!: 1), b) !!!
    const nextState = chats ? chats.reduce(mergeChat, state) : state;
    return unreadMessages ?
      unreadMessages.reduce(mergeUnreadMessage, nextState) :
      nextState;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [UNREAD_MESSAGES_WERE_READ_ACTION] = (state, {payload}) => {
    const {readMessageIds} = payload;
    return readMessageIds ?
      readMessageIds.reduce((accState, currentId) => {
          const nextObservedIds = accState.get('observedMessageIds').delete(currentId);
          return accState.set('observedMessageIds', nextObservedIds);
        }, state) :
      state;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [UNREAD_MESSAGE_WAS_OBSERVED_ACTION] = (state, {payload}) => {
    const {[MESSAGE_ID_FLD]:msgId} = payload;
    return msgId ?
      state
        .deleteIn(['unreadMessages', msgId])
        .set('observedMessageIds', state.get('observedMessageIds').add(msgId)) :
      state;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [MY_FRIENDS_WERE_FETCHED_ACTION] = (state, {payload}) => {
    const {directUsers} = payload;
    // ...для юзерів що мають прямі чати в sideOptions зберігаємо ідентифікатори прямих чатів !!!
    const nextState = directUsers.reduce((accState, user) => {
      const {[ID_FLD]:userId, [DIRECT_CHAT_ID_FLD]:chatId} = user;
      return accState.mergeIn(['sideOptions', userId + USER_CTX], new SideOption({ctxId:userId, ctxType:USER_CTX, ctxOwnerId:userId, chatId:chatId}));
    }, state);
    // ...для юзерів повинні бути завантажені прямі чати !!!
    const missedChatIds = directUsers.reduce((acc, directChat) => {
      const {[DIRECT_CHAT_ID_FLD]:chatId} = directChat;
      return chatId && !this.isChatLoaded(chatId) ? acc.add(chatId) : acc;
    }, Set());
    if (missedChatIds.size > 0) {
      fetchChats(missedChatIds); // xtra-fetch: Chats
    }
    return nextState;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [STAPLE_WAS_CREATED_ACTION] = (state, {payload}) => {
    const {staple} = payload;
    if (!staple) {
      return state;
    }
    const {[ID_FLD]:ctxId, ctxType = STAPLE_CTX, [OWNER_ID_FLD]:ctxOwnerId} = staple;
    return state
      .mergeIn(['sideCtxId'], '')
      .mergeIn(['sideCtxType'], '')
      .mergeIn(['sideTabMode'], '') // sic!: початковий стан має бути '' для коректного activeTab!!!
      .mergeIn(['sideOptions', ctxId + ctxType], new SideOption({ctxId, ctxType, ctxOwnerId}));
  }

  // ToDo: якщо стейпл містить чати, то перерахувати відповідні контексти !!!
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // [STAPLE_WAS_CLONED_ACTION] = (state, {payload}) => {
  //   const {staple} = payload;
  //   return state;
  // }

  // ToDo: видалити стейпл з контекстів та перерахувати контексти чатів !!!
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // [STAPLES_WERE_DELETED_ACTION] = (state, {payload}) => {
  //   const {deletedIds, affectedCollectionIds} = payload;
  //   return state;
  // }

  // ToDo: видалити колекцію з контекстів та перерахувати контексти чатів !!!
  // // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // [COLLECTION_WAS_DELETED_ACTION] = (state, {payload}) => {
  //   const {collectionId} = payload;
  //   return state;
  // }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [SIDEBAR_CONTEXT_WAS_SUBSTITUTED_ACTION] = (state, {payload}) => {
    const {ctxId = '', ctxType = '', ctxOwnerId = ''} = payload;
    const prevCtxId = state.get('sideCtxId');
    const prevCtxType = state.get('sideCtxType');
    if (ctxId === prevCtxId && ctxType === prevCtxType) {
      return state;
    }
    const isContextFound = !!state.getIn(['sideOptions', ctxId + ctxType]);
    return isContextFound ?
      state.mergeIn(['sideCtxId'], ctxId).mergeIn(['sideCtxType'], ctxType) :
      state.mergeIn(['sideCtxId'], ctxId).mergeIn(['sideCtxType'], ctxType)
        .mergeIn(['sideOptions', ctxId + ctxType], new SideOption({ctxId, ctxType, ctxOwnerId}));
  }

  // Attn: якщо вказаний userId, то контекст чату задається "глобально" в контексті юзера;
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [SIDEBAR_CHAT_WAS_SUBSTITUTED_ACTION] = (state, {payload}) => {
    const {chatId = '', userId = ''} = payload;
    const ctxId = state.get('sideCtxId');
    const ctxType = state.get('sideCtxType');
    const prevChatId = state.getIn(['sideOptions', ctxId + ctxType, 'chatId']);
    // ...встановлюємо вказівники та опції для глобального контексту (якщо заданий userId)
    if (userId) {
      return state
        .setIn(['sideCtxId'], userId)
        .setIn(['sideCtxType'], USER_CTX)
        .setIn(['sideOptions', userId + USER_CTX], new SideOption({ctxId: userId, ctxType: USER_CTX, chatId: chatId}));
    }
    // ...встановлюємо поточний чат (порожнє значення chatId свідчить, що поточного чату не задано)
    return ctxId && ctxType && chatId !== prevChatId ?
      state.setIn(['sideOptions', ctxId + ctxType, 'chatId'], chatId) :
      state;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [SIDEBAR_TABMODE_WAS_SUBSTITUTED_ACTION] = (state, {payload}) => {
    const {tabMode} = payload;
    return tabMode ?
      state.mergeIn(['sideTabMode'], tabMode) :
      state;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [CONTACT_WAS_SELECTED_ACTION] = (state, {payload}) => {
    return state.mergeIn(['sideTabMode'], SELECTION_TAB);
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [CONTACTS_OF_GROUP_WERE_SELECTED_ACTION] = (state, {payload}) => {
    return state.mergeIn(['sideTabMode'], SELECTION_TAB);
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [STAPLE_WAS_SELECTED_ACTION] = (state, {payload}) => {
    return state.mergeIn(['sideTabMode'], SELECTION_TAB);
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [STAPLES_OF_COLLECTION_WERE_SELECTED_ACTION] = (state, {payload}) => {
    return state.mergeIn(['sideTabMode'], SELECTION_TAB);
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [MY_ACCOUNT_WAS_FETCHED_ACTION] = (state, {payload}) => {
    const {account} = payload;
    return account ?
      state.mergeIn(['myId'], account[ID_FLD]) :
      state;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  [LOGGED_OUT_ACTION] = (state, {payload}) => {
    return this.getInitialState();
  }

  reduce(state, action) {
    const reducer = this[action.type];
    if (reducer) {
      const nextState = reducer(state, action);
      trace(`reduce:`, action, nextState.toJS());
      return nextState;
    }
    return state;
  }
}

export default new ChatStore(Dispatcher);
