// -------------------------------------------------------------------------------------------------
//  elementTypes.js
//  - - - - - - - - - -
//  Staple element enumerated types.
// -------------------------------------------------------------------------------------------------

export const ADVERT_ELEMENT               = 'a';      // attn: це не зовсім тип контенту, але треба відображення ContentHolder
export const BOOKMARK_ELEMENT             = 'b';      //
export const EMBED_ELEMENT                = 'e';      //
export const IMAGE_ELEMENT                = 'i';      //
export const TEXTNOTE_ELEMENT             = 't';      //
export const DOCUMENT_ELEMENT             = 'd';      //
export const COLLECTION_ELEMENT           = 'c';      //
export const USER_ELEMENT                 = 'u';      //
export const LINK_ELEMENT                 = ',';      // same as: LINK_STAPLE !!!
export const SYSTEM_ELEMENT               = '#';      // same as: SYSTEM_STAPLE !!!
