// -------------------------------------------------------------------------------------------------
//  UserCollectionsContainer.js
//  - - - - - - - - - -
//  Контейнер стрічки певного типу (default, course, ...) колекцій юзера.
//
//  Attn:
//  - - - - -
//  - в цьому фіді показуємо ЛИШЕ колекції одного типу (принцип "незмішування");
//  - тип колекцій до відображення передаємо в параметрі collectionType;
//  - форма має ДВА варіанти виклику: з параметром userId та userSlug;
//  - форма може викликатись для залогіненого (користувач) або незалогіненого (гість) юзерів;
//  - для залогіненого юзера використовуємо wsock-набір API;
//  - для незалогіненого юзера використовуємо http-набір API;
//
//  Attn: 1)
//  - - - - -
//  Для ініціалізації на сайдбарі прямого чату з юзером і таби CHAT_TAB враховувати/робити таке:
//  - після перезавантаження сторінки на момент виконання componentDidMount() значення поля
//    user.directChatId встановлюється НЕ ВІДРАЗУ (стор не встигає обробити API getMyFriends());
//  - тому доводиться викликати setSidebar() із componentDidUpdate;
//  - isSetSidebarRepaired потрібен щоб викликати setSidebar() із componentDidUpdate лише ОДИН раз;
//  - щоб обійти помилку "Dispatch.dispatch(...): Cannot dispatch in the middle of a dispatch"
//    доводиться робити затримку через setTimeout ~50ms;
//
//  Attn: a)
//  - - - - -
//  - колекції різних типів не показуються ОДНОЧАСНО;
//  - тип колекцій, які буде показано/оброблено визначається за допомогою URL:
//    якщо /co/ --> DEFAULT_COLLECTION;
//    якщо /crs/ --> COURSE_COLLECTION;
//    якщо /cse/ --> SEQUENCE_COLLECTION;
//    якщо /crd/ --> RANDOM_COLLECTION;
//  - тип колекції передається в параметрі роутера collectionType;
//  - в залежності від отриманого collectionType визначається feedType та яке API буде застосовано;
// -------------------------------------------------------------------------------------------------
import React from 'react';
import Types from 'prop-types';
import {Container} from 'flux/utils';
import {is} from 'immutable';
import {DEFAULT_TSN12_CURSOR, DEFAULT_COLLECTION, COURSE_COLLECTION, SEQUENCE_COLLECTION, RANDOM_COLLECTION} from 'core/commonTypes';
import {USER_CTX, STAPLE_CTX, COLLECTION_CTX, ADVERT_CTX, ROOM_CTX} from 'core/communicationTypes';
import {COLLECTION_TAB, ACTION_TAB, CHAT_TAB, SELECTION_TAB, USER_COLLECTIONS_FEED, USER_COURSES_FEED} from 'core/uiTypes';
import {fetchUserCollections, fetchUserCollectionsAsGuest} from 'actions/CollectionActions';
import {setAllContactsUnselected} from 'actions/ContactActions';
import {setAllStaplesUnselected} from 'actions/StapleActions';
import {fetchUsers, fetchUsersAsGuest, fetchUsersBySlugs, fetchUsersBySlugsAsGuest} from 'actions/UserActions';
import {substituteSidebarContext, substituteSidebarChat, substituteSidebarTabMode} from 'actions/LayoutActions';
import {fetchSystemSubjects, fetchSystemSubjectsAsGuest} from 'actions/SystemActions';
import AccountStore from 'stores/AccountStore';
import CrmStore from 'stores/CrmStore';
import CollectionStore from 'stores/CollectionStore';
import StapleStore from 'stores/StapleStore';
import SystemStore from 'stores/SystemStore';
import UserStore from 'stores/UserStore';
import Loader from 'utils/ComponentLoader';

const UserCollections = Loader.create(() => import('./UserCollections'));
const isVerbose = DEBUG && true;
const prefix = '- - - UserCollectionsContainer';

function trace(msg, ...other) { if (isVerbose) { console.log(`${prefix}.${msg}`, ...other); }}
function traceWarn(msg, ...other) { if (isVerbose) { console.warn(`${prefix}.${msg}`, ...other); }}
function traceError(msg, ...other) { console.error(`${prefix}.${msg}`, ...other); }

let isSetSidebarRepaired = false;         // чи викликали функцію setSidebar?

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
class UserCollectionsContainer extends React.Component {
  static contextTypes = {
    router: Types.object.isRequired,
  }

  static getStores() {
    trace(`getStores`);
    return [AccountStore, CollectionStore, CrmStore, StapleStore, SystemStore, UserStore];
  }

  static calculateState(prevState, props) {
    trace(`calculateState`);
    const {collectionType} = props;
    const {userId:uid, userSlug} = props.match.params;
    const feedType = collectionType === COURSE_COLLECTION ? USER_COURSES_FEED : USER_COLLECTIONS_FEED; // attn: a) !!!
    const {[feedType]:viewMode} = AccountStore.getUISettings();
    const isLoggedIn = AccountStore.isLoggedIn();
    const myAccountType = AccountStore.getMyAccountType();
    const myId = AccountStore.getMyId();
    const user = uid ? UserStore.getUser(uid) : userSlug ? UserStore.getUserBySlug(userSlug) : null;
    const {id:userId} = user || {};
    const areEmptyVisible = !!(myId && myId === userId); // видимі: свої = всі, чужі = тільки наповнені
    const collections = CollectionStore.getUserCollectionsByType(userId, collectionType, areEmptyVisible);
    const owners = UserStore.getSomeUsersMap(collections.map(c => c.ownerId).toSet().add(myId));
    const allSubjects = SystemStore.getSubjectList();
    const fsrCount = UserStore.getFSRequestCount();
    const {groupsQty, contactsQty} = CrmStore.getQts();
    const collectionCursor = userId ? CollectionStore.getUserCollectionCursorByType(userId, collectionType) : DEFAULT_TSN12_CURSOR;
    const areCollectionsLoaded = userId ? CollectionStore.areUserCollectionsByTypeLoaded(userId, collectionType) : false;
    const hasAnySelected = myId && user && user.id === myId ? StapleStore.hasSelectedStaples() || CrmStore.hasSelectedContacts() : false;
    return {
      isLoggedIn, collectionType, myAccountType, myId, user, collections, owners, allSubjects, feedType,
      viewMode, fsrCount, groupsQty, contactsQty, collectionCursor, areCollectionsLoaded, hasAnySelected};
  }

  shouldComponentUpdate(nextProps, nextState) {
    const result = this.state.isLoggedIn !== nextState.isLoggedIn
      || this.state.myAccountType !== nextState.myAccountType
      || this.state.myId !== nextState.myId
      || this.state.viewMode !== nextState.viewMode
      || this.state.fsrCount !== nextState.fsrCount
      || this.state.groupsQty !== nextState.groupsQty
      || this.state.contactsQty !== nextState.contactsQty
      || this.state.collectionCursor !== nextState.collectionCursor
      || this.state.areCollectionsLoaded !== nextState.areCollectionsLoaded
      || this.state.hasAnySelected !== nextState.hasAnySelected
      || this.state.collectionType !== nextState.collectionType
      || this.state.feedType !== nextState.feedType
      || this.state.allSubjects.size !== nextState.allSubjects.size
      || !is(this.state.collections, nextState.collections)
      || !is(this.state.owners, nextState.owners)
      || !is(this.state.user, nextState.user);
    trace(`shouldComponentUpdate: ${result}`);
    return result;
  }

  async componentDidMount() {
    trace(`componentDidMount`);
    const {userId, userSlug} = this.props.match.params;
    const {isLoggedIn} = this.state;
    if (userId) {
      if (!UserStore.isUserLoaded(userId)) {
        isLoggedIn ?
          await fetchUsers([userId]) :
          await fetchUsersAsGuest([userId]);
      }
    } else if (userSlug) {
      if (!UserStore.isUserLoadedBySlug(userSlug)) {
        isLoggedIn ?
          await fetchUsersBySlugs([userSlug]) :
          await fetchUsersBySlugsAsGuest([userSlug]);
      }
    }
    if (!SystemStore.areSubjectsLoaded()) {
      isLoggedIn ?
        await fetchSystemSubjects() :
        await fetchSystemSubjectsAsGuest();
    }
    this.setSidebar(); // attn: 1)
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    trace(`UNSAFE_componentWillReceiveProps`);
    const {isLoggedIn} = this.state;
    if (!SystemStore.areSubjectsLoaded()) {
      isLoggedIn ?
        await fetchSystemSubjects() :
        await fetchSystemSubjectsAsGuest();
    }
  }

  // CTX: Attn: 1)
  async componentDidUpdate(prevProps, prevState) {
    const {user:prevUser} = prevState;
    const {isLoggedIn, user} = this.state;
    if (isLoggedIn) {
      const {directChatId:prevDirectChatId} = prevUser || {};
      const {directChatId} = user || {};
      if (prevDirectChatId === '' && directChatId) {
        trace(`componentDidUpdate: directChatId =`, directChatId);
        if (!isSetSidebarRepaired) {
          setTimeout(this.setSidebar, 50);
          isSetSidebarRepaired = true;
        }
      }
    }
  }

  // CTX: Attn: 1)
  setSidebar = async () => {
    trace(`setSidebar`);
    const {isLoggedIn, myId, user, hasAnySelected} = this.state;
    if (isLoggedIn) {
      if (user && user.id !== myId) {
        await substituteSidebarContext({ctxId: user.id, ctxType: USER_CTX, ctxOwnerId: user.id});
        if (user.directChatId) {
          await substituteSidebarTabMode(CHAT_TAB, true);
          await substituteSidebarChat({chatId: user.directChatId});
        } else {
          await substituteSidebarTabMode(ACTION_TAB);
          await substituteSidebarChat({chatId: ''});
        }
      }
      if (user && user.id === myId) {
        await substituteSidebarTabMode(!hasAnySelected ? COLLECTION_TAB : SELECTION_TAB);
        await substituteSidebarContext({ctxId: '', ctxType: '', ctxOwnerId: ''});
        await substituteSidebarChat({chatId: ''});
      }
    }
  }

  handleUnselectAll = () => {
    trace(`handleUnselectAll`);
    setAllContactsUnselected();
    setAllStaplesUnselected();
  }

  isFetchingUserCollections = false;

  fetchUserCollectionsPage = async () => {
    trace(`fetchUserCollectionsPage(1): isFetching=${this.isFetchingUserCollections}`);
    const {isLoggedIn, collectionType, user, collectionCursor = DEFAULT_TSN12_CURSOR} = this.state;
    const {id:userId} = user || {};
    if (userId && !this.isFetchingUserCollections) {
      trace(`fetchUserCollectionsPage(2): userId=${userId}, cursor='${collectionCursor}'`);
      this.isFetchingUserCollections = true;
      isLoggedIn ?
        await fetchUserCollections({userId: userId, type: collectionType, cursor: collectionCursor, limit: COLLECTIONS_FEED_FETCH_LIMIT}) :
        await fetchUserCollectionsAsGuest({userId: userId, type: collectionType, cursor: collectionCursor, limit: COLLECTIONS_FEED_FETCH_LIMIT});
      this.isFetchingUserCollections = false;
    }
  }

  render() {
    trace(`render`);
    const {
      isLoggedIn,
      collectionType,
      myAccountType,
      myId,
      user,
      owners,
      feedType,
      viewMode,
      fsrCount,
      groupsQty,
      contactsQty,
      collections,
      allSubjects,
      areCollectionsLoaded,
      hasAnySelected} = this.state;
    return (
      <UserCollections
        isLoggedIn={isLoggedIn}
        collectionType={collectionType}
        myAccountType={myAccountType}
        myId={myId}
        user={user}
        owners={owners}
        feedType={feedType}
        viewMode={viewMode}
        fsrCount={fsrCount}
        groupsQty={groupsQty}
        contactsQty={contactsQty}
        collections={collections}
        allSubjects={allSubjects}
        areCollectionsLoaded={areCollectionsLoaded}
        onPageTrigger={this.fetchUserCollectionsPage}
        onUnselectAll={hasAnySelected ? this.handleUnselectAll : null}
      />
    );
  }
}

export default Container.create(UserCollectionsContainer, {withProps: true, pure: false});
