// -------------------------------------------------------------------------------------------------
//  SelectField.js
//  - - - - - - - - - -
//  v2.01: 2018-10-03
//  v5.01: 2021-11-01
//
//  Поле для вводу значень із списку (або з можливістю додавати нові).
//  Обгортка для компоненти react-select.
//  
//  При створенні нових елементів приватність задається символом в першій позиції значення.
//  Символи '+', '*' задають приватний елемент (isPublic := false).
//  Символи '-' задають публічний елемент (isPublic := true).
//  Якщо символи не вказані, то елемент вважається публічним (isPublic := true);
//
//  Формат списку обʼєктів {{ CO-SELECT2 }}:
//  [
//    { id: 'alK9A6srElz01', value: 'ex-one', label: 'Ex One', isPublic: true, type: 'c', __isNew__: false },
//    { id: '+', value: 'ex-two', label: 'Ex Two', isPublic: false, type: 'c', __isNew__: true },
//    ...
//  ]
//
//  Attn:
//  - - - - -
//  - поки що структура типів gr/em однакова, але в подальшому можлива дивергенція;
//  - значення ідентифікатора містить поле 'id' (напр: 'alK9A6srElz01');
//  - поле 'value' містить транслітеровану стрічку у форматі sline/slug (напр: this-is-the-example);
//  - наявність символу '+' в полі 'value' свідчить про те, що цей обʼєкт був доданий до списку;
//  - privacy: undefined inSelectField means public !!!
//  - компонента SelectField використовує пропс 'choices', тоді як початкова 'options';
//  - classNamePrefix required to prevent library warning;
//  - для collections потрібен порядок сортування обернений до бажаного (API розвертає список);
//  - сортування текстових стрічок повинно проводитись з врахуванням локалі (.localeCompare);
//
//  Replaceable components:
//  - - - - - - - - - - - -
//  - Control
//  - IndicatorsContainer
//  - IndicatorSeparator
//  - DropdownIndicator
//  - LoadingIndicator
//  - ClearIndicator
//  - Group
//  - GroupHeading
//  - Input (*)
//  - Menu
//  - MenuList
//  - LoadingMessage
//  - NoOptionsMessage
//  - MultiValue
//  - MultiValueContainer
//  - MultiValueLabel
//  - MultiValueRemove
//  - Option
//  - Placeholder
//  - SelectContainer
//  - SingleValue
//  - ValueContainer
//
//  Docs:
//  - - - - -
//  https://react-select.com/props#creatable-props
//  https://react-select.com/props#select-props
//  https://github.com/JedWatson/react-select
// -------------------------------------------------------------------------------------------------
import React from 'react';
import Types from 'prop-types';
import classnames from 'classnames';
import {t} from 'ttag';
import Select from 'react-select';
import Creatable from 'react-select/creatable';
import {ID_FLD, TYPE_FLD, NAME_FLD, VALUE_FLD, LABEL_FLD, IS_PUBLIC_FLD} from 'core/apiFields';
import {
  DEFAULT_COLLECTION,
  COURSE_COLLECTION,
  SEQUENCE_COLLECTION,
  RANDOM_COLLECTION,
  STAR_COLLECTION} from 'core/commonTypes';
import Icon from 'components/UI/icons/Icon';
import {slugify, cleanifyGroupName, cleanifyEmail, cleanifyCollectionName} from 'utils/converters';
import {isEmailValid} from 'utils/validations';
import styles from './SelectField.scss';

export const GROUP_CHOICES                  = 'g';
export const EMAIL_CHOICES                  = '@';
export const ADDRESS_CHOICES                = 'd';
export const CATEGORY_CHOICES               = '0';
export const SUBJECT_CHOICES                = 'j';
export const COLLECTION_CHOICES             = 'c';
export const OTHER_CHOICES                  = '*';

export const DEFAULT_TAGS_PLUS              = {};

// =================================================================================================
//  (FMT) Groups/Emails/Addresses Format Converters
// =================================================================================================

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// 	Converts groups/emails/addresses: from GR-SELECT2 into GR-TAGS-PLUS format,
// 	                                  from EM-SELECT2 into EM-TAGS-PLUS format,
// 	                                  from AD-SELECT2 into AD-TAGS-PLUS format.
//
//  {{ GR-SELECT2 }}:
//  [
//    {
//      id: 'alK9A6srElz01',                // id || '+'
//      value: 'name1',                     // slugified group/email/address name
//      label: 'Name1',                     // group/email/address name
//      isPublic: true,                     // <-- is always TRUE (бо для груп не використовується) !!!
//      __isNew__: true || false,           // is an option just created by user?
//    },
//    ...
//  ]
//
//  {{ GR-TAGS-PLUS }}:
//  {
//    "id1": {                              // id
//        l: "Label1"                       // назва групи/мейл-адреса/адреси
//      },
//    "+": {                                // '+' свідчить, що потрібно створити НОВУ групу/мейл/адресу
//        l: "Label2"                       // назва для створення нової групи/мейла/адреси
//      },
//    ...
//  }
//
export function toGrTagsPlus(grSelect2) {
  if (grSelect2 && grSelect2.length > 0) {
    return grSelect2.reduce((acc, current) => {
      const {id, label, __isNew__} = current;
      return Object.assign(acc, {
        [__isNew__ ? '+' + label : id]: {
          [LABEL_FLD]: label
        }
      });
    }, {});
  } else {
    return DEFAULT_TAGS_PLUS;
  }
}

export function toEmTagsPlus(emSelect2) {
  return toGrTagsPlus(emSelect2);
}

export function toAdTagsPlus(adSelect2) {
  return toGrTagsPlus(adSelect2);
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// 	Converts groups/emails/addresses: from GR-TAGS-PLUS into GR-LIST-STR format,
// 	                                  from EM-TAGS-PLUS into EM-LIST-STR format,
// 	                                  from AD-TAGS-PLUS into AD-LIST-STR format.
//
//  Attn:
//  - - - - -
//  - для groups/emails потрібен порядок сортування обернений до бажаного (API розвертає список);
//  - GR-LIST-STR це стрінгіфікований GR-LIST-PLUS;
//
//  {{ GR-TAGS-PLUS }}:
//  {
//    "id1": {                              // id
//        l: "Label"                        // назва групи/мейл-адреса/адреси
//      },
//    ...
//  }
//
//  {{ GR-LIST-STR }}:
//  "[
//    {
//      i: "1234567890123",                 // id || '+' to create new group/email/address
//      l: "Label"                          // назва групи/мейл-адреса/адреси
//    },
//    ...
//  ]"
//
export function toGrListStr(grTagsPlus) {
  if (grTagsPlus && Object.keys(grTagsPlus).length > 0) {
    let grListPlus = [];
    for (let i in grTagsPlus) {
      const {[LABEL_FLD]:label} = grTagsPlus[i];
      if (label) {
        grListPlus = grListPlus.concat([{
          [ID_FLD]: i,
          [LABEL_FLD]: label
        }]);
      }
    }
    return JSON.stringify(
      grListPlus.sort((a, b) => { return a[LABEL_FLD] < b[LABEL_FLD] ? -1 : 1; })
    );
  }
  return '[]';
}

export function toEmListStr(emTagsPlus) {
  return toGrListStr(emTagsPlus);
}

export function toAdListStr(adTagsPlus) {
  return toGrListStr(adTagsPlus);
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// 	Converts groups/emails/addresses: from GR-LIST-PLUS into GR-SELECT2 format,
// 	                                  from EM-LIST-PLUS into EM-SELECT2 format,
// 	                                  from AD-LIST-PLUS into AD-SELECT2 format.
//
//  {{ GR-LIST-PLUS }}:
//  [
//    {
//      i: "1234567890123" || "+",          // id || '+' to create new group/email/address
//      l: "Label",                         // назва групи/мейл-адреса/адреси
//    },
//    ...
//  ]
//
//  {{ GR-SELECT2 }}:
//  [
//    {
//      id: 'alK9A6srElz01',                // id
//      value: 'name1',                     // slugified group/email/address name
//      label: 'Name1',                     // group/email/address name
//      isPublic: true,                     // <-- is always TRUE (бо для груп не використовується) !!!
//      __isNew__: true || false,           // is an option just created by user?
//    },
//    ...
//  ]
//
export function toGrSelect2(grListPlus) {
  if (grListPlus && grListPlus.length > 0) {
    return grListPlus.reduce((acc, current) => {
      const {[ID_FLD]:id, [LABEL_FLD]:label} = current;
      return acc.concat([{
          id: id,
          value: slugify(label),
          label: label,
          isPublic: false, // sic!: групи завжди приватні !!!
        }]
      );
    }, []);
  } else {
    return [];
  }
}

export function toEmSelect2(emListPlus) {
  if (emListPlus && emListPlus.length > 0) {
    return emListPlus.reduce((acc, current) => {
      const {[ID_FLD]:id, [LABEL_FLD]:label} = current;
      return acc.concat([{
          id: id,
          value: slugify(label),
          label: label,
          isPublic: true, // true --> щоб не показувати lock !!!
        }]
      );
    }, []);
  } else {
    return [];
  }
}

export function toAdSelect2(adListPlus) {
  return toEmSelect2(adListPlus);
}

// =================================================================================================
//  (FMT) Collection Format Converters
// =================================================================================================

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// 	Converts collections from CO-SELECT2 into CO-TAGS-PLUS format.
//
//  {{ CO-SELECT2 }}:
//  [
//    {
//      id: 'alK9A6srElz01',                // id
//      type: '.' || 'c' || ...             // type (default, course, ...)
//      value: 'name',                      // transliterated collection name
//      label: 'Name',                      // collection name
//      isPublic: false || true,            // privacy
//      __isNew__: true || false,           // is an option just created by user?
//    },
//    ...
//  ]
//
//  {{ CO-TAGS-PLUS }}:
//  {
//    "id1": {                              // id
//        n: "Name",                        // collection name
//        p: false,                         // privacy
//        t: "c"                            // type (але якщо default, то НЕ додаємо !!!)
//      },
//    "+": {                                // '+' свідчить, що потрібно створити НОВУ колекцію
//        n: "Name",                        // назва колекції, що буде створена
//        p: true,                          // приватність нової колекції
//        t: "c"                            // тип колекції (але якщо default, то НЕ додаємо !!!)
//      },
//    ...
//  }
//
export function toCoTagsPlus(coSelect2) {
  if (coSelect2 && coSelect2.length > 0) {
    return coSelect2.reduce((acc, current) => {
      const {id, type = DEFAULT_COLLECTION, label, isPublic, __isNew__} = current;
      return type === DEFAULT_COLLECTION ?
        Object.assign(acc, {[__isNew__ ? '+' + label : id]: {n: label, p: isPublic }}) :
        Object.assign(acc, {[__isNew__ ? '+' + label : id]: {n: label, p: isPublic, t: type }});
    }, {});
  } else {
    return {};
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// 	Converts collections from CO-TAGS-PLUS into CO-LIST-STR format.
//
//  Attn:
//  - - - - -
//  - для collections потрібен порядок сортування обернений до бажаного (API розвертає список);
//  - CO-LIST-STR це стрінгіфікований CO-LIST-PLUS
//
//  {{ CO-TAGS }}:
//  {
//    "id1": {                              // id
//        n: "Name",                        // name of the collection
//        p: false,                         // privacy
//        t: "c"                            // (OPTIONAL) type (тільки для course, для default відсутнє !!!)
//      },
//    ...
//  }
//
//  {{ CO-LIST-STR }}:
//  "[
//    {
//      i: "1234567890123",                 // id || '+' to create new collections
//      n: "Name",                          // name of the collection
//      p: false || true,                   // privacy
//      t: "c"                              // (OPTIONAL) type (тільки для course, для default відсутнє !!!)
//    },
//    ...
//  ]"
//
export function toCoListStr(coTagsPlus) {
  if (coTagsPlus && Object.keys(coTagsPlus).length > 0) {
    let coListPlus = [];
    for (let i in coTagsPlus) {
      const {n, p, t = DEFAULT_COLLECTION} = coTagsPlus[i];
      if (n) {
        coListPlus = t === DEFAULT_COLLECTION ?
          coListPlus.concat([{i: i, n: n, p: p}]) :
          coListPlus.concat([{i: i, n: n, p: p, t: t}]);
      }
    }
    return JSON.stringify(
      coListPlus.sort((a, b) => { return ((a.t + a.n) < (b.t + b.n)) ? -1 : 1; }) // attn: sorting collections by type + name !!!
    );
  }
  return '[]';
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// 	Converts collections from CO-LIST-PLUS into CO-SELECT2 format.
//
//  {{ CO-LIST-PLUS }}:
//  [
//    {
//      i: "1234567890123" || "+",          // id || '+' to create new collections
//      n: "Name",                          // collection name
//      p: false || true,                   // privacy
//      t: "c"                              // (OPTIONAL) type (тільки для course, для default відсутнє !!!)
//    },
//    ...
//  ]
//
//  {{ CO-SELECT2 }}:
//  [
//    {
//      id: 'alK9A6srElz01',                // id
//      value: 'name',                      // transliterated collection name
//      label: 'Name',                      // collection name
//      isPublic: false || true,            // privacy
//      type: '.' || 'c' || ...             // type (default, course, ...)
//      __isNew__: true || false,           // is an option just created by user?
//    },
//    ...
//  ]
//
export function toCoSelect2(coListPlus) {
  if (coListPlus && coListPlus.length > 0) {
    return coListPlus.reduce((acc, current) => {
      const {i, n, p, t = DEFAULT_COLLECTION} = current;
      return acc.concat([{
          id: i,
          value: slugify(n),
          label: n,
          isPublic: p,
          type: t,
        }]
      );
    }, []);
  } else {
    return [];
  }
}

// =================================================================================================
//  (FMT) Subject Format Converters
//  (FMT) Interest Format Converters
//  (FMT) Category Format Converters
// =================================================================================================

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// 	Converts subjects from SJ-LIST into SJ-SELECT2 format.
//
//  {{ SJ-LIST }}:
//  [
//     "1BW4vVTqoj00"                       // ідентифікатор предмета
//     ...
//  ]
//
//  {{ SJ-SELECT2 }}:
//  [
//    {
//      id: 'alK9A6srElz01',                // id
//      value: 'slug1',                     // slug
//      label: 'Name1',                     // name
//      isPublic: true,                     // <-- is always TRUE (для subjects не використовується) !!!
//      __isNew__: false,                   // <-- is always FALSE (нові subjects не створюються юзерами) !!!
//    },
//    ...
//  ]
//
export function toSjSelect2(sjList, allSubjects) {
  if (sjList && sjList.length > 0 && allSubjects && allSubjects.size > 0) {
    return sjList.reduce((acc, id) => {
      const {slug = '', name = ''} = allSubjects.find(cat => cat.id === id) || {}; // allSubjects is a List()
      return acc.concat([{
          id: id,
          value: slug,
          label: name,
          isPublic: true,
        }]
      );
    }, []);
  } else {
    return [];
  }
}

export function toItSelect2(itList, allInterests) {
  return toSjSelect2(itList, allInterests)
}

export function toCgSelect2(cgList, allCategories) {
  return toSjSelect2(cgList, allCategories)
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// 	Converts subject from SJ-SELECT2 into SJ-LIST format.
//
//  {{ SJ-SELECT2 }}:
//  [
//    {
//      id: 'alK9A6srElz01',                // id
//      value: 'slug1',                     // slug
//      label: 'Name1',                     // name
//      isPublic: true,                     // <-- is always TRUE (для categories не використовується) !!!
//      __isNew__: false,                   // <-- is always FALSE (нові categories не створюються юзерами) !!!
//    },
//    ...
//  ]
//
//  {{ SJ-LIST }}:
//  [
//     "1BW4vVTqoj00"                       // ідентифікатор предмета
//     ...
//  ]
//
export function toSjList(sjSelect2) {
  if (sjSelect2 && sjSelect2.length > 0) {
    return sjSelect2.reduce((acc, current) => {
      const {id} = current;
      return [...acc, id];
    }, []);
  } else {
    return [];
  }
}

export function toItList(itSelect2) {
  return toSjList(itSelect2);
}

export function toCgList(cgSelect2) {
  return toSjList(cgSelect2);
}

// =================================================================================================
//  Select Field
// =================================================================================================

const customControl = (props) => {
  const {
    innerProps,
    isFocused,
    children,
    // hasValue,
    // isDisabled,
    // isMulti,
    // isRtl,
  } = props;
  const {...otherInnerProps} = innerProps || {};
  return (
    <div className={classnames(styles.control, {
      [styles.isFocused]: isFocused,
    })} {...otherInnerProps}>
      {children}
    </div>
  );
};

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
const customIndicatorSeparator = ({innerProps}) => {
  return (
    <span className={styles.indicatorSeparator} {...innerProps}></span>
  );
};

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
const customClearIndicator = ({innerProps}) => {
  return (
    <div className={styles.clearIndicator} {...innerProps}>
      <Icon symbolName="cross" className={styles.icon} />
    </div>
  );
};

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
const collectionCreateLabelFormatter = (inputValue) => {
  return t`Create new Collection` + `: "${inputValue}"`;
};

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
const groupCreateLabelFormatter = (inputValue) => {
  return t`Create new Group` + `: "${inputValue}"`;
};

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
const emailCreateLabelFormatter = (inputValue) => {
  return t`Create new Email` + `: "${inputValue}"`;
};

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
const categoryCreateLabelFormatter = (inputValue) => {
  return t`Create new Category` + `: "${inputValue}"`;
};

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
const subjectCreateLabelFormatter = (inputValue) => {
  return t`Create new Subject` + `: "${inputValue}"`;
};

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
const defaultCreateLabelFormatter = (inputValue) => {
  return t`Create new Tag` + `: "${inputValue}"`;
};

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
const customDropdownIndicator = (props) => {
  const {
    innerProps,
    // isFocused,
    // hasValue,
    // isDisabled,
    // isMulti,
    // isRtl,
  } = props;
  return (
    <div className={styles.dropdownIndicator} {...innerProps}>
      <Icon symbolName="triangle-down" className={styles.icon} />
    </div>
  );
};

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
const customMenu = (props) => {
  const {
    innerProps,
    children,
    // isLoading,
    // isMulti,
    // isRtl,
    // hasValue,
    // minMenuHeight,
    // maxMenuHeight,
    // menuPlacement,
    // menuPosition,
    // menuShouldScrollIntoView,
  } = props;
  return (
    <div className={styles.menu} {...innerProps}>
      {children}
    </div>
  );
};

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
const customMenuList = (props) => {
  const {
    innerProps,
    children
    // isLoading,
    // isMulti,
    // isRtl,
    // hasValue,
    // maxHeight,
  } = props;
  const {...otherInnerProps} = innerProps || {}; // ToDo: тут чомусь виникала помилка розпаковки, бо (innerProps === undefined)  ???
  return (
    <div className={styles.menuList} {...otherInnerProps}>
      {children}
    </div>
  );
};

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
const customOption = (props) => {
  const {
    data,
    innerProps,
    isFocused,
    isSelected,
    isDisabled,
    children,
    // isMulti,
    // isRtl,
    // hasValue,
  } = props;
  const {...otherInnerProps} = innerProps || {};
  const {value, label, isPublic, type} = data;
  const isCourse = type === COURSE_COLLECTION; // sic!: курси відмічаємо зеленим !!!
  const hasLock = isPublic === false; // sic!: undefined inSelectField means public
  return (
    <div className={classnames(styles.option, {
      [styles.isCourse]: isCourse,
      [styles.isFocused]: isFocused,
      [styles.isSelected]: isSelected,
      [styles.isDisabled]: isDisabled,
    })} {...otherInnerProps}>
      {hasLock &&
        <div className={styles.privacy}>
          <Icon symbolName="lock" className={styles.icon} />
        </div>
      }
      {children}
    </div>
  );
};

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
const customMultiValue = (props) => {
  const {
    data,
    isFocused,
    isDisabled,
    removeProps,
    // children,
    // components,
    // hasValue,
    // isMulti,
    // isRtl,
  } = props;
  const {value, label, isPublic, type} = data;
  const hasLock = isPublic === false; // sic!: undefined inSelectField means public
  return (
    <div className={classnames(styles.multiValue, {
      [styles.isFocused]: isFocused,
      [styles.isDisabled]: isDisabled,
      [styles.isCourseType]: type === COURSE_COLLECTION,
    })}>
      {hasLock &&
        <div className={styles.privacy}>
          <Icon symbolName="lock" className={styles.icon} />
        </div>
      }
      <div className={styles.labelText}>{label}</div>
      <div className={styles.removeButton}
        {...removeProps}>
        <Icon symbolName="cross" className={styles.icon} />
      </div>
    </div>
  );
};

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export default class SelectField extends React.Component {
  static propTypes = {
    name: Types.string,                   // name of the HTML Input (optional - without this, no input will be rendered)
    value: Types.oneOfType([Types.object, Types.array]), // the current value; reflected by the selected option
    choices: Types.oneOfType([Types.object, Types.array]), // list of choices to select
    choicesType: Types.string,            // type of choice content (e.g.: collections, emails, ...)
    delimiter: Types.string,              // delimiter used to join multiple values into a single HTML Input value
    placeholder: Types.string,            // placeholder text for the select value
    label: Types.string,                  // label text (shown above field)
    error: Types.string,                  // error message
    isAutoFocused: Types.bool,            // focus the control when it is mounted
    isBackspaceRemovable: Types.bool,     // can backspace remove current value?
    isClearable: Types.bool,              // is the select value clearable?
    isDisabled: Types.bool,               // is the select disabled?
    isInsertable: Types.bool,             // can insert new values?
    isRequired: Types.bool,               // is field required?
    isSearchable: Types.bool,             // is search functionality enabled?
    isMenuCloseOnSelect: Types.bool,      // close the select menu when the user selects an option
    isMenuOpenOnFocus: Types.bool,        // allows control of whether the menu is opened when the Select is focused
    isMenuOpenOnClick: Types.bool,        // allows control of whether the menu is opened when the Select is clicked
    isMulti: Types.bool,                  // is multiple selection enabled?
    isRtl: Types.bool,                    // is the select direction right-to-left?
    onBlur: Types.func,                   // callback on blur events
    onFocus: Types.func,                  // callback on focus events
    onChange: Types.func,                 // callback on change events
    onInputChange: Types.func,            // callback on change events on the input
    onKeyDown: Types.func,                // callback on key down events
    onMenuOpen: Types.func,               // callback on menu opening events
    onMenuClose: Types.func,              // callback on menu closing events
    onMenuScrollToTop: Types.func,        // fired when the user scrolls to the top of the menu
    onMenuScrollToBottom: Types.func,     // fired when the user scrolls to the bottom of the menu
    onNoOptions: Types.func,              // text to display when there are no options
    onFormatCreateLabel: Types.func,      // custom function to format 'create..." label
  }

  static defaultProps = {
    choices: [],
    choicesType: OTHER_CHOICES,
    delimiter: '·',
    placeholder: '',
    label: '',
    error: '',
    isAutoFocused: false,
    isBackspaceRemovable: false,
    isClearable: false,
    isDisabled: false,
    isInsertable: false,
    isRequired: false,
    isSearchable: true,
    isMenuCloseOnSelect: true,
    isMenuOpenOnFocus: true,
    isMenuOpenOnClick: true,
    isMulti: false,
    isRtl: false,
    onBlur: undefined,
    onFocus: undefined,
    onChange: undefined,
    onInputChange: undefined,
    onKeyDown: undefined,
    onMenuOpen: undefined,
    onMenuClose: undefined,
    onMenuScrollToTop: undefined,
    onMenuScrollToBottom: undefined,
    onFormatCreateLabel: undefined,
  }

  // - - - - - - - - - - - - - - - - - - -
  //  Кастомна фільтрація елементів списку.
  //  Можливість вибирати тільки приватні або публічні колекції.
  //  Пошук дублів проводиться по полям label & value.
  //
  //  [searchCmd] + searchStr = pattern
  //
  //  Attn:
  //  - - - - -
  //  - дефолтна фільтрація шукає значення в label & value.
  // - - - - - - - - - - - - - - - - - - -
  handleFilter = (item, pattern) => {
    // show all items for empty search string
    if (!item || !item.label || !pattern) {
      return true;
    }
    const {label = '', value = '', data} = item;
    if (data) {
      const {isPublic, __isNew__} = data;
      // ...show all new & new candidates items
      if (__isNew__) {
        return true;
      }
      const searchCmd = pattern.substr(0,1);
      // ...show private items only
      if (searchCmd === '+') {
        const searchStr = pattern.substr(1);
        return isPublic === false && (!searchStr ? true
            : label.toLowerCase().indexOf(searchStr.toLowerCase()) >= 0
            || value.toLowerCase().indexOf(searchStr.toLowerCase()) >= 0);
      }
      // ...show public items only
      if (searchCmd === '-') {
        // sic!: undefined inSelectField means public
        const searchStr = pattern.substr(1);
        return isPublic !== false && (!searchStr ? true
            : label.toLowerCase().indexOf(searchStr.toLowerCase()) >= 0
            || value.toLowerCase().indexOf(searchStr.toLowerCase()) >= 0);
      }
    }
    // ...show items containing search string in label only
    return (
      label.toLowerCase().indexOf(pattern.toLowerCase()) >= 0
      || value.toLowerCase().indexOf(pattern.toLowerCase()) >= 0
    );
  }

  // - - - - - - - - - - - - - - - - - - -
  //  Обробка введеного текста та пошук/створення нового tag-елементу.
  //
  //  Шукаємо в списку choices, якщо є то беремо елемент звідти.
  //  Якщо відсутній в choices, то беремо елемент із введених даних.
  //  Обраний елемент перевіряємо на дублювання в списку items,
  //  і якщо тут його немає, то тільки тоді додаємо його в items.
  //  При додаванні робимо перетворення slugify/cleanifyEmail для value/label.
  //
  //  Attn: 1)
  //  - - - - -
  //  - По-замовчуванню колекції створюються як приватні (isPublic := false);
  //  - Приватність isPublic := true можна задати, вказавши '-' на початку.
  //    Інші значення вважаються isPublic := false;
  //    Так зроблено, щоб недосвідчені юзери не засмічували стрічку.
  //
  //  Attn:
  //  - - - - -
  //  - вважаємо, що введене значення є останнім в масиві елементів;
  //  - вважаємо, що для цього значення ще не задане поле isPublic (тому воно undefined);
  //  - приватність для визначення унікальності не має значення, лише value & label;
  //  - 'c+++', 'c++' --> 'c' in values, that's why these items are same even with different labels;
  // - - - - - - - - - - - - - - - - - - -
  handleChangeCollections = (items) => {
    const {choices, onChange} = this.props;
    // тільки для введених елементів, які не були знайдені в props.choices (напр: містять '+')
    if (items && items.length > 0 && items[items.length - 1].isPublic === undefined) {
      const prevItem = items.pop();
      const prevValue = prevItem.value + '';
      const prevLabel = prevItem.label + '';
      const firstSymb = prevValue[0];
      const nextValue = slugify(firstSymb === '+' || firstSymb === '-' ?
        prevValue.substr(1) :
        prevValue);
      const nextLabel = cleanifyCollectionName(firstSymb === '+' || firstSymb === '-' ?
        prevLabel.substr(1) :
        prevLabel);
      // ...1) пошук на присутність в списку props.choices; якщо є, то беремо елемент звідти;
      let foundItem;
      choices && choices.map(elem => {
        if (elem.value === nextValue || elem.label === nextLabel) {
          if (!foundItem) {
            foundItem = Object.assign(elem);
          }
        }
      });
      // ...2) пошук на дублювання в списку вже вибраних/новостворених елементів;
      let isDoubled = false;
      items.map(elem => {
        if (elem.value === nextValue || elem.label === nextLabel) {
          isDoubled = true;
        }
      });
      // ...add current item when not doubled and value & label are non empty
      if (!isDoubled && nextValue.length > 0 && nextLabel.length > 0) {
        if (foundItem) {
          // додаємо знайдений в choices елемент
          items.push(foundItem);
        } else {
          // створюємо новий елемент
          items.push(Object.assign(prevItem, {
            id: '+',
            value: nextValue,
            label: nextLabel,
            isPublic: firstSymb === '-', // attn: 1)
          }));
        }
      }
    }
    onChange && onChange(items);
  }

  // - - - - - - - - - - - - - - - - - - -
  //  Обробка введеного текста та пошук/створення нового group-елементу.
  //
  //  Шукаємо в списку choices, якщо є то беремо елемент звідти.
  //  Якщо відсутній в choices, то беремо елемент із введених даних.
  //  Обраний елемент перевіряємо на дублювання в списку items,
  //  і якщо тут його немає, то тільки тоді додаємо його в items.
  //  При додаванні робимо перетворення slugify/cleanifyEmail для value/label.
  //
  //  Attn:
  //  - - - - -
  //  - вважаємо, що введене значення є останнім в масиві елементів;
  //  - 'c+++', 'c++' --> 'c' in values, that's why these items are same even with different labels;
  // - - - - - - - - - - - - - - - - - - -
  handleChangeGroups = (items) => {
    const {choices, onChange} = this.props;
    // тільки для введених елементів, які не були знайдені в props.choices (напр: містять '+')
    if (items && items.length > 0 && items[items.length - 1].isPublic === undefined) {
      const prevItem = items.pop();
      const prevValue = prevItem.value + '';
      const prevLabel = prevItem.label + '';
      const firstSymb = prevValue[0];
      const nextValue = slugify(firstSymb === '+' || firstSymb === '-' ?
        prevValue.substr(1) :
        prevValue);
      const nextLabel = cleanifyGroupName(firstSymb === '+' || firstSymb === '-' ?
        prevLabel.substr(1) :
        prevLabel);
      // ...1) пошук на присутність в списку props.choices; якщо є, то беремо елемент звідти;
      let foundItem;
      choices && choices.map(elem => {
        if (elem.value === nextValue || elem.label === nextLabel) {
          if (!foundItem) {
            foundItem = Object.assign(elem);
          }
        }
      });
      // ...2) пошук на дублювання в списку вже вибраних/новостворених елементів;
      let isDoubled = false;
      items.map(elem => {
        if (elem.value === nextValue || elem.label === nextLabel) {
          isDoubled = true;
        }
      });
      // add current item when not doubled and value & label are non empty
      if (!isDoubled && nextValue.length > 0 && nextLabel.length > 0) {
        if (foundItem) {
          // додаємо знайдений в choices елемент
          items.push(foundItem);
        } else {
          // створюємо новий елемент
          items.push(Object.assign(prevItem, {
            id: '+',
            value: nextValue,
            label: nextLabel,
            isPublic: false, // sic!: групи завжди приватні !!!
          }));
        }
      }
    }
    onChange && onChange(items);
  }

  // - - - - - - - - - - - - - - - - - - -
  //  Обробка введеного текста та пошук/створення нового email-елементу.
  //
  //  Шукаємо в списку choices, якщо є то беремо елемент звідти.
  //  Якщо відсутній в choices, то беремо елемент із введених даних.
  //  Обраний елемент перевіряємо на дублювання в списку items,
  //  і якщо тут його немає, то тільки тоді додаємо його в items.
  //  При додаванні робимо перетворення cleanifyEmail/cleanifyEmail для value/label.
  //
  //  Attn:
  //  - - - - -
  //  1) при формуванні value використовуємо cleanifyEmail щоб зберегти символи "@" та ".";
  //  2) вважаємо, що введене значення є останнім в масиві елементів;
  //  3) 'c+++', 'c++' --> 'c' in values, that's why these items are same even with different labels;
  // - - - - - - - - - - - - - - - - - - -
  handleChangeEmails = (items) => {
    const {choices, onChange} = this.props;
    // тільки для введених елементів, які не були знайдені в props.choices (напр: містять '+')
    if (items && items.length > 0 && items[items.length - 1].isPublic === undefined) {
      const prevItem = items.pop();
      const prevValue = prevItem.value + '';
      const prevLabel = prevItem.label + '';
      const firstSymb = prevValue[0];
      const nextValue = cleanifyEmail(firstSymb === '+' || firstSymb === '-' ? // sic!: 1)
        prevValue.substr(1) :
        prevValue);
      const nextLabel = cleanifyEmail(firstSymb === '+' || firstSymb === '-' ?
        prevLabel.substr(1) :
        prevLabel);
      // ...1) пошук на присутність в списку props.choices; якщо є, то беремо елемент звідти;
      let foundItem;
      choices && choices.map(elem => {
        if (elem.value === nextValue || elem.label === nextLabel) {
          if (!foundItem) {
            foundItem = Object.assign(elem);
          }
        }
      });
      // ...2) пошук на дублювання в списку вже вибраних/новостворених елементів;
      let isDoubled = false;
      items.map(elem => {
        if (elem.value === nextValue || elem.label === nextLabel) {
          isDoubled = true;
        }
      });
      // add current item when not doubled and value & label are non empty
      if (!isDoubled && nextValue.length > 0 && nextLabel.length > 0) {
        if (foundItem) {
          // додаємо знайдений в choices елемент
          items.push(foundItem);
        } else {
          // створюємо новий елемент
          items.push(Object.assign(prevItem, {
            id: '+',
            value: nextValue,
            label: nextLabel,
          }));
        }
      }
    }
    onChange && onChange(items);
  }

  // Attn: 1) Оскільки список categories не додається юзером, то повертаємо його БЕЗ змін.
  // - - - - - - - - - - - - - - - - - - -
  handleChangeCategories = (items) => {
    const {onChange} = this.props;
    onChange && onChange(items); // attn: 1)
  }

  // Attn: 1) Оскільки список subjects не додається юзером, то повертаємо його БЕЗ змін.
  // - - - - - - - - - - - - - - - - - - -
  handleChangeSubjects = (items) => {
    const {onChange} = this.props;
    onChange && onChange(items); // attn: 1)
  }

  isCollectionInputValid = (input) => {
    return (input + '').length > 0;
  }

  isGroupInputValid = (input) => {
    return (input + '').length > 0;
  }

  isEmailInputValid = (input) => {
    return isEmailValid(input);
  }

  isCategoryInputValid = (input) => {
    return (input + '').length > 0;
  }

  isSubjectInputValid = (input) => {
    return (input + '').length > 0;
  }

  isDefaultInputValid = (input) => {
    return true;
  }

  collectionOnNoOptions = () => t`Enter collection name to create new one`;
  groupOnNoOptions = () => t`Enter group name to create new one`;
  emailOnNoOptions = () => t`Enter valid email address to create new one`;
  categoryOnNoOptions = () => t`No more categories to choose from`;
  subjectOnNoOptions = () => t`No more subjects to choose from`;
  defaultOnNoOptions = () => t`No options`;

  render() {
    const {
      name,
      value,
      choices,
      choicesType,
      delimiter,
      placeholder,
      error,
      label,
      isAutoFocused,
      isBackspaceRemovable,
      isClearable,
      isDisabled,
      isInsertable,
      isRequired,
      isSearchable,
      isMenuCloseOnSelect,
      isMenuOpenOnFocus,
      isMenuOpenOnClick,
      isMulti,
      isRtl,
      onBlur,
      onFocus,
      onInputChange,
      onKeyDown,
      onMenuOpen,
      onMenuClose,
      onMenuScrollToTop,
      onMenuScrollToBottom,
      onFormatCreateLabel,
      onNoOptions} = this.props;
    const SelectToRender = isInsertable ? Creatable : Select;
    let createLabelFormatter = onFormatCreateLabel;
    if (!createLabelFormatter) {
      switch (choicesType) {
        case COLLECTION_CHOICES:  createLabelFormatter = collectionCreateLabelFormatter; break;
        case GROUP_CHOICES:       createLabelFormatter = groupCreateLabelFormatter; break;
        case EMAIL_CHOICES:       createLabelFormatter = emailCreateLabelFormatter; break;
        case CATEGORY_CHOICES:    createLabelFormatter = categoryCreateLabelFormatter; break;
        case SUBJECT_CHOICES:     createLabelFormatter = subjectCreateLabelFormatter; break;
        default:                  createLabelFormatter = defaultCreateLabelFormatter; break;
      }
    }
    let noOptionHandler = onNoOptions;
    if (!noOptionHandler) {
      switch (choicesType) {
        case COLLECTION_CHOICES:  noOptionHandler = this.collectionOnNoOptions; break;
        case GROUP_CHOICES:       noOptionHandler = this.groupOnNoOptions; break;
        case EMAIL_CHOICES:       noOptionHandler = this.emailOnNoOptions; break;
        case CATEGORY_CHOICES:    noOptionHandler = this.categoryOnNoOptions; break;
        case SUBJECT_CHOICES:     noOptionHandler = this.subjectOnNoOptions; break;
        default:                  noOptionHandler = this.defaultOnNoOptions; break;
      }
    }
    let isOptionValid;
    switch (choicesType) {
      case COLLECTION_CHOICES:    isOptionValid = this.isCollectionInputValid; break;
      case GROUP_CHOICES:         isOptionValid = this.isGroupInputValid; break;
      case EMAIL_CHOICES:         isOptionValid = this.isEmailInputValid; break;
      case CATEGORY_CHOICES:      isOptionValid = this.isCategoryInputValid; break;
      case SUBJECT_CHOICES:       isOptionValid = this.isSubjectInputValid; break;
      default:                    isOptionValid = this.isDefaultInputValid; break;
    }
    let onChangeHandler;
    switch (choicesType) {
      case COLLECTION_CHOICES:    onChangeHandler = this.handleChangeCollections; break;
      case GROUP_CHOICES:         onChangeHandler = this.handleChangeGroups; break;
      case EMAIL_CHOICES:         onChangeHandler = this.handleChangeEmails; break;
      case CATEGORY_CHOICES:      onChangeHandler = this.handleChangeCategories; break;
      case SUBJECT_CHOICES:       onChangeHandler = this.handleChangeSubjects; break;
      default:                    onChangeHandler = this.handleChangeCollections; break;
    }
    return (
      <div className={classnames(styles.SelectField, {[styles.isError]: error})}>
        {label &&
          <div className={styles.label}>{label}{isRequired && <span>*</span>}:</div>
        }
        <SelectToRender
          className={styles.select}
          classNamePrefix="s2"
          name={name}
          value={value}
          options={choices}
          delimiter={delimiter}
          placeholder={isDisabled ? '' : placeholder}
          formatCreateLabel={createLabelFormatter}
          components={{
            Control: customControl,
            ClearIndicator: customClearIndicator,
            DropdownIndicator: customDropdownIndicator,
            IndicatorSeparator: customIndicatorSeparator,
            Menu: customMenu,
            MenuList: customMenuList,
            MultiValue: customMultiValue,
            Option: customOption,
          }}
          autoFocus={isAutoFocused}
          backspaceRemovesValue={isBackspaceRemovable}
          closeMenuOnSelect={isMenuCloseOnSelect}
          openMenuOnFocus={isMenuOpenOnFocus}
          openMenuOnClick={isMenuOpenOnClick}
          isClearable={isClearable}
          isSearchable={isSearchable}
          isDisabled={isDisabled}
          isMulti={isMulti}
          isRtl={isRtl}
          isValidNewOption={isOptionValid}
          filterOption={this.handleFilter}
          noOptionsMessage={noOptionHandler}
          onBlur={onBlur}
          onFocus={onFocus}
          onChange={onChangeHandler}
          onInputChange={onInputChange}
          onKeyDown={onKeyDown}
          onMenuOpen={onMenuOpen}
          onMenuClose={onMenuClose}
          onMenuScrollToTop={onMenuScrollToTop}
          onMenuScrollToBottom={onMenuScrollToBottom}
        />
        {error &&
          <div className={styles.error}>{error}</div>
        }
      </div>
    );
  }
}
