// -------------------------------------------------------------------------------------------------
//  App.js
//  - - - - - - - - - -
//  У нас є два режими інтерфейсу: мобільний (до 1228px включно) та десктопний (ширше аніж 1228px).
//  Для цих режимів можуть існувати різні компоненти, переключення по значенню флагу isMobile.
//
//  Docs:
//  - - - - -
//  3) https://www.npmjs.com/package/react-ga4
//
//  Attn:
//  - - - - -
//  - усі URL-адреси, що складаються ЛИШЕ з однієї частини повинні в Route мати фразу 'exact';
//    (напр: <Route path="/ads" exact component={DiscoverAdverts} />)
//  - для URL-адрес, що містяь `/auth`, `/add`, `/create` в історії робимо replace замість push;
//  - URL-адреси, що відмічені як 'trackless' не зберігаємо в локалстор (зручно для переадресації);
//  - на юзера адресуємо `/${slug}` щоб була можливість зробити зпільний coll/staple-фід,
//    тоді як роути `/${slug}/items` та `/${slug}/collections` - це фіди обʼєктів одного типу;
// -------------------------------------------------------------------------------------------------
// ToDo: при переключенні вигляду (landscape <---> portrait) не перемикаються Mobile* / Desktop !!!
// ToDo: може замість прокидання функції onToggleSidebar передавати ЛИШЕ isMobile ???

import React, {Fragment as F} from 'react';
import {BrowserRouter, Route, Redirect} from 'react-router-dom';
import {Container} from 'flux/utils';
import {is} from 'immutable';
import Media from 'react-media';
import ReactGA from 'react-ga4';
import 'styles/reset.scss';
import 'styles/markdown.scss';

import {relogin} from 'actions/AuthActions';
import {setUnreadMessagesAsRead} from 'actions/ChatActions';
import {updatePendingFields} from 'actions/MiscActions';
import {subscribeOnServerEvents, unsubscribeFromServerEvents} from 'actions/ServerActions';
import {toggleSidebar} from 'actions/LayoutActions';
import {DEFAULT_COLLECTION, COURSE_COLLECTION, SEQUENCE_COLLECTION, RANDOM_COLLECTION} from 'core/commonTypes';
import {USER_CTX, STAPLE_CTX, COLLECTION_CTX, ADVERT_CTX, ROOM_CTX} from 'core/communicationTypes';
import AccountStore from 'stores/AccountStore';
import ChatStore from 'stores/ChatStore';
import CrmStore from 'stores/CrmStore';
import UserStore from 'stores/UserStore';

import Head from 'application/Head';
import GAListener from 'application/GAListener';
import LayerSwitch from 'application/LayerSwitch';
import {NoTabbar, addBodyClass, removeBodyClass} from 'application/Body';

import RootNavbar from 'components/Navbars/RootNavbar';
import RootSidebar from 'components/Sidebars/RootSidebar';
import Tabbar from 'components/Tabbars/Tabbar';
import MobileMenu from 'components/Navbars/MobileMenu';
import ColorTagsEditor from 'components/UI/ColorTagsEditor';
import ConfirmAction from 'components/UI/ConfirmAction';
import SelectAction from 'components/UI/SelectAction';
import SelectObjects from 'components/UI/SelectObjects';
import HelpScreen from 'components/UI/HelpScreen';
import CreateGradeMessage from 'components/Chats/CreateGradeMessage';
import CreateMediaMessage from 'components/Chats/CreateMediaMessage';
import MediaViewer from 'components/MediaViewer';
import Informer from 'components/UI/Informer';

import Login from 'components/Auth/LoginLoader';
import LoginError from 'components/Auth/LoginErrorLoader';
import LoginCanceled from 'components/Auth/LoginCanceledLoader';
import LoginSuccess from 'components/Auth/LoginSuccessLoader';
import RegistrationConfirm from 'components/Auth/RegistrationConfirmLoader';
import RegistrationEmailSent from 'components/Auth/RegistrationEmailSentLoader';
import RegistrationEmailExpired from 'components/Auth/RegistrationEmailExpiredLoader';
import RegistrationSuccess from 'components/Auth/RegistrationSuccessLoader';
import LogoutSuccess from 'components/Auth/LogoutSuccessLoader';
import Invitation from 'components/Auth/InvitationLoader';
import Settings from 'components/Settings';

// import DiscoverAdverts from 'components/Feeds/DiscoverAdverts'; // ToDo: Hidden functionality of Adverts !!!
import SubjectCollections from 'components/Feeds/SubjectCollections';
import DiscoverCollections from 'components/Feeds/DiscoverCollections';
import DiscoverStaples from 'components/Feeds/DiscoverStaples';
import FeaturedStaples from 'components/Feeds/FeaturedStaples';
import FollowingStaples from 'components/Feeds/FollowingStaples';
// import SubjectAdverts from 'components/Feeds/SubjectAdverts'; // ToDo: Hidden functionality of Adverts !!!
import MyContacts from 'components/Feeds/MyContacts';
import MyGroupContacts from 'components/Feeds/MyGroupContacts';
import MyGroups from 'components/Feeds/MyGroups';
import MyFSRUsers from 'components/Feeds/MyFSRUsers';
import MyTopicStaples from 'components/Feeds/MyTopicStaples';
import MyCommunityUsers from 'components/Feeds/MyCommunityUsers';
import MyCSubscriptions from 'components/Feeds/MyCSubscriptions';
import MyCSubscriptionStaples from 'components/Feeds/MyCSubscriptionStaples';
import MyWorkspaceStaples from 'components/Feeds/MyWorkspaceStaples';
import CollectionStaples from 'components/Feeds/CollectionStaples';
// import UserAdverts from 'components/Feeds/UserAdverts'; // ToDo: Hidden functionality of Adverts !!!
import UserStaples from 'components/Feeds/UserStaples';
import UserCollections from 'components/Feeds/UserCollections';
import UserTopicStaples from 'components/Feeds/UserTopicStaples';

import CreateContact from 'components/Contacts/CreateContact';
import EditContact from 'components/Contacts/EditContact';
import OneContact from 'components/Contacts/OneContact';
import CreateGroup from 'components/Groups/CreateGroup';
import EditGroup from 'components/Groups/EditGroup';
import AddAdvertFeedback from 'components/Adverts/AddAdvertFeedback';
import CreateAdvert from 'components/Adverts/CreateAdvert';
import EditAdvert from 'components/Adverts/EditAdvert';
import OneAdvert from 'components/Adverts/OneAdvert';
import CreateStaple from 'components/Staples/CreateStaple';
import CloneStaple from 'components/Staples/CloneStaple';
import EditStaple from 'components/Staples/EditStaple';
import OneStaple from 'components/Staples/OneStaple';
import CreateCollection from 'components/Collections/CreateCollection';
import EditCollection from 'components/Collections/EditCollection';
import OneCollection from 'components/Collections/OneCollection'; // ToDo: OBSOLETE <-- чи потрібна ця форма  ???
import InviteFriend from 'components/Users/InviteFriend';
import OneUser from 'components/Users/OneUser';

import AboutPage from 'components/Pages/AboutPage';
import StaticPage from 'components/Pages/StaticPage';
import PricingPage from 'components/Pages/PricingPage';
import WelcomePage from 'components/Pages/WelcomePage';
import NotFoundPage from 'components/Pages/NotFoundPage';
import SocialRedirectPage from 'components/Pages/SocialRedirectPage';
import UserRedirectPage from 'components/Pages/UserRedirectPage';

import {app as settings} from 'settings/local.yaml';
import styles from './App.scss'; // sic!: обовʼязково!

ReactGA.initialize(GOOGLE_ANALYTICS_CODE, {debug: false, titleCase: false});
ReactGA.ga('set', 'userId', AccountStore.getMyId());

const isVerbose = DEBUG && true;
const prefix = '- - - App';

let isAppSubscribed                       // флаг проведення wsock-підписок поточного аплікейшена
let reloginTimer;                         // ідентифікатор таймера повторного логіну в систему
let unreadTimer;                          // ідентифікатор таймера обробки unread-меседжів
let pendingTimer;                         // ідентифікатор таймера обробки незбережених зет-полів

function trace(msg, ...other) { if (isVerbose) { console.log(`${prefix}.${msg}`, ...other); }}
function traceWarn(msg, ...other) { if (isVerbose) { console.warn(`${prefix}.${msg}`, ...other); }}
function traceError(msg, ...other) { console.error(`${prefix}.${msg}`, ...other); }

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
class App extends React.Component {
  constructor(props) {
    super(props);
    addBodyClass('no-sidebar');           // по-замовчуванню без сайдбару, бо isSidebarOpen: false
  }

  enableSidebarTransitions = false;       // are transitions enabled?

  static getStores() {
    trace(`getStores`);
    return [AccountStore, ChatStore, CrmStore, UserStore];
  }

  static calculateState(prevState, props) {
    trace(`calculateState`);
    const myAccount = AccountStore.getMyAccount();
    const isLoggedIn = AccountStore.isLoggedIn();
    const {isSidebarOpen} = AccountStore.getUISettings();
    const fsrCount = isLoggedIn ? UserStore.getFSRequestCount() : '';
    const topicUnreadCount = isLoggedIn ? ChatStore.getTopicUnreadCount() : '';
    const communityUnreadCount = isLoggedIn ? ChatStore.getCommunityUnreadCount() : '';
    const observedIds = isLoggedIn ? ChatStore.getObservedIds() : undefined;
    return {
      myAccount, isLoggedIn, isSidebarOpen, fsrCount,
      topicUnreadCount, communityUnreadCount, observedIds};
  }

  shouldComponentUpdate(nextProps, nextState) {
    const result = this.state.isSidebarOpen !== nextState.isSidebarOpen
      || this.state.communityUnreadCount !== nextState.communityUnreadCount
      || this.state.topicUnreadCount !== nextState.topicUnreadCount
      || this.state.fsrCount !== nextState.fsrCount
      || this.state.isLoggedIn !== nextState.isLoggedIn
      || !is(this.state.myAccount, nextState.myAccount);
    trace(`shouldComponentUpdate: ${result}`);
    return result;
  }

  UNSAFE_componentWillMount() {
    trace(`UNSAFE_componentWillMount`);
    this.state.isSidebarOpen ? removeBodyClass('no-sidebar') : addBodyClass('no-sidebar'); // layout: 1) init sidebar
  }

  componentDidMount() {
    trace(`componentDidMount`);
    const {isLoggedIn} = this.state;
    if (isLoggedIn) {
      if (!isAppSubscribed) {
        subscribeOnServerEvents();
        isAppSubscribed = true;
      }
      if (!reloginTimer) {
        reloginTimer = setTimeout(relogin, settings.reloginTimeout);
      }
      if (!unreadTimer) {
        unreadTimer = setInterval(this.handleUnreadMessages, settings.unreadTimeout);
      }
      if (!pendingTimer) {
        pendingTimer = setInterval(this.handlePendingFields, settings.pendingTimeout);
      }
    }
  }

  componentDidUpdate() {
    trace(`componentDidUpdate`);
    const {isLoggedIn} = this.state;
    if (isLoggedIn) {
      if (!isAppSubscribed) {
        subscribeOnServerEvents();
        isAppSubscribed = true;
      }
      if (!reloginTimer) {
        reloginTimer = setTimeout(relogin, settings.reloginTimeout);
      }
      if (!unreadTimer) {
        unreadTimer = setInterval(this.handleUnreadMessages, settings.unreadTimeout);
      }
      if (!pendingTimer) {
        pendingTimer = setInterval(this.handlePendingFields, settings.pendingTimeout);
      }
    } else {
      if (isAppSubscribed) {
        unsubscribeFromServerEvents();
        isAppSubscribed = false;
      }
      if (reloginTimer) {
        clearInterval(reloginTimer);
      }
      if (unreadTimer) {
        clearInterval(unreadTimer);
      }
      if (pendingTimer) {
        clearInterval(pendingTimer);
      }
    }
  }

  componentWillUnmount() {
    trace(`componentWillUnmount`);
    unsubscribeFromServerEvents();
    isAppSubscribed = false;
  }

  handleUnreadMessages = () => {
    const {isLoggedIn, observedIds} = this.state;
    if (isLoggedIn && observedIds && observedIds.size > 0) {
      trace(`handleUnreadMessages: qty=${observedIds.size}`);
      setUnreadMessagesAsRead(observedIds);
    }
  }

  handlePendingFields = () => {
    const {isLoggedIn} = this.state;
    if (isLoggedIn) {
      trace(`handlePendingFields`);
      updatePendingFields();
    }
  }

  handleToggleSidebar = () => {
    toggleSidebar(!this.state.isSidebarOpen); // layout: 2) on/off sidebar
    this.enableSidebarTransitions = true; // enable transitions on toggle
  }

  render() {
    const {myAccount, isLoggedIn, isSidebarOpen, fsrCount, topicUnreadCount, communityUnreadCount} = this.state;
    const {id:myId, slug:mySlug = ''} = myAccount;
    trace(`render: isLoggedIn=${isLoggedIn}`);
    // sic!: mobile 1024-->1228: (max-width: 1024px) --> (max-width: 1228px) !!!
    return (
      <BrowserRouter>
        <Media query="(max-width: 1228px)">
          {(isMobile) => {
            return (
              <F>
                <GAListener />
              {/* ToDo: OBSOLETE ???
                <Head />
              */}
                {/* - - - top - - - */}
                <RootNavbar
                  myAccount={myAccount}
                  isLoggedIn={isLoggedIn}
                  isMobile={isMobile}
                  isSidebarOpen={isSidebarOpen}
                  fsrCount={fsrCount}
                  topicUnreadCount={topicUnreadCount}
                  communityUnreadCount={communityUnreadCount}
                  onToggleSidebar={this.handleToggleSidebar}
                />
                {/* - - - main - - - */}
                <RootSidebar
                  isLoggedIn={isLoggedIn}
                  isDocked={isSidebarOpen && !isMobile}
                  isOpen={isSidebarOpen}
                  isMobile={isMobile}
                  isPulledRight={true}
                  enableTransitions={this.enableSidebarTransitions}
                  onToggleSidebar={this.handleToggleSidebar}
                >
                  {!isLoggedIn &&
                    <LayerSwitch key="logged-out" mySlug={mySlug} isMobile={isMobile}>
                      <Route path="/"                             exact render={() => <AboutPage                isLoggedIn={isLoggedIn} mySlug={mySlug}/>}  />
                      {/* <Route path="/ads"                      exact render={(props) => <DiscoverAdverts     {...props} />} /> */}
                      <Route path="/courses"                      exact render={(props) => <DiscoverCollections collectionType={COURSE_COLLECTION} {...props} />} />
                      <Route path="/discover"                     exact render={(props) => <DiscoverStaples     {...props} />} />
                      <Route path="/featured"                     exact render={(props) => <FeaturedStaples     {...props} />} />
                      <Route path="/following"                    exact render={(props) => <FollowingStaples    {...props} />} />
                      <Route path="/my/groups"                    exact component={Login} layer="modal" />
                      <Route path="/my/contacts"                  exact component={Login} layer="modal" />
                      <Route path="/my/invitations"               exact component={Login} layer="modal" />
                      <Route path="/my/topics"                    exact component={Login} layer="modal" />
                      <Route path="/my/community"                 exact component={Login} layer="modal" />
                      <Route path="/auth/login"                   exact component={Login} layer="modal" />
                      <Route path="/auth/login/success"                 render={() => <LoginSuccess             isMobile={isMobile} />} />
                      <Route path="/auth/login/canceled"                component={LoginCanceled}               layer="modal" />
                      <Route path="/auth/login/error"                   component={LoginError}                  layer="modal" />
                      <Route path="/auth/logout/success"                component={LogoutSuccess}               layer="modal" />
                      <Route path="/auth/registration/confirm"          component={RegistrationConfirm}         layer="modal" />
                      <Route path="/auth/registration/email/sent"       component={RegistrationEmailSent}       layer="modal" />
                      <Route path="/auth/registration/email/expired"    component={RegistrationEmailExpired}    layer="modal" />
                      <Route path="/auth/registration/success"          render={() => <RegistrationSuccess      isLoggedIn={isLoggedIn} isMobile={isMobile} />} />
                      <Route path="/about"                        exact render={() => <AboutPage                isLoggedIn={isLoggedIn} mySlug={mySlug}/>} />
                      <Route path="/invitation"                   exact render={() => <Invitation               isLoggedIn={isLoggedIn} mySlug={mySlug} />} layer="modal" />
                      <Route path="/pricing"                      exact render={(props) => <PricingPage         {...props} />} />
                      <Route path="/terms-of-service"             exact render={(props) => <StaticPage          {...props} />} layer="modal" />
                      <Route path="/privacy-policy"               exact render={(props) => <StaticPage          {...props} />} layer="modal" />
                      <Route path="/user-data-deletion"           exact render={(props) => <StaticPage          {...props} />} layer="modal" />
                      <Route path="/markdown"                     exact render={(props) => <StaticPage          {...props} />} layer="modal" />
                      <Route path="/a/:advertId/feedback"               render={(props) => <AddAdvertFeedback   {...props} />} layer="modal" />
                      <Route path="/a/:advertId"                  exact render={(props) => <OneAdvert           {...props} />} layer="submodal" />
                      <Route path="/i/:stapleId/:elemId"          exact render={(props) => <OneStaple           {...props} />} layer="submodal" />
                      <Redirect exact from="/i/:stapleId" to="/i/:stapleId/1" />
                      <Redirect exact from="/i/:stapleId/0" to="/i/:stapleId/1" />
                      <Route path="/co/:collectionId/info"              render={(props) => <OneCollection       collectionType={DEFAULT_COLLECTION} {...props} />} layer="submodal" />
                      <Route path="/co/:collectionId"             exact render={(props) => <CollectionStaples   collectionType={DEFAULT_COLLECTION} {...props} />} />
                      {/* <Route path="/:userSlug/ads"                  render={(props) => <UserAdverts         {...props} />} /> /> */}
                      <Route path="/:userSlug/about"                    render={(props) => <OneUser             {...props} />} layer="submodal" />
                      <Route path="/:userSlug/collections"              render={(props) => <UserCollections     collectionType={DEFAULT_COLLECTION} {...props} />} />
                      <Route path="/:userSlug/courses"                  render={(props) => <UserCollections     collectionType={COURSE_COLLECTION}  {...props} />} />
                      <Route path="/:userSlug/items"                    render={(props) => <UserStaples         {...props} />} />
                      <Route path="/:userSlug"                          render={(props) => <UserRedirectPage    mySlug="" {...props} />} />
                      <Route component={NotFoundPage} />
                    </LayerSwitch>
                  }
                  {isLoggedIn &&
                    <LayerSwitch key="logged-in" mySlug={mySlug} isMobile={isMobile}>
                      <Route path="/"                             exact component={SocialRedirectPage} />
                      {/* <Route path="/ads"                      exact render={(props) => <DiscoverAdverts     {...props} />} /> */}
                      {/* <Route path="/ads/:subjectSlug"         exact render={(props) => <SubjectAdverts      {...props} />} /> */}
                      <Route path="/courses/:subjectSlug"         exact render={(props) => <SubjectCollections  collectionType={COURSE_COLLECTION} {...props} />} />
                      <Route path="/courses"                      exact render={(props) => <DiscoverCollections collectionType={COURSE_COLLECTION} {...props} />} />
                      <Route path="/discover"                     exact render={(props) => <DiscoverStaples     {...props} />} />
                      <Route path="/featured"                     exact render={(props) => <FeaturedStaples     {...props} />} />
                      <Route path="/following"                    exact render={(props) => <FollowingStaples    {...props} />} />
                      <Route path="/my/invitations"                     render={(props) => <MyFSRUsers          {...props} />} />
                      <Route path="/my/contacts"                        render={(props) => <MyContacts          {...props} />} />
                      <Route path="/my/groups"                          render={(props) => <MyGroups            {...props} />} />
                      <Route path="/my/workspace"                       render={(props) => <MyWorkspaceStaples  myId={myId} {...props} />} />
                      <Route path="/my/topics/"                   exact render={(props) => <MyTopicStaples      {...props} />} />
                      <Route path="/my/community"                       render={(props) => <MyCommunityUsers    {...props} />} />
                      <Route path="/my/subscriptions"                   render={(props) => <MyCSubscriptions    {...props} />} />
                      <Route path="/sub/:collectionId"            exact render={(props) => <MyCSubscriptionStaples {...props} />} />
                      <Route path="/auth/registration/email/expired"    component={RegistrationEmailExpired}    layer="modal" />
                      <Route path="/auth/registration/success"          render={() => <RegistrationSuccess      isMobile={isMobile} />} />
                      <Redirect from="/auth/items" to="/about" />
                      <Route path="/about"                        exact render={() => <AboutPage                isLoggedIn={isLoggedIn} mySlug={mySlug} />} layer="submodal" />
                      <Route path="/invitation"                   exact render={() => <Invitation               isLoggedIn={isLoggedIn} mySlug={mySlug} />} layer="modal" />
                      <Route path="/welcome"                      exact render={() => <WelcomePage              mySlug={mySlug} />} />
                      <Route path="/pricing"                      exact render={(props) => <PricingPage         {...props} />} />
                      <Route path="/terms-of-service"             exact render={(props) => <StaticPage          {...props} />} layer="modal" />
                      <Route path="/privacy-policy"               exact render={(props) => <StaticPage          {...props} />} layer="modal" />
                      <Route path="/user-data-deletion"           exact render={(props) => <StaticPage          {...props} />} layer="modal" />
                      <Route path="/markdown"                     exact render={(props) => <StaticPage          {...props} />} layer="modal" />
                      <Route path="/settings"                     exact render={(props) => <Settings            {...props} />} layer="submodal" />
                      <Route path="/k/create"                           render={(props) => <CreateContact       {...props} />} layer="submodal" />
                      <Route path="/k/:contactId/edit"                  render={(props) => <EditContact         {...props} />} layer="submodal" />
                      <Route path="/k/:contactId"                 exact render={(props) => <OneContact          {...props} />} layer="submodal" />
                      <Route path="/g/create"                           render={(props) => <CreateGroup         {...props} />} layer="submodal" />
                      <Route path="/g/:groupId/edit"                    render={(props) => <EditGroup           {...props} />} layer="submodal" />
                      <Route path="/g/:groupId"                   exact render={(props) => <MyGroupContacts     {...props} />} />
                      <Route path="/f/invite"                           render={(props) => <InviteFriend        {...props} />} layer="modal" />
                      <Route path="/a/create"                           render={(props) => <CreateAdvert        {...props} />} layer="submodal" />
                      <Route path="/a/:advertId/feedback"               render={(props) => <AddAdvertFeedback   {...props} />} layer="modal" />
                      <Route path="/a/:advertId/edit"                   render={(props) => <EditAdvert          {...props} />} layer="submodal" />
                      <Route path="/a/:advertId"                  exact render={(props) => <OneAdvert           {...props} />} layer="submodal" />
                      <Route path="/i/create"                           render={(props) => <CreateStaple        {...props} />} layer="submodal" />
                      <Route path="/i/:stapleId/:elemId/clone"          render={(props) => <CloneStaple         {...props} />} layer="submodal" />
                      <Route path="/i/:stapleId/:elemId/edit"           render={(props) => <EditStaple          {...props} />} layer="submodal" />
                      <Route path="/i/:stapleId/:elemId/:chatId"  exact render={(props) => <OneStaple           {...props} />} layer="submodal" />
                      <Route path="/i/:stapleId/:elemId"          exact render={(props) => <OneStaple           {...props} />} layer="submodal" />
                      <Redirect exact from="/i/:stapleId" to="/i/:stapleId/1" />
                      <Redirect exact from="/i/:stapleId/0" to="/i/:stapleId/1" />
                      <Route path="/co/create"                          render={(props) => <CreateCollection    collectionType={DEFAULT_COLLECTION} {...props} />} layer="submodal" />
                      <Route path="/co/:collectionId/edit"              render={(props) => <EditCollection      collectionType={DEFAULT_COLLECTION} {...props} />} layer="submodal" />
                      <Route path="/co/:collectionId/info"              render={(props) => <OneCollection       collectionType={DEFAULT_COLLECTION} {...props} />} layer="submodal" />
                      <Route path="/co/:collectionId"             exact render={(props) => <CollectionStaples   collectionType={DEFAULT_COLLECTION} {...props} />} />
                      <Route path="/crs/create"                         render={(props) => <CreateCollection    collectionType={COURSE_COLLECTION}  {...props} />} layer="submodal" />
                      <Route path="/crs/:collectionId/edit"             render={(props) => <EditCollection      collectionType={COURSE_COLLECTION}  {...props} />} layer="submodal" />
                      <Route path="/crs/:collectionId/info"             render={(props) => <OneCollection       collectionType={COURSE_COLLECTION}  {...props} />} layer="submodal" />
                      <Route path="/crs/:collectionId"            exact render={(props) => <CollectionStaples   collectionType={COURSE_COLLECTION}  {...props} />} />
                      {/* <Route path="/:userSlug/ads"                  render={(props) => <UserAdverts         {...props} />} /> /> */}
                      <Route path="/:userSlug/about"                    render={(props) => <OneUser             {...props} />} layer="submodal" />
                      <Route path="/:userSlug/collections"              render={(props) => <UserCollections     collectionType={DEFAULT_COLLECTION} {...props} />} />
                      <Route path="/:userSlug/courses"                  render={(props) => <UserCollections     collectionType={COURSE_COLLECTION}  {...props} />} />
                      <Route path="/:userSlug/items"                    render={(props) => <UserStaples         {...props} />} />
                      <Route path="/:userSlug/topics"                   render={(props) => <UserTopicStaples    {...props} />} />
                      <Route path="/:userSlug"                          render={(props) => <UserRedirectPage    mySlug={mySlug} {...props} />} />
                      <Route component={NotFoundPage} />
                    </LayerSwitch>
                  }
                </RootSidebar>
                {/* - - - bottom - - - */}
                {isLoggedIn && isMobile &&
                  <footer>
                    <Tabbar
                      myAccount={myAccount}
                      isSidebarOpen={isSidebarOpen}
                      fsrCount={fsrCount}
                      topicUnreadCount={topicUnreadCount}
                      communityUnreadCount={communityUnreadCount}
                      onToggleSidebar={this.handleToggleSidebar}
                    />
                  </footer>
                }
                {!isLoggedIn &&
                  <NoTabbar />
                }
                {/* - - - entry point: global area - - - */}
                {isMobile &&
                  <MobileMenu isLoggedIn={isLoggedIn}/>
                }
                <MediaViewer />
                <ColorTagsEditor />
                <CreateGradeMessage />
                <CreateMediaMessage />
                <ConfirmAction />
                <SelectAction />
                <SelectObjects />
                <HelpScreen />
                <Informer />
              </F>
            )
          }}
        </Media>
      </BrowserRouter>
    );
  }
}

export default Container.create(App, {pure: false});
