// -------------------------------------------------------------------------------------------------
//  commonTypes.js
//  - - - - - - - - - -
//  Common enumerated types.
// -------------------------------------------------------------------------------------------------

// database types:
export const TSN12_ID_LEN                       = 12;       				// довжина ідентифікатора TSN12 в БД
export const DEFAULT_TSN12_CURSOR     					= 'zzzzzzzzzzzz';   // дефолтний TSN12-курсор є свідченням того, що фід НЕ ініціалізований
export const TOPMOST_TSN12_CURSOR     					= 'zzzzzzzzzzzz';   // максимально-майбутній TSN12-курсор (найвищий)
export const BOTTOMMOST_TSN12_CURSOR  					= '';               // максимально-минулий TSN12-курсор (найнижчий)

export const DEFAULT_POS10_CURSOR     					= 'zzzzzzzzzz';     // дефолтний POS10-курсор є свідченням того, що фід НЕ ініціалізований
export const TOPMOST_POS10_CURSOR     					= 'zzzzzzzzzz';     // максимально-майбутній POS10-курсор (найвищий)
export const BOTTOMMOST_POS10_CURSOR  					= '';               // максимально-минулий POS10-курсор (найнижчий)

// object types:
export const COLLECTION_OBJECT        					= 'c';              // default collection type object
export const COURSE_OBJECT            					= 'crs';            // course collection type object
export const CONTACT_OBJECT           					= 'k';              // contact type object
export const GROUP_OBJECT             					= 'g';              // group type object
export const STAPLE_OBJECT            					= 's';              // staple type object

// collection types:
export const DEFAULT_COLLECTION  					      = '.';              // звичайна колекція (видимі всі публічні матеріали)
export const COURSE_COLLECTION   					      = 'c';              // курс-колекція (до декількох матеріалів з перевіркою)
export const SEQUENCE_COLLECTION   					    = 's';              // послідовна колекція (один матеріал за раз)
export const RANDOM_COLLECTION   					      = 'r';              // рендом-колекція (один випадковий матеріал за раз)
export const STAR_COLLECTION   			      		  = '*';              // стар-колекція (обʼєкти, що відмічені зірочкою)

// staple types:
export const DEFAULT_STAPLE     					      = '.';              // звичайний стейпл
export const LINK_STAPLE        					      = ',';              // лінк-стейпл (посилання на інші обʼєкти в системі)
export const SYSTEM_STAPLE  					          = '#';              // системний лінк-стейпл (створюється лише системою)

// privacy types:
export const PRIVATE_PRIVACY          					= '+';              // (Private) - тільки власник має доступ
export const PUBLIC_PRIVACY           					= '-';              // (Public) - будь-хто має доступ
export const MISC_PRIVACY             					= '.';              // (Misc) - будь-хто має доступ

// icons & symbols:
export const STAR_ICON       					          = '⭐';              // default star icon

// align types:
export const alignTypes = {
  TOP:                  't',
  RIGHT:                'r',
  BOTTOM:               'b',
  LEFT:                 'l',
  CENTER:               'c',
};

// global css-styles:
export const MARKDOWN_CSS                       = 'markdown';       // class '.markdown' in markdown.scss
export const SELECTABLE_CSS                     = 'selectable';     // class '.selectable' in markdown.scss

// localStorage:
export const LS_PRESET_SUBJECT_IDS              = 'lvpji';          // зберігає список ідентифікаторів предметів по замовчуванню
export const LS_PRESET_COLLECTION_IDS           = 'lvpci';          // зберігає список ідентифікаторів колекцій по замовчуванню
export const LS_PRESET_GROUP_IDS                = 'lvpgi';          // зберігає список ідентифікаторів груп по замовчуванню
export const LS_PREV_BASE_LOCATION              = 'lvpbl';          // previous base location
export const LS_PREV_SUBMODAL_LOCATION          = 'lvpsl';          // previous submodal location
export const LS_LAST_SOCIALFEED                 = 'lvlsf';          // тип фіду, вибраний користувачем за адресою '/'
export const LS_LAST_USERFEED                   = 'lvluf';          // тип фіду, вибраний користувачем за адресою '/:userSlug'
export const LS_LAST_MYFEED                     = 'lvlmf';          // тип фіду, вибраний користувачем за адресою '/:mySlug'
export const LS_LAST_STAPLE_COMMENTS_OPEN       = 'lvsce';          // last editor's staple comments field open state
export const LS_LAST_COLLECTION_COMMENTS_OPEN   = 'lvcce';          // last editor's collection comments field open state
export const LS_LAST_VIEWER_COMMENTS_OPEN       = 'lvcov';          // last viewer's comments field open state

export const LS_LOCALE                          = 'locale';         // змінна, в якій зберігається поточна локаль
export const LS_SETTINGS                        = 'settings';       // змінна, в якій зберігається назва сетінгів

// misc:
export const MONTH                              = 'm';              // billing period = month
export const YEAR                               = 'y';              // billing period = year
