// -------------------------------------------------------------------------------------------------
//  apiFields.js
//  - - - - - - - - - -
//  Загальний перелік скорочень назв полів (для API).
// -------------------------------------------------------------------------------------------------

export const DISCOVER_FLD                           = 'dis';        // 'discover'
export const FEATURED_FLD                           = 'frd';        // 'featured'
export const PUBLISHED_FLD                          = 'pbl';        // 'published'

export const ACCOUNT_FLD                            = 'acc';        // 'account'
export const ACCOUNT_TYPE_FLD                       = 'acct';       // 'account_type'
export const BILLING_TYPE_FLD                       = 'bt';         // 'billing_type'
export const ADVERT_FLD                             = 'a';          // 'advert'
export const ADVERT_ID_FLD                          = 'ai';         // 'advert_id'
export const ADVERT_IDS_FLD                         = 'aix';        // 'advert_ids'
export const ADVERTS_FLD                            = 'ax';         // 'adverts'
export const STAPLE_FLD                             = 's';          // 'staple'
export const STAPLE_ID_FLD                          = 'si';         // 'staple_id'
export const STAPLE_IDS_FLD                         = 'six';        // 'staple_ids'
export const STAPLE_FIELDS_FLD                      = 'sfx';        // 'staple_fields'
export const STAPLES_FLD                            = 'sx';         // 'staples'
export const STAPLEDATA_FLD                         = 'sd';         // 'stapledata'
export const STAPLEDATAS_FLD                        = 'sdx';        // 'stapledatas'
export const STAPLECOLLECTION_FLD                   = 'sc';         // 'staplecollection'
export const STAPLECOLLECTIONS_FLD                  = 'scx';        // 'staplecollections'
export const COLLECTION_FLD                         = 'c';          // 'collection'
export const COLLECTION_ID_FLD                      = 'ci';         // 'collection_id'
export const COLLECTION_IDS_FLD                     = 'cix';        // 'collection_ids'
export const COLLECTION_FIELDS_FLD                  = 'cfx';        // 'collection_fields'
export const COLLECTIONS_FLD                        = 'cx';         // 'collections'
export const COURSE_FLD                             = 'crs';        // 'course'
export const COURSE_ID_FLD                          = 'crsi';       // 'course_id'
export const COURSE_IDS_FLD                         = 'crsix';      // 'course_ids'
export const COURSES_FLD                            = 'crsx';       // 'courses'
export const CSUBSCRIPTION_FLD                      = 'ss';         // 'csubscription'
export const CSUBSCRIPTION_ID_FLD                   = 'ssi';        // 'csubscription_id'
export const CSUBSCRIPTION_IDS_FLD                  = 'ssix';       // 'csubscription_ids'
export const CSUBSCRIPTION_STATUS_FLD               = 'ssst';       // 'csubscription_status'
export const CSUBSCRIPTION_OWNER_ID_FLD             = 'sso';        // 'csubscription_owner_id'
export const CSUBSCRIPTION_ACCESS_COST_FLD          = 'sscca';      // 'csubscription_access_cost'
export const CSUBSCRIPTION_PAID_AMOUNT_FLD          = 'sspda';      // 'csubscription_paid_amount'
export const CSUBSCRIPTION_CREATED_AT_FLD           = 'sstc';       // 'csubscription_created_at'
export const CSUBSCRIPTION_STARTED_AT_FLD           = 'ssts';       // 'csubscription_started_at'
export const CSUBSCRIPTION_ENDED_AT_FLD             = 'sste';       // 'csubscription_ended_at'
export const CSUBSCRIPTIONS_FLD                     = 'ssx';        // 'csubscriptions'
export const CSUBSCRIBED_COLLECTION_FLD             = 'ssc';        // 'csubscribed_collection'
export const CSUBSCRIBED_COLLECTION_ID_FLD          = 'ssci';       // 'csubscribed_collection_id'
export const CSUBSCRIBED_COLLECTION_IDS_FLD         = 'sscix';      // 'csubscribed_collection_ids'
export const CSUBSCRIBED_COLLECTIONS_FLD            = 'sscx';       // 'csubscribed_collections'
export const MASTER_COLLECTION_FLD                  = 'mc';         // 'master_collection'
export const MASTER_COLLECTION_ID_FLD               = 'mci';        // 'master_collection_id'
export const MASTER_COLLECTION_IDS_FLD              = 'mcix';       // 'master_collection_ids'
export const MASTER_COLLECTIONS_FLD                 = 'mcx';        // 'master_collections'
export const LANGUAGE_ID_FLD                        = 'lc';         // 'language_id' = lc/locale
export const LANGUAGES_FLD                          = 'lcx';        // 'languages' = lcx/locales
export const COUNTRY_FLD                            = 'y';          // 'country'
export const COUNTRY_ID_FLD                         = 'yi';         // 'country_id'
export const COUNTRY_IDS_FLD                        = 'yix';        // 'country_ids'
export const COUNTRIES_FLD                          = 'yx';         // 'countries'
export const CATEGORY_FLD                           = 'cg'          // 'category'
export const CATEGORY_ID_FLD                        = 'cgi'         // 'category_id'
export const CATEGORY_IDS_FLD                       = 'cgix'        // 'category_ids'
export const CATEGORIES_FLD                         = 'cgx'         // 'categories'
export const INTEREST_FLD                           = 'it';         // 'interest'
export const INTEREST_ID_FLD                        = 'iti';        // 'interest_id'
export const INTEREST_IDS_FLD                       = 'itix';       // 'interest_ids'
export const INTERESTS_FLD                          = 'itx';        // 'interests'
export const SUBJECT_FLD                            = 'j';          // 'subject'
export const SUBJECT_ID_FLD                         = 'ji';         // 'subject_id'
export const SUBJECT_IDS_FLD                        = 'jix';        // 'subject_ids'
export const SUBJECTS_FLD                           = 'jx';         // 'subjects'
export const SKILL_FLD                              = 'sk';         // 'skill'
export const SKILL_ID_FLD                           = 'ski';        // 'skill_id'
export const SKILL_IDS_FLD                          = 'skix';       // 'skill_ids'
export const SKILLS_FLD                             = 'skx';        // 'skills'
export const LEVEL_FLD                              = 'lv';         // 'level'
export const LEVEL_ID_FLD                           = 'lv';         // 'level_id' = same as 'level' !!!
export const LEVEL_IDS_FLD                          = 'lvx';        // 'level_ids' = same as 'levels' !!!
export const LEVELS_FLD                             = 'lvx';        // 'levels'
export const RATING_FLD                             = 'rt';         // 'rating'
export const RATING_1_QTY_FLD                       = 'r1q';        // 'rating_1_qty'
export const RATING_2_QTY_FLD                       = 'r2q';        // 'rating_2_qty'
export const RATING_3_QTY_FLD                       = 'r3q';        // 'rating_3_qty'
export const RATING_4_QTY_FLD                       = 'r4q';        // 'rating_4_qty'
export const RATING_5_QTY_FLD                       = 'r5q';        // 'rating_5_qty'
export const REACTION_FLD                           = 're';         // 'reaction'
export const REACTION_ID_FLD                        = 'rei';        // 'reaction_id'
export const REACTION_IDS_FLD                       = 'reix';       // 'reaction_ids'
export const REACTIONS_FLD                          = 'rex';        // 'reactions'
export const REVIEW_FLD                             = 'rv';         // 'review'
export const REVIEW_ID_FLD                          = 'rvi';        // 'review_id'
export const REVIEW_IDS_FLD                         = 'rvix';       // 'review_ids'
export const REVIEWS_QTY_FLD                        = 'rvq';        // 'reviews_qty'
export const REVIEWS_FLD                            = 'rvx';        // 'reviews'
export const GRADE_FLD                              = 'gr';         // 'grade'
export const GRADE_ID_FLD                           = 'gri';        // 'grade_id'
export const GRADE_TYPE_FLD                         = 'grt';        // 'grade_type'
export const GRADES_FLD                             = 'grx';        // 'grades'
export const STAPLE_GRADE_FLD                       = 'sgr';        // 'staple_grade'
export const STAPLE_GRADE_ID_FLD                    = 'sgri';       // 'staple_grade_id'
export const STAPLE_GRADE_TYPE_FLD                  = 'sgrt';       // 'staple_grade_type'
export const STAPLE_GRADES_FLD                      = 'sgrx';       // 'staple_grades'
export const STATICPAGE_FLD                         = 'sp';         // 'staticpage'
export const STATICPAGES_FLD                        = 'spx';        // 'staticpages'
export const PARENT_FLD                             = 'pt'          // 'parent'
export const PARENT_ID_FLD                          = 'pti'         // 'parent_id'
export const PARENT_NAME_FLD                        = 'ptn'         // 'parent_name'
export const PARENT_POSITION_FLD                    = 'ptz'         // 'parent_position'
export const ADDRESS_FLD                            = 'ad';         // 'address'
export const ADDRESS_ID_FLD                         = 'adi';        // 'address_id'
export const ADDRESS_IDS_FLD                        = 'adix';       // 'address_ids'
export const ADDRESSES_FLD                          = 'adx';        // 'addresses'
export const PHONE_FLD                              = 'pn';         // 'phone'
export const PHONE_ID_FLD                           = 'pni';        // 'phone_id'
export const PHONE_IDS_FLD                          = 'pnix';       // 'phone_ids'
export const PHONES_FLD                             = 'pnx';        // 'phones'
export const EMAIL_FLD                              = 'em';         // 'email'
export const EMAIL_ID_FLD                           = 'emi';        // 'email_id'
export const EMAIL_IDS_FLD                          = 'emix';       // 'email_ids'
export const EMAILS_FLD                             = 'emx';        // 'emails'
export const URL_FLD                                = 'ur';         // 'url'
export const URL_ID_FLD                             = 'uri';        // 'url_id'
export const URL_IDS_FLD                            = 'urix';       // 'url_ids'
export const URLS_FLD                               = 'urx';        // 'urls'
export const CONTACT_FLD                            = 'k';          // 'contact'
export const CONTACT_ID_FLD                         = 'ki';         // 'contact_id'
export const CONTACT_IDS_FLD                        = 'kix';        // 'contact_ids'
export const CONTACT_FIELDS_FLD                     = 'kfx';        // 'contact_fields'
export const CONTACTS_FLD                           = 'kx';         // 'contacts'
export const GROUP_FLD                              = 'g';          // 'group'
export const GROUP_ID_FLD                           = 'gi';         // 'group_id'
export const GROUP_IDS_FLD                          = 'gix';        // 'group_ids'
export const GROUP_FIELDS_FLD                       = 'gfx';        // 'group_fields'
export const GROUPS_FLD                             = 'gx';         // 'groups'
export const FRIEND_FLD                             = 'f';          // 'friend'
export const FRIEND_ID_FLD                          = 'fi';         // 'friend_id'
export const FRIEND_IDS_FLD                         = 'fix';        // 'friend_ids'
export const FRIENDS_FLD                            = 'fx';         // 'friends'
export const FRIENDSHIP_REQUESTS_IN_FLD             = 'fsrxi';      // 'friendship_requests_in'
export const FRIENDSHIP_REQUESTS_OUT_FLD            = 'fsrxo';      // 'friendship_requests_out'
export const FRIENDSHIP_STATUS_FLD                  = 'fss';        // 'friendship_status'
export const DIRECT_USERS_FLD                       = 'dux';        // 'direct_users'
export const USER_FLD                               = 'u';          // 'user'
export const USER_ID_FLD                            = 'ui';         // 'user_id'
export const USER_IDS_FLD                           = 'uix';        // 'user_ids'
export const USER_SLUG_FLD                          = 'ul';         // 'user_slug'
export const USER_ALIAS_FLD                         = 'uas';        // 'user_alias'
export const USER_AVATAR_SOURCE_URL_FLD             = 'uau';        // 'user_avatar_source_url'
export const USER_XHASH2_FLD                        = 'ux2';        // 'user_xhash2_url'
export const USER_EXT_FLD                           = 'uxt';        // 'user_ext_url'
export const USER_PREVIEW_SS_URL_FLD                = 'ups';        // 'user_avatar_preview_ss_url'
export const USER_PREVIEW_MD_URL_FLD                = 'upm';        // 'user_avatar_preview_md_url'
export const USER_PREVIEW_XL_URL_FLD                = 'upl';        // 'user_avatar_preview_xl_url'
export const USERS_FLD                              = 'ux';         // 'users'

export const ID_FLD                                 = 'i';          // 'id'
export const IDS_FLD                                = 'ix';         // 'ids'
export const OWNER_ID_FLD                           = 'o';          // 'owner_id'
export const AUTHOR_ID_FLD                          = 'a';          // 'author_id'
export const AUTHOR_SLUG_FLD                        = 'al';         // 'author_slug'
export const AUTHOR_ALIAS_FLD                       = 'aas';        // 'author_alias'
export const ANCESTOR_FLD                           = 'an';         // 'ancestor'
export const ANCESTOR_ID_FLD                        = 'ani';        // 'ancestor_id'
export const CHAT_FLD                               = 'h';          // 'chat'
export const CHAT_ID_FLD                            = 'h';          // 'chat_id'
export const CHAT_IDS_FLD                           = 'hx';         // 'chat_ids'
export const CHAT_TYPE_FLD                          = 'ht';         // 'chat_type'
export const CHAT_STATUS_FLD                        = 'hs';         // 'chat_status'
export const CHATS_FLD                              = 'hx';         // 'chats'
export const DIRECT_CHATS_FLD                       = 'dhx';        // 'direct_chats'
export const DIRECT_CHAT_ID_FLD                     = 'dhi';        // 'direct_chat_id'
export const CONTEXT_FLD                            = 'ctx';        // 'context'
export const CONTEXT_ID_FLD                         = 'ctxi';       // 'context_id'
export const CONTEXT_TYPE_FLD                       = 'ctxt';       // 'context_type'
export const CONTEXTDATA_FLD                        = 'ctxd';       // 'contextdata'
export const CONTEXTDATAS_FLD                       = 'ctxd';       // 'contextdatas'
export const CONTEXTS_FLD                           = 'ctxx';       // 'contexts'
export const MEMBER_FLD                             = 'mb';         // 'member'
export const MEMBER_ID_FLD                          = 'mbi';        // 'member_id'
export const MEMBER_IDS_FLD                         = 'mbix';       // 'member_ids'
export const MEMBERS_FLD                            = 'mbx';        // 'members'
export const MESSAGE_FLD                            = 'm';          // 'message'
export const MESSAGE_ID_FLD                         = 'm';          // 'message_id'
export const MESSAGE_IDS_FLD                        = 'mx';         // 'message_ids'
export const MESSAGES_FLD                           = 'mx';         // 'messages'
export const LAST_MESSAGE_ID_FLD                    = 'lm';         // 'last_message_id'
export const LAST_MESSAGE_TYPE_FLD                  = 'lmt';        // 'last_message_type'
export const LAST_MESSAGE_BODY_FLD                  = 'lmb';        // 'last_message_body'
export const LAST_MESSAGE_ATTACHMENTS_FLD           = 'lmx';        // 'last_message_attachments'
export const UNREAD_MESSAGE_FLD                     = 'um';         // 'unread_message'
export const UNREAD_MESSAGE_ID_FLD                  = 'um';         // 'unread_message_id'
export const UNREAD_MESSAGE_IDS_FLD                 = 'umx';        // 'unread_message_ids'
export const UNREAD_MESSAGES_FLD                    = 'umx';        // 'unread_messages'
export const PROCESSED_IDS_FLD                      = '=ix';        // 'processed_ids'
export const FAILED_IDS_FLD                         = '!ix';        // 'failed_ids'

export const CALL_FLD                               = 'call';       // 'call'
export const CALL_ID_FLD                            = 'call_id';    // 'call_id'
export const CALL_IDS_FLD                           = 'call_ids';   // 'call_ids'
export const CALLS_FLD                              = 'calls';      // 'calls'
export const CALLER_FLD                             = 'caller';     // 'caller'
export const CALLER_ID_FLD                          = 'caller_id';  // 'caller_id'
export const CALLEE_FLD                             = 'callee';     // 'callee'
export const CALLEE_ID_FLD                          = 'callee_id';  // 'callee_id'
export const SIGNAL_DATA_FLD                        = 'data';       // 'data'
export const SIGNALS_FLD                            = 'signals';    // 'signals'
export const REASON_FLD                             = 'reason';     // 'reason'
export const ROOM_FLD                               = 'r';          // 'room'
export const ROOM_ID_FLD                            = 'ri';         // 'room_id'
export const ROOM_IDS_FLD                           = 'rix';        // 'room_ids'
export const ROOMS_FLD                              = 'rx';         // 'rooms'

export const SLINE_FLD                              = 'l';          // 'sline'
export const SLUG_FLD                               = 'l';          // 'slug'
export const SLUGS_FLD                              = 'lx';         // 'slugs'
export const VALUE_FLD                              = 'v';          // 'value'
export const TYPE_FLD                               = 't';          // 'type'
export const ALIAS_FLD                              = 'as';         // 'alias'
export const NAME_FLD                               = 'n';          // 'name'
export const FIRST_NAME_FLD                         = 'n1';         // 'first_name'
export const MIDDLE_NAME_FLD                        = 'n2';         // 'middle_name'
export const LAST_NAME_FLD                          = 'n3';         // 'last_name'
export const LABEL_FLD                              = 'l';          // 'label'
export const ATTACHMENTS_FLD                        = 'x';          // 'attachments'
export const DESCRIPTION_FLD                        = 'd';          // 'description'
export const DETAILS_FLD                            = 'dx';         // 'details'
export const BODY_FLD                               = 'b';          // 'body'
export const CORE_FLD                               = 'cr';         // 'core'
export const CORES_FLD                              = 'crx';        // 'core'
export const COMMENTS_FLD                           = 'cs';         // 'comments'
export const COMMENTS_LIST_FLD                      = 'csx';        // 'comments_list'
export const CONTENT_FLD                            = 'cn';         // 'content'
export const CONTENT_ID_FLD                         = 'cni';        // 'content_id'
export const CONTENT_TYPE_FLD                       = 'cnt';        // 'content_type'
export const CONTENTS1_FLD                          = 'cn1';        // 'contents1'
export const CONTENTS2_FLD                          = 'cn2';        // 'contents2'
export const ELEMENT_FLD                            = 'el';         // 'element'
export const ELEMENTS_FLD                           = 'ex';         // 'elements'
export const ELEMENTS1_FLD                          = 'e1';         // 'elements1'
export const ELEMENTS2_FLD                          = 'e2';         // 'elements2'
export const EMBED_CODE_FLD                         = 'ec';         // 'embed_code'
export const EMBED_TYPE_FLD                         = 'et';         // 'embed_type'
export const ORIGIN_URL_FLD                         = 'ou';         // 'origin_url'
export const DOMAIN_FLD                             = 'domain';     // 'domain'
export const SOURCE_FLD                             = 'src';        // 'source'
export const PRICE_FLD                              = 'price';      // 'price'
export const PRICE_RANGE_FLD                        = 'dp';         // 'price_range'
export const BUSINESS_HOURS_FLD                     = 'dh';         // 'business_hours'
export const COINS_AMOUNT_FLD                       = 'ccq';        // 'coins_amount'
export const EVENT_FLD                              = 'ev';         // 'event'
export const EVENT_TYPE_FLD                         = 'evt';        // 'event_type'
export const ZIP_CODE_FLD                           = 'zip';        // 'zip_code'
export const DATA_FLD                               = 'data';       // 'data'
export const MISC_FLD                               = 'misc';       // 'misc'
export const SIZE_ON_DISK_FLD                       = 'sz';         // 'size_on_disk'

export const AVATAR_FLD                             = 'av';         // 'avatar'
export const AVATAR_ID_FLD                          = 'avi';        // 'avatar_id'
export const AVATAR_IDS_FLD                         = 'avix';       // 'avatar_ids'
export const AVATAR_SOURCE_URL_FLD                  = 'au';         // 'avatar_source_url'
export const AVATAR_XHASH2_FLD                      = 'x2';         // 'avatar_xhash2' = same as 'xhash2' !!!
export const AVATAR_EXT_FLD                         = 'xt';         // 'avatar_ext' = same as 'ext' !!!
export const AVATARS_FLD                            = 'avx';        // 'avatars'
export const DOCUMENT_ID_FLD                        = 'di';         // 'document_id'
export const DOCUMENT_TYPE_FLD                      = 'dt';         // 'document_type'
export const DOCUMENT_XHASH2_FLD                    = 'dx2';        // 'document_xhash2'
export const DOCUMENT_EXT_FLD                       = 'dxt';        // 'document_ext'
export const PICTURE_ID_FLD                         = 'pi';         // 'picture_id'
export const PICTURE_SOURCE_URL_FLD                 = 'pu';         // 'picture_source_url'
export const PICTURE_XHASH2_FLD                     = 'x2';         // 'picture_xhash2' = same as 'xhash2' !!!
export const PICTURE_EXT_FLD                        = 'xt';         // 'picture_ext' = same as 'ext' !!!
export const PICTURES_FLD                           = 'px';         // 'pictures'
export const VIDEO_XHASH2_FLD                       = 'vx2';        // 'video_xhash2'
export const VIDEO_EXT_FLD                          = 'vxt';        // 'video_ext'
export const XHASH2_FLD                             = 'x2';         // 'xhash2'
export const XHASH1_FLD                             = 'x1';         // 'xhash1'
export const EXT_FLD                                = 'xt';         // 'ext'
export const BLOB_FLD                               = 'blob';       // 'blob'
export const BLOBS_FLD                              = 'blobs';      // 'blobs'

export const PREVIEW_SS_URL_FLD                     = 'ps';         // 'preview_ss_url'
export const PREVIEW_MD_URL_FLD                     = 'pm';         // 'preview_md_url'
export const PREVIEW_XL_URL_FLD                     = 'pl';         // 'preview_xl_url'

export const STAPLES_QTY_FLD                        = 'sq';         // 'staples_qty'
export const COLLECTIONS_QTY_FLD                    = 'cq';         // 'collections_qty'
export const CONTACTS_QTY_FLD                       = 'kq';         // 'contacts_qty'
export const GROUPS_QTY_FLD                         = 'gq';         // 'groups_qty'
export const ADVERTS_QTY_FLD                        = 'aq';         // 'total_adverts_qty'
export const MASTERS_QTY_FLD                        = 'mq';         // 'total_masters_qty'
export const FOLLOWERS_QTY_FLD                      = 'fq';         // 'total_followers_qty'
export const ALL_COLLECTIONS_FLD                    = 'alcx';       // 'all_collections'
export const PUBLIC_COLLECTIONS_FLD                 = 'pbcx';       // 'public_collections'
export const PUBLIC_COLLECTIONS_QTY_FLD             = 'pbcq';       // 'public_collections_qty'
export const PRIVATE_COLLECTIONS_QTY_FLD            = 'prcq';       // 'private_collections_qty'
export const VISITS_COUNT_FLD                       = 'vq';         // 'visits_count'
export const USAGE_COUNT_FLD                        = 'uq';         // 'usage_count'

export const ACCESS_COST_FLD                        = 'cca';        // 'access_cost'
export const PAID_AMOUNT_FLD                        = 'pda';        // 'paid_amount'
export const INITIAL_DROP_SIZE_FLD                  = 'dr0';        // 'initial_drop_size'
export const RECURRING_DROP_SIZE_FLD                = 'drs';        // 'recurring_drop_size'
export const NEXT_DROP_DELAY_FLD                    = 'drd';        // 'next_drop_delay'

export const CREATED_AT_FLD                         = 'tc';         // 'time of creation
export const UPDATED_AT_FLD                         = 'tu';         // 'time of updated
export const ENDED_AT_FLD                           = 'te';         // 'time of ending
export const EXPIRED_AT_FLD                         = 'te';         // 'time of expiration
export const DELETED_AT_FLD                         = 'td';         // 'time of deletion
export const STARTED_AT_FLD                         = 'ts';         // 'time of start
export const FINISHED_AT_FLD                        = 'tf';         // 'time of finish
export const PUBLISHED_AT_FLD                       = 'tp';         // 'time of publishing

export const HAS_UNREAD_MESSAGES_FLD                = '_m';         // 'has_unread_messages'
export const HAS_STAR_COLLECTION_FLD                = '_strc';      // 'has_star_collection'
export const HAS_PUBLIC_COLLECTIONS_FLD             = '_pbcx';      // 'has_public_collections'
export const HAS_PRIVATE_COLLECTIONS_FLD            = '_prcx';      // 'has_private_collections'

export const IS_PUBLIC_FLD                          = 'p';          // 'is_public'
export const IS_PUBLISHED_FLD                       = 'pb';         // 'is_published'
export const IS_OWNER_FLD                           = '_o';         // 'is_owner'
export const IS_ADMIN_FLD                           = '_a';         // 'is_admin'
export const IS_WRITER_FLD                          = '_w';         // 'is_writer'
export const IS_READER_FLD                          = '_r';         // 'is_reader'
export const IS_SUBSCRIBED_FLD                      = '_s';         // 'is_subscribed'
export const IS_BLOCKED_FLD                         = '_b';         // 'is_blocked'
export const IS_BANNED_FLD                          = '_n';         // 'is_banned'
export const IS_FOLLOWED_FLD                        = '_l';         // 'is_followed'
export const IS_UPDATED_FLD                         = '_z';         // 'is_updated'
export const IS_EDITED_FLD                          = '_e';         // 'is_edited'
export const IS_DELETED_FLD                         = '_d';         // 'is_deleted'
export const IS_RESTORABLE_FLD                      = '_u';         // 'is_restorable'
export const IS_CLONABLE_FLD                        = '_c';         // 'is_clonable'
export const IS_STARRED_FLD                         = '_s';         // 'is_starred'
export const IS_READ_FLD                            = '_r';         // 'is_read'
export const IS_SOLD_FLD                            = '_y';         // 'is_sold'
export const IS_MINTED_FLD                          = '_m';         // 'is_minted'
export const IS_FRIEND_FLD                          = '_f';         // 'is_friend'
export const IS_VERIFIED_FLD                        = '_v';         // 'is_verified'
export const IS_HELP_MODE_FLD                       = '_h';         // 'is_help_mode'
export const IS_PAID_FLD                            = '_p';         // 'is_paid'
export const IS_PROFILE_LOADED_FLD                  = '_p';         // 'is_profile_loaded'
export const IS_HIDDEN_FLD                          = '_h';         // 'is_hidden'
export const IS_SELECTED_FLD                        = '_x';         // 'is_selected'

export const PREV_POSITION_FLD                      = 'z0';         // 'prev_position'
export const PREV_POSITION_X_FLD                    = 'zx0';        // 'prev_position_x'
export const PREV_POSITION_Y_FLD                    = 'zy0';        // 'prev_position_y'

export const POSITION_FLD                           = 'z';          // 'position'
export const POSITION2_FLD                          = 'z2';         // 'position2'
export const POSITION_X_FLD                         = 'zx';         // 'position_x'
export const POSITION_Y_FLD                         = 'zy';         // 'position_y'
export const COLOR_TAGS_FLD                         = 'zt';         // 'color_tags'
export const COLOR_LABELS_FLD                       = 'zl';         // 'color_labels'

export const STATUS_FLD                             = 's';          // 'status'
export const STATE_FLD                              = 'st';         // 'state'
export const CURSOR_FLD                             = 'v';          // 'bottom_cursor'
export const ORDER_FLD                              = 'o';          // 'order'
export const LIMIT_FLD                              = 'l';          // 'limit'
