// -------------------------------------------------------------------------------------------------
//  MyWorkspaceStaplesContainer.js
//  - - - - - - - - - -
//  Контейнер стрічки стейплів дешборда.
//  Фід є гібридом двох фідів: CollectionStaple + UserStaple !!!
//
//  Attn:
//  - - - - -
//  - ідентифікатор стар-колекції формується з переданого в параметрі роутера myId --> starCollectionId;
//  - при вході в стар-колекцію контекст сайдбару встановлюємо на закладку колекцій (COLLECTION_TAB);
//  - функція getCollectionStaples віддає стейпли тільки ДО курсора, щоб не було "пропусків" в фіді;
// -------------------------------------------------------------------------------------------------
import React from 'react';
import Types from 'prop-types';
import {Container} from 'flux/utils';
import {List, Map, Set, OrderedSet, is} from 'immutable';
import {DEFAULT_POS10_CURSOR} from 'core/commonTypes';
import {STAPLE_CTX} from 'core/communicationTypes';
import {COLLECTION_TAB, ACTION_TAB, CHAT_TAB, SELECTION_TAB, MY_WORKSPACE_STAPLES_FEED} from 'core/uiTypes';
import {setAllContactsUnselected} from 'actions/ContactActions';
import {substituteSidebarContext, substituteSidebarChat, substituteSidebarTabMode} from 'actions/LayoutActions';
import {setAllStaplesUnselected, fetchCollectionStaples} from 'actions/StapleActions';
import {fetchUsers} from 'actions/UserActions';
import AccountStore from 'stores/AccountStore';
import ChatStore from 'stores/ChatStore';
import CrmStore from 'stores/CrmStore';
import CollectionStore from 'stores/CollectionStore';
import StapleStore from 'stores/StapleStore';
import UserStore from 'stores/UserStore';
import {composeStarCollectionId} from 'utils/converters';
import Loader from 'utils/ComponentLoader';

const MyWorkspaceStaples = Loader.create(() => import('./MyWorkspaceStaples'));
const isVerbose = DEBUG && true;
const prefix = '- - - MyWorkspaceStaplesContainer';

function trace(msg, ...other) { if (isVerbose) { console.log(`${prefix}.${msg}`, ...other); }}
function traceWarn(msg, ...other) { if (isVerbose) { console.warn(`${prefix}.${msg}`, ...other); }}
function traceError(msg, ...other) { console.error(`${prefix}.${msg}`, ...other); }

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
class MyWorkspaceStaplesContainer extends React.Component {
  static contextTypes = {
    router: Types.object.isRequired,
  }

  state = {}

  static getStores() {
    trace(`getStores`);
    return [AccountStore, ChatStore, CollectionStore, CrmStore, StapleStore, UserStore];
  }

  static calculateState(prevState, props) {
    trace(`calculateState`);
    const {myId} = props;
    const starCollectionId = composeStarCollectionId(myId);
    const feedType = MY_WORKSPACE_STAPLES_FEED; // attn: a) !!!
    const {[feedType]:viewMode, isSidebarOpen} = AccountStore.getUISettings();
    const isLoggedIn = AccountStore.isLoggedIn();
    const staples = starCollectionId ? StapleStore.getCollectionStaples(starCollectionId) : OrderedSet();
    const owners = UserStore.getSomeUsersMap(staples.reduce((a, s) => a.add(s.ownerId).add(s.authorId), Set().add(myId)));
    const relatedCollections = staples && staples.size > 0 ?
      staples.reduce((acc, currStaple) => {
        const localColls = currStaple.lstCollections || [];
        return localColls.reduce((localAccumulator, currColl) => {
          return localAccumulator.setIn([currColl.i], currColl);
        }, acc);
      }, Map())
        .toList()
        .sort((a, b) => { return ((a.t + a.n.toLowerCase()) < (b.t + b.n.toLowerCase())) ? -1 : 1; }) : // attn: sorting collections by type + name !!!
      List();
    const fsrCount = UserStore.getFSRequestCount();
    const {groupsQty, contactsQty} = CrmStore.getQts();
    const unreadCountsByStaple = isLoggedIn ? ChatStore.getUnreadCountsByCtxType(STAPLE_CTX) : Map();
    const stapleCursor = starCollectionId ? StapleStore.getCollectionStapleCursor(starCollectionId) : DEFAULT_POS10_CURSOR;
    const areStaplesLoaded = starCollectionId ? StapleStore.areCollectionStaplesLoaded(starCollectionId) : false;
    const hasAnySelected = myId ? StapleStore.hasSelectedStaples() || CrmStore.hasSelectedContacts() : false;
    return {
      isLoggedIn, myId, owners, staples, starCollectionId, relatedCollections,
      fsrCount, groupsQty, contactsQty, unreadCountsByStaple, feedType, viewMode,
      isSidebarOpen, stapleCursor, areStaplesLoaded, hasAnySelected
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    const result = this.state.isLoggedIn !== nextState.isLoggedIn
      || this.state.myId !== nextState.myId
      || this.state.starCollectionId !== nextState.starCollectionId
      || this.state.viewMode !== nextState.viewMode
      || this.state.isSidebarOpen !== nextState.isSidebarOpen
      || this.state.hasAnySelected !== nextState.hasAnySelected
      || this.state.fsrCount !== nextState.fsrCount
      || this.state.groupsQty !== nextState.groupsQty
      || this.state.contactsQty !== nextState.contactsQty
      || this.state.stapleCursor !== nextState.stapleCursor
      || this.state.areStaplesLoaded !== nextState.areStaplesLoaded
      || this.state.relatedCollections.size !== nextState.relatedCollections.size
      || this.state.staples.size !== nextState.staples.size
      || this.state.owners.size !== nextState.owners.size
      || this.state.feedType !== nextState.feedType
      || !is(this.state.unreadCountsByStaple, nextState.unreadCountsByStaple)
      || !is(this.state.staples, nextState.staples)
      || !is(this.state.owners, nextState.owners);
    trace(`shouldComponentUpdate: ${result}`);
    return result;
  }

  async componentDidMount() {
    trace(`componentDidMount`);
    const {myId} = this.props;
    if (myId && !UserStore.isUserLoaded(myId)) {
      await fetchUsers([myId]);
    }
    this.setSidebar();
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    trace(`UNSAFE_componentWillReceiveProps`);
    const {myId} = this.props;
    if (myId && !UserStore.isUserLoaded(myId)) {
      await fetchUsers([myId]);
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    trace(`componentDidUpdate`);
    this.setSidebar();
  }

  // CTX: Attn: 1)
  setSidebar = async () => {
    trace(`setSidebar`);
    const {hasAnySelected} = this.state;
    await substituteSidebarTabMode(!hasAnySelected ? COLLECTION_TAB : SELECTION_TAB);
    await substituteSidebarContext({ctxId: '', ctxType: '', ctxOwnerId: ''});
    await substituteSidebarChat({chatId: ''});
  }

  handleUnselectAll = () => {
    trace(`handleUnselectAll`);
    setAllContactsUnselected();
    setAllStaplesUnselected();
  }

  isFetchingCollectionStaples = false;

  fetchWorkspaceStaplesPage = async () => {
    trace(`fetchWorkspaceStaplesPage(1): isFetching=${this.isFetchingCollectionStaples}`);
    const {starCollectionId, stapleCursor = DEFAULT_POS10_CURSOR} = this.state;
    if (starCollectionId && !this.isFetchingCollectionStaples) {
      trace(`fetchWorkspaceStaplesPage(2): starCollectionId=${starCollectionId}, cursor='${stapleCursor}'`);
      this.isFetchingCollectionStaples = true;
      await fetchCollectionStaples({collectionId:starCollectionId, cursor:stapleCursor, limit:STAPLES_FEED_FETCH_LIMIT});
      this.isFetchingCollectionStaples = false;
    }
  }

  render() {
    const pathname = this.props.location.pathname;
    const {
      isLoggedIn,
      myId,
      owners,
      staples,
      starCollectionId,
      relatedCollections,
      unreadCountsByStaple,
      feedType,
      viewMode,
      fsrCount,
      groupsQty,
      contactsQty,
      isSidebarOpen,
      areStaplesLoaded,
      hasAnySelected} = this.state;
    if (!isLoggedIn) {
      return null;
    }
    trace(`render`);
    return (
      <MyWorkspaceStaples
        isLoggedIn={isLoggedIn}
        myId={myId}
        owners={owners}
        staples={staples}
        starCollectionId={starCollectionId}
        relatedCollections={relatedCollections}
        unreadCountsByStaple={unreadCountsByStaple}
        pathname={pathname}
        feedType={feedType}
        viewMode={viewMode}
        fsrCount={fsrCount}
        groupsQty={groupsQty}
        contactsQty={contactsQty}
        isSidebarOpen={isSidebarOpen}
        areStaplesLoaded={areStaplesLoaded}
        onUnselectAll={hasAnySelected ? this.handleUnselectAll : null}
        onPageTrigger={this.fetchWorkspaceStaplesPage}
      />
    );
  }
}

export default Container.create(MyWorkspaceStaplesContainer, {withProps: true, pure: false});
